import {
  BoxAnnotation,
  LineAnnotation,
  TextAnnotation,
  BitMapAnnotation,
  PureCanvasAnnotation,
  Position,
  MediaProperties,
} from '@clef/shared/types';
import Konva from 'konva';
import { KonvaNodeEvents } from 'react-konva';
import { ZoomScale } from './utils';
import { KonvaEventObject } from 'konva/types/Node';
import { RGB } from 'konva/types/types';
import { Mode } from './components/DefectCreator';
import React from 'react';

export type SelectedAnnotation = {
  type: 'box' | 'line' | 'text';
  index: number;
};

export enum CanvasMode {
  View = 'view',
  IBox = 'IBox',
  ILine = 'ILine',
  IPolygon = 'IPolygon',
  IPolyline = 'IPolyline',
  IText = 'IText',
  IQuickLabeling = 'IQuickLabeling',
  Pan = 'Pan',
}

export enum CanvasAnnotationType {
  Box = 'box',
  Line = 'line',
  Polygon = 'polygon',
  BitMap = 'bitMap',
  Text = 'text',
  PureCanvas = 'canvas',
}

export enum AnnotationSourceType {
  GroundTruth = 'ground_truth',
  Prediction = 'predictions',
  Suggestion = 'suggestions',
  Heatmap = 'heatmap',
}

export type CanvasAnnotationGroup = AnnotationSourceType | ({} & string);

export type CanvasAnnotation = {
  id: string;
  type: CanvasAnnotationType;
  selected?: boolean;
  hovered?: boolean;
  data: BoxAnnotation | LineAnnotation | TextAnnotation | BitMapAnnotation | PureCanvasAnnotation;
  defectId?: number;
  group?: CanvasAnnotationGroup;
  // created means this annotation was crafted by the user instead fetched from server
  created?: boolean;
  enableContour?: boolean;
};

export enum CanvasInteractionEvent {
  MissingColor = 'MissingColor',
  NonInteractiveClick = 'NonInteractiveClick',
  NoMoreUndo = 'NoMoreUndo',
  NoMoreRedo = 'NoMoreRedo',
  DrawingStarted = 'DrawingStarted',
  DrawingEnded = 'DrawingFinished',
}

export type CustomEventsHandlers = {
  onEscPressed?: () => void;
  onEnterPressed?: (newClassColor?: string) => void;
  onOutsideClick?: () => void;
  onModeChanged?: () => void;
  onUndo?: () => void;
  onRedo?: () => void;
  onIsWorkingInProgressChanged?: (wip: boolean) => void;
  isWorkInProgress?: () => boolean;
  // TODO need to figure out how to do the below
  // onUmount?: () => void;
};

export type AnnotationCreatorBuilder = {
  // helper components are like the dotted line in polygon/polyline, preview cursor in brush, positive/negative
  // dots in auto seg
  creatorHelperComponent?: React.ReactNode;
  // preview component is preview of what will be drawn
  creatorPreviewComponent?: React.ReactNode;
  // interactive props to apply to Stage
  creatorInteractiveProps?: KonvaNodeEvents;
  // custom handlers like outside click, esc/enter press
  creatorCustomHandlers?: CustomEventsHandlers;
  // custom cursor
  customCursor?: string | null;
  // clear all annotations
  clearWipAnnotations?: () => void;
};

export enum AnnotationChangeType {
  Create = 'Create',
  DeleteAll = 'DeleteAll',
  DeleteGroundTruth = 'DeleteGroundTruth',
  DeleteColor = 'DeleteColor',
  Delete = 'Delete',
  Select = 'Select',
  Edit = 'Edit',
  Undo = 'Undo',
  Redo = 'Redo',
  Reset = 'Reset',
}

export type MediaInteractiveCanvasProps = {
  // image src to render media
  imageSrc?: string;
  // Image properties can include height and width for the canvas
  properties?: MediaProperties | null;
  // the canvas mode:
  //  - CanvasMode.View (default)   - view image and annotation only
  //  - CanvasMode.IBox             - draw bounding box on canvas
  //  - CanvasMode.ILine            - draw line on canvas
  //  - CanvasMode.IPolygon         - draw polygon on canvas
  //  - CanvasMode.IText            - drop text on canvas
  mode?: CanvasMode;
  // aspect radio to render image and annotations, if not provided with apply height:100%
  aspectRadio?: number;
  // annotations
  annotations: CanvasAnnotation[];
  // on annotations updated
  onAnnotationChanged?: (
    annotations: CanvasAnnotation[],
    changeType: AnnotationChangeType,
    layer: Konva.Layer | null,
  ) => void;
  // props for drawing shapes
  shapeProps?: {
    color?: string;
    lineStrokeWidth?: number;
    segmentationOpacity?: number;
    text?: string;
    fontSize?: number;
  };
  // enable hide labels button
  enableHideLabels?: boolean;
  // when 'created-only', new labels are shown, only old labels are hidden
  showGroundTruthLabels?: boolean | 'created-only';
  // zoom scale change
  onZoomScaleChange?: (newZoomScale: ZoomScale, mousePosition?: Position | null) => void;
  // callback called when zoom scale for fit changed on resize
  onFitZoomScaleChange?: (zoomScale: number) => void;
  // on drag move
  onDragMove?: (position: Position) => void;
  // on mouse move
  onMouseMove?: (
    e?: KonvaEventObject<MouseEvent>,
    info?: { labelColor?: RGB; predictColor?: RGB; position?: Position },
  ) => void;
  // on mouse click
  onMouseClick?: (e?: KonvaEventObject<MouseEvent>, info?: { position?: Position }) => void;
  // enable internal built in zoom floater to update zoom scale
  builtInZoom?: boolean | 'topRight' | 'topLeft';
  // callback called when image is loaded, should only be called once
  onImageLoad?: (image: HTMLImageElement) => void;
  // interaction events like missing color / zoomed in / selected certain mode
  onInteractionEvent?: (type: CanvasInteractionEvent) => void;
  // if false, dragging is limited to the edge of the media
  freeDrag?: boolean;
  // enable pinch scroll zooming
  enablePinchScrollZoom?: boolean;
  // enable fit padding
  enableFitPadding?: boolean;
  // enable pixelation (pixelation is time consuming, but needed for labeling)
  enablePixelation?: boolean;
  // enable crosshair display
  enableCrosshair?: boolean;
  // render image in grayscale
  enableGrayscaleImage?: boolean;
  // image brightness
  brightness?: number;
  // on defect create (for quick create defect flow)
  onDefectCreate?: (defectName: string, defectColor: string) => void | Promise<void>;
  // pending defect color when there is no selected defect (for quick create defect flow)
  pendingDefectColor?: string;
  // on mouse down event when drawing is disabled (for quick create defect flow)
  onDisabledDrawingMouseDown?: () => void;
  // on defect creator mode change (for quick create defect flow)
  onDefectCreatorModeChange?: (mode: Mode) => void;
  // selected defect name
  pendingDefectName?: string;
  // defect creator tips
  defectCreatorTips?: string;
  // class name applied to the root element
  className?: string;
  // enable contour
  enableContour?: boolean;
  // disable undo/redo hotkeys
  disableUndoRedo?: boolean;
  // hide prediction annotations
  hidePredictions?: boolean;
  // enable defect creator click away event
  enableDefectCreatorClickAwayEvent?: boolean;
  // padding top of the image inside the canvas
  fitMediaPaddingTop?: number;
  // padding bottom of the image inside the canvas
  fitMediaPaddingBottom?: number;
  // SAM annotation to be rendered on ground truth layer
  samAnnotations?: CanvasAnnotation[] | null;
  // custom cursor CSS property to overwrite the default cursor
  customCursor?: string | null;
  // on enter key pressed
  onEnterPress?: () => void;
  // on annotation creator error
  onCreatorError?: (error: any) => void;
  // SAM ONNX model
  onnxModel?: ArrayBuffer | undefined;
  // Image embedding
  imageEmbedding?: ArrayBuffer | undefined;
};

export type SetHoveredAnnotationParams = {
  annotationId?: string | string[];
  color?: string;
  group?: string;
};
