import React, { useMemo } from 'react';
import { ConfusionMatrixPerThreshold, LabelType } from '@clef/shared/types';
import { Box, Slider, TextField, Tooltip, Typography, ValueLabelProps } from '@material-ui/core';
import { range } from 'lodash';
import useStyles from './styles';
import ConfusionMatrixGraphs from '@/components/ProjectModel/ConfusionMatrixGraphs';
import { PerformanceType } from '@/pages/DataBrowser/ProjectModelDetails/states';
import {
  appliedFilterMappingToFormattedFilterMapping,
  getColumnFilterMapWithModelId,
  useDataBrowserState,
} from '@/pages/DataBrowser/dataBrowserState';
import PerformanceRates from '@/pages/DataBrowser/ModelPerformance/PerformanceRates';
import { useDatasetModelMetricsQuery } from '@/serverStore/dataset';
import { CONFIDENCE_THRESHOLD_OPTIONS } from '@clef/shared/constants';

// The constant represents the threshold percentage used to determine if the difference between the item
// (an element of the thresholds array) and the value variable is small enough to consider them equal.
// For example, if thresholdsStep is 0.01 and value is 0.01, an element is considered equal to value
// if it is within 0.0055 (55% of 0.01) from it.
// This means that elements with values between 0.0045 and 0.0155 (0.01 - 0.0055 and 0.01 + 0.0055, respectively)
// would be considered equal to value.
const THRESHOLD_FOR_ACCURACY_EVALUATION = 0.55;

type ConfidenceThresholdSliderProps = {
  labelType: LabelType | null | undefined;
  registeredModelId: string;
  threshold: number;
  updateThreshold: (newThreshold: number) => void;
  disabled?: boolean;
};

const ConfidenceThresholdSlider: React.FC<ConfidenceThresholdSliderProps> = ({
  labelType,
  registeredModelId,
  threshold,
  updateThreshold,
  disabled = false,
}) => {
  const styles = useStyles();
  const {
    state: { viewMode, appliedFilters },
  } = useDataBrowserState();

  const [columnFilterMap, metadataFilterMap] = useMemo(() => {
    const [col, metadata] = appliedFilterMappingToFormattedFilterMapping(appliedFilters);
    const colWithModelId = getColumnFilterMapWithModelId(col, registeredModelId, true);
    return [colWithModelId, metadata];
  }, [appliedFilters, registeredModelId]);

  const { data: modelMetrics } = useDatasetModelMetricsQuery({
    modelId: registeredModelId,
    columnFilterMap,
    metadataFilterMap,
    viewMode,
  });

  const { thresholdsMin, thresholdsMax, thresholdsStep } = CONFIDENCE_THRESHOLD_OPTIONS;

  const thresholds = range(thresholdsMin, thresholdsMax, thresholdsStep).map(
    x => Math.round(x * 1000) / 1000,
  );

  const ValueLabelComponent = useMemo(
    () => (props: ValueLabelProps) => {
      const { children, open, value } = props;
      return (
        <Tooltip
          open={open}
          enterTouchDelay={0}
          placement="top"
          classes={{
            tooltip: styles.tooltip,
          }}
          title={
            <Box
              maxWidth={272}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Typography className={styles.tooltipTitle}>
                {t('Data Performance Reference ')}
              </Typography>
              <Box>
                <Box marginRight="-2px">
                  <ConfusionMatrixGraphs
                    split=""
                    performanceType={PerformanceType.AnnotationLevel}
                    confusionMatrix={
                      viewMode === 'instance'
                        ? modelMetrics?.instanceParingConfusionMatrix
                        : (modelMetrics?.confusionMatrix as ConfusionMatrixPerThreshold)
                    }
                    threshold={value}
                    labelType={labelType}
                    modelId={registeredModelId}
                    onUpdateThreshold={newThreshold => {
                      updateThreshold(newThreshold);
                    }}
                  />
                </Box>
                {modelMetrics && (
                  <Box display="flex" marginTop={7.5} width="100%">
                    <PerformanceRates
                      modelMetrics={modelMetrics}
                      threshold={value}
                      labelType={labelType}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          }
        >
          {children}
        </Tooltip>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modelMetrics, styles.tooltip, viewMode],
  );

  if (threshold === undefined) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" marginBottom={0.5}>
      <Typography variant="body1" className={styles.title}>
        {t('Confidence Threshold')}
      </Typography>
      <Box className={styles.thresholdWrapper}>
        <Slider
          disabled={disabled}
          min={thresholdsMin}
          max={thresholdsMax}
          step={thresholdsStep}
          value={threshold}
          onChange={(_, newThreshold) => {
            updateThreshold(newThreshold as number);
          }}
          classes={{
            root: styles.sliderRoot,
            rail: styles.sliderRail,
            track: styles.sliderTrack,
            thumb: styles.sliderThumb,
            active: styles.sliderActive,
            disabled: styles.disabled,
          }}
          {...(!!modelMetrics && { ValueLabelComponent: ValueLabelComponent })}
        />
        <TextField
          disabled={disabled}
          variant="outlined"
          type="number"
          value={threshold}
          InputProps={{
            classes: {
              root: styles.thresholdInputRoot,
              input: styles.thresholdInput,
            },
          }}
          inputProps={{
            className: styles.thresholdInput,
            step: thresholdsStep,
            min: thresholdsMin,
            max: thresholdsMax,
            'data-testid': 'threshold-input',
          }}
          onChange={e => {
            let value = Number(e.target.value || 0);
            if (value > thresholdsMax) {
              value = thresholdsMax;
            } else if (value < thresholdsMin) {
              value = thresholdsMin;
            } else if (!thresholds.includes(value)) {
              value =
                thresholds.find(
                  item =>
                    Math.abs(item - value) <= thresholdsStep * THRESHOLD_FOR_ACCURACY_EVALUATION,
                ) || 0;
            }
            updateThreshold(value || 0);
          }}
        />
      </Box>
      <div style={{ flex: 1 }} />
    </Box>
  );
};

export default ConfidenceThresholdSlider;
