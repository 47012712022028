import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  popper: {
    pointerEvents: 'auto',
  },
  arrow: {
    color: theme.palette.blue[700],
  },
  tooltip: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  SnapshotTabsTipsPos: {
    position: 'absolute',
    right: -30,
    top: 9,
  },
  SnapshotUsageTipsPos: {
    position: 'absolute',
    right: 140,
    top: 10,
  },
  SnapshotListTipsPos: {
    position: 'absolute',
    left: 98,
    top: 17,
  },
}));

export default useStyles;
