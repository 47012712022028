import React from 'react';

const SliderIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="8" height="14" rx="3" fill="#37414D" />
    <line x1="2" y1="3.5" x2="6" y2="3.5" stroke="#F3F3F4" />
    <line x1="2" y1="6.5" x2="6" y2="6.5" stroke="#F3F3F4" />
    <line x1="2" y1="9.5" x2="6" y2="9.5" stroke="#F3F3F4" />
  </svg>
);

export default SliderIcon;
