import { BaseAPI } from './base_api';
import {
  Dataset,
  DatasetId,
  MediaId,
  ProjectId,
  SortOptions,
  FilterOptionType,
  SortFieldInfo,
  DatasetGroupOptions,
  ColumnsToReturn,
  DefectDistributionToAssignSplitMapping,
  DatasetVersionId,
  MediaDetailsWithPrediction,
  MediaStatsAPIResponse,
  MediaWithDatasetContent,
  FormattedColumnFilterMap,
  FormattedFieldFilterMap,
  InstanceViewMode,
  AnnotationInstance,
  PerformanceMetrics,
  VersionedDatasetContent,
  SnapshotModelInfo,
  RegisteredModelId,
} from '@clef/shared/types';
import { SelectMediaOption } from '@clef/shared/types/dataset';

const MEDIA_FIELDS_TO_RETURN: ColumnsToReturn[] = [
  ColumnsToReturn.MEDIA_ID,
  ColumnsToReturn.ORG_ID,
  ColumnsToReturn.MEDIA_TYPE,
  ColumnsToReturn.PATH,
  ColumnsToReturn.SRC_TYPE,
  ColumnsToReturn.SRC_NAME,
  ColumnsToReturn.PROPERTIES,
  ColumnsToReturn.NAME,
  ColumnsToReturn.UPLOAD_TIME,
];

class DatasetAPI extends BaseAPI {
  async getDatasetFilterOptions(
    projectId: ProjectId,
    version?: number,
  ): Promise<FilterOptionType[]> {
    return this.get('filter_options', { projectId, version }, /* only data */ true);
  }

  async getExportedDatasetsWithVersions(
    projectId: ProjectId,
    withCount = false,
    includeNotCompleted = false,
    includeFastEasy = false,
  ): Promise<Dataset[]> {
    return this.get(
      'exported_with_versions',
      { projectId, withCount, includeNotCompleted, includeFastEasy },
      true,
    );
  }

  // get map from dataset id to ids media counts
  async getDatasetMediaCounts(datasetIds: DatasetId[]): Promise<Record<DatasetId, number>> {
    return this.get('media_count', { datasetIds }, true);
  }

  // get the media count of a dataset
  async getDatasetMediaCount(datasetId: DatasetId): Promise<number> {
    const res = await this.getDatasetMediaCounts([datasetId]);
    return res[datasetId];
  }

  async startDatasetVersionExport(datasetId: DatasetId, version: number) {
    const res = await this.postURLParams('export/start', {
      datasetId,
      version,
    });
    return res.data;
  }

  async createDatasetVersion(
    datasetId: DatasetId,
    name: string,
    selectOptions: SelectMediaOption,
    pascalVoc: boolean,
  ) {
    const res = await this.postJSON('create_dataset_version', {
      datasetId,
      name,
      selectMediaOptions: selectOptions,
      ...(pascalVoc && { exportToPascalVOC: pascalVoc }),
    });
    return res.data;
  }

  async createDatasetSnapshot(
    projectId: ProjectId,
    name: string,
    details?: string,
    onlyLabeled?: boolean,
    selectMediaOptions?: SelectMediaOption,
  ) {
    const res = await this.postJSON('create_dataset_snapshot', {
      projectId,
      name,
      details,
      onlyLabeled,
      selectMediaOptions,
    });
    return res.data;
  }

  async getDatasetVersionSignedUrls(
    datasetId: DatasetId,
    version: number,
  ): Promise<{
    csvPathSignedUrl: string;
    defectMapPathSignedUrl: string;
    pascalVocSignedUrl: string;
  }> {
    return this.get('get_dataset_version_signed_url', { datasetId, version }, true);
  }

  async getMedias(
    datasetId: DatasetId,
    projectId: ProjectId,
    sortOptions: SortOptions = {},
    columnFilterMap: object = {},
    metadataFilterMap: object = {},
    // TODO: refactor this to be a separate API in the future (when Media Status goes in)
    includeMediaStatus: boolean = false,
    version?: number,
  ): Promise<MediaWithDatasetContent[]> {
    const columnsToReturn = [...MEDIA_FIELDS_TO_RETURN];
    if (includeMediaStatus && !columnsToReturn.includes(ColumnsToReturn.MEDIA_STATUS))
      columnsToReturn.push(ColumnsToReturn.MEDIA_STATUS);
    return this.get(
      'medias',
      {
        datasetId,
        projectId,
        sortOptions: JSON.stringify(sortOptions),
        columnFilterMap: JSON.stringify(columnFilterMap),
        metadataFilterMap: JSON.stringify(metadataFilterMap),
        columnsToReturn: columnsToReturn,
        version,
      },
      true,
    );
  }

  async getMediaCount(
    datasetId: DatasetId,
    projectId: ProjectId,
    selectOptions: SelectMediaOption,
    labeledOnly?: boolean,
    version?: number,
  ): Promise<{ count: number }> {
    return this.get(
      'dataset_media_count',
      {
        datasetId,
        projectId,
        selectMediaOptions: JSON.stringify(selectOptions),
        labeledOnly: Boolean(labeledOnly),
        version,
      },
      true,
    );
  }

  async getStats(
    datasetId: DatasetId,
    projectId: ProjectId,
    selectOptions: Partial<SelectMediaOption>,
    groupOptions: DatasetGroupOptions[],
    version?: DatasetVersionId,
  ): Promise<MediaStatsAPIResponse[]> {
    return this.get(
      'stats',
      {
        datasetId,
        projectId,
        selectMediaOptions: JSON.stringify(selectOptions),
        groupbyCondition: JSON.stringify({
          col: groupOptions,
          metadata: [],
        }),
        version,
      },
      true,
    );
  }

  async getMediaDetails(
    datasetId: DatasetId,
    mediaId: MediaId,
    version?: number,
    modelId?: string,
  ): Promise<MediaDetailsWithPrediction> {
    return this.get(
      'media_details',
      {
        datasetId,
        mediaId,
        version,
        modelId,
      },
      true,
    );
  }

  async getSortableFieldInfo(): Promise<SortFieldInfo[]> {
    return this.get('get_sortable_fieldinfos', {}, true);
  }

  async deleteMedias(
    datasetId: DatasetId,
    projectId: ProjectId,
    selectOptions: SelectMediaOption,
  ): Promise<{ deletedMediasCount: number }> {
    const res = await this.postJSON('delete_medias', {
      datasetId,
      projectId,
      selectMediaOptions: JSON.stringify(selectOptions),
    });
    return res.data;
  }

  async autoSplit(
    projectId: ProjectId,
    selectOptions: SelectMediaOption,
    splitByDefectDistribution: DefectDistributionToAssignSplitMapping,
  ): Promise<void> {
    await this.postJSON('auto_split', {
      projectId,
      selectMediaOptions: JSON.stringify(selectOptions),
      splitByDefectDistribution: JSON.stringify(splitByDefectDistribution),
    });
  }

  async getVersionedDatasetContent(
    datasetId: DatasetId,
    version: number,
  ): Promise<VersionedDatasetContent[]> {
    const res = await this.get('versioned_content', {
      datasetId,
      version,
    });
    return res.data;
  }

  async getVersionedMediaAndLabelIds(
    datasetId: DatasetId,
    version: number,
  ): Promise<{ mediaId: number; labelId: number }[]> {
    const response = await this.getVersionedDatasetContent(datasetId, version);
    const res = response.map(({ mediaId, labelId }: { mediaId: number; labelId: number }) => ({
      mediaId,
      labelId,
    }));
    return res;
  }

  async updateMediaSplit(
    datasetId: DatasetId,
    projectId: ProjectId,
    splitSet: number,
    selectMediaOptions: SelectMediaOption,
  ): Promise<void> {
    await this.postJSON('update_media_split', {
      datasetId,
      projectId,
      splitSet,
      selectMediaOptions,
    });
  }

  async getAnnotationInstances(params: {
    projectId: number;
    modelId?: RegisteredModelId;
    columnFilterMap?: FormattedColumnFilterMap;
    metadataFilterMap?: FormattedFieldFilterMap;
    mode?: InstanceViewMode;
    sortOptions: Partial<SortOptions>;
    threshold?: number;
  }): Promise<AnnotationInstance[]> {
    return this.get(
      'annotation_instance',
      {
        ...params,
        sortOptions: JSON.stringify(params.sortOptions),
        columnFilterMap: JSON.stringify(params.columnFilterMap),
        metadataFilterMap: JSON.stringify(params.metadataFilterMap),
      },
      true,
    );
  }

  async getAnnotationInstancesCount(params: {
    projectId: number;
    modelId?: RegisteredModelId;
    columnFilterMap?: FormattedColumnFilterMap;
    metadataFilterMap?: FormattedFieldFilterMap;
    instanceViewMode: InstanceViewMode;
    threshold?: number;
  }): Promise<{ count: number }> {
    return this.get(
      'annotation_count',
      {
        ...params,
        columnFilterMap: JSON.stringify(params.columnFilterMap),
        metadataFilterMap: JSON.stringify(params.metadataFilterMap),
        // groundTruth, prediction, mix
        instanceViewMode: params.instanceViewMode,
      },
      true,
    );
  }

  async getModelMetrics(
    datasetId: DatasetId,
    projectId: ProjectId,
    modelId: string,
    columnFilterMap?: FormattedColumnFilterMap,
    metadataFilterMap?: FormattedFieldFilterMap,
    viewMode?: string,
    useVersionedDataset?: boolean,
  ): Promise<PerformanceMetrics> {
    return this.get(
      'metrics',
      {
        datasetId,
        projectId,
        modelId,
        columnFilterMap: JSON.stringify(columnFilterMap),
        metadataFilterMap: JSON.stringify(metadataFilterMap),
        viewMode,
        useVersionedDataset,
      },
      true,
    );
  }

  async getModelPerformanceCsv(projectId: number, modelId: string): Promise<string> {
    return this.get(
      'export/model_performance_csv_export',
      {
        projectId,
        modelId,
      },
      true,
    );
  }

  async exportSnapshotVersion(datasetId: DatasetId, version: number, restartJob?: boolean) {
    const res = await this.postJSON('export/dataset_version', {
      datasetId,
      version,
      restartJob: restartJob ?? false,
    });
    return res.data;
  }

  async revertDatasetVersion(projectId: ProjectId, version: Number) {
    return this.postJSON('recover_dataset_version', {
      projectId,
      version,
    });
  }

  async softDeleteSnapshot(projectId: ProjectId, datasetVersionId: DatasetVersionId) {
    return this.postJSON('soft_delete_snapshot', {
      projectId,
      datasetVersionId,
    });
  }

  async editSnapshot(datasetVersionId: number, name?: string, details?: string) {
    return this.postJSON('update_snapshot_info', {
      datasetVersionId,
      name,
      details,
    });
  }

  async getSnapshotModels(
    projectId: ProjectId,
    datasetVersionId: DatasetVersionId,
  ): Promise<{ data: SnapshotModelInfo[] }> {
    return this.get('get_snapshot_models', {
      projectId,
      datasetVersionId,
    });
  }
}

export default new DatasetAPI('dataset');
