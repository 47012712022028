import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { Button, useLocalStorage } from '@clef/client-library';
import SmartLabelingGif from '@/images/sam/smart_labeling.gif';

const useStyles = makeStyles(theme => ({
  smartLabelingGuideGif: {
    width: '100%',
    objectFit: 'contain',
    margin: theme.spacing(6, 0),
  },
  confirmButton: {
    width: '100%',
  },
}));

export type SAMSmartLabelingAnnouncementDialogProps = {};

const SAMSmartLabelingAnnouncementDialog: React.FC<
  SAMSmartLabelingAnnouncementDialogProps
> = () => {
  const styles = useStyles();
  const [skipSAMSmartLabelingAnnouncementDialog, setSkipSAMSmartLabelingAnnouncementDialog] =
    useLocalStorage('skipSAMSmartLabelingAnnouncementDialog');

  if (skipSAMSmartLabelingAnnouncementDialog) {
    return null;
  }

  return (
    <BaseDialog
      open
      maxWidth="xs"
      onClose={() => setSkipSAMSmartLabelingAnnouncementDialog(true)}
      title={t('Introducing lighting-fast labeling!')}
    >
      <Typography>
        {t(
          "Landing AI's new Smart Labeling tool (powered by SAM) makes labeling faster than ever.",
        )}{' '}
        <strong>{t('Try it out by creating a Segmentation project.')}</strong>
      </Typography>

      <img src={SmartLabelingGif} className={styles.smartLabelingGuideGif} />

      <Button
        id="sam-smart-labeling-announcement-dialog-confirm-button"
        variant="contained"
        color="primary"
        onClick={() => setSkipSAMSmartLabelingAnnouncementDialog(true)}
        className={styles.confirmButton}
      >
        {t('Got it')}
      </Button>
    </BaseDialog>
  );
};

export default SAMSmartLabelingAnnouncementDialog;
