import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Popover, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useAtom } from 'jotai';

import { IconButton } from '@clef/client-library';
import CLEF_PATH from '@/constants/path';

import { HistoryIcon } from '@/images/data-browser/History';
import { useDatasetExportedWithVersionsQuery } from '@/serverStore/dataset';
import {
  createSnapshotDialogModeAtom,
  CreateSnapshotDialogMode,
} from '@/uiStates/datasetSnapshot/pageUIStates';
import { getDateNumber } from '@/utils';

import SnapshotActionButtonTips from '../SnapshotOnboardingTips/SnapshotActionButtonTips';

const useStyles = makeStyles(theme => ({
  snpashotBtnWrapper: {
    position: 'relative',
  },
  more: {
    padding: '10px 0',
    color: '#666',
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    width: '33px',
    height: '33px',
    alignItems: 'center',
    borderRadius: 5,
  },
}));

type SnapshotActionButtonProps = {};

const SnapshotActionButton: React.FC<SnapshotActionButtonProps> = () => {
  const styles = useStyles();
  const history = useHistory();

  const { data: datasetExported } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });
  const initialDatasetId = useMemo(() => {
    if (datasetExported?.datasetVersions?.length) {
      const versionList = datasetExported.datasetVersions.sort(
        (a, b) => getDateNumber(b.creationTime) - getDateNumber(a.creationTime),
      );
      const firstManuallySnapshot = versionList.find(v => v.fastEasyExport === false);
      return firstManuallySnapshot?.version ?? versionList[0].version;
    }
    return null;
  }, [datasetExported]);

  const [, setCreateSnapshotDialogMode] = useAtom(createSnapshotDialogModeAtom);

  return (
    <PopupState variant="popover" popupId="snapshot-action-btn-popup-popover">
      {popupState => (
        <div className={styles.snpashotBtnWrapper}>
          <IconButton
            id="snapshot-more-btn"
            className={styles.more}
            tooltip={t('Snapshot')}
            {...bindTrigger(popupState)}
          >
            <HistoryIcon width={16} height={16} color={'rgba(0,0,0,0.54)'} />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <List>
              <ListItem
                button
                id="save-snapshot"
                onClick={() => {
                  setCreateSnapshotDialogMode(CreateSnapshotDialogMode.FULL);
                  popupState.close();
                }}
              >
                <ListItemText primary={t('Snapshot entire dataset')} />
              </ListItem>

              {datasetExported?.datasetVersions?.length ? (
                <ListItem
                  button
                  id="open-snapshot-list"
                  onClick={() => {
                    history.push(`${CLEF_PATH.data.datasetSnapshot}/${initialDatasetId}`);
                  }}
                  disabled={!initialDatasetId}
                >
                  <ListItemText primary={t('Show snapshot list')} />
                </ListItem>
              ) : null}
            </List>
          </Popover>
          <SnapshotActionButtonTips />
        </div>
      )}
    </PopupState>
  );
};

export default SnapshotActionButton;
