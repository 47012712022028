import React, { useMemo, useRef, MutableRefObject, useState } from 'react';
import { throttle } from 'lodash';
import cx from 'classnames';
import {
  Box,
  LinearProgress,
  Tooltip,
  Select,
  MenuItem,
  makeStyles,
  withStyles,
  createStyles,
  Theme,
  InputBase,
  Typography,
} from '@material-ui/core';

import { Button, calcItemPerRow, IconButton, useElementEventListener } from '@clef/client-library';
import { Media, FilterOptionName } from '@clef/shared/types';

import { defaultSelectOptions } from '@/constants/data_browser';
import { useGetSelectedProjectQuery, useGetProjectSplitQuery } from '@/serverStore/projects';
import { useDatasetMediaListQuery, useDatasetMediaCountQuery } from '@/serverStore/dataset';

import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

import ZoomInIcon from '@/images/data-browser/dataset/ZoomInIcon';
import ZoomOutIcon from '@/images/data-browser/dataset/ZoomOutIcon';
import { useModelDetailsDialogState } from '@/pages/DataBrowser/ModelPerformance/ModelDetailsDialogV2/modelDetailsDialogState';
import { MediaSplitName } from '@/constants/stats_card';
import {
  AppliedFilterMapping,
  appliedFilterMappingToFormattedFilterMapping,
  getColumnFilterMapWithModelId,
} from '@/pages/DataBrowser/dataBrowserState';
import CLEF_PATH from '@/constants/path';
import { useHistory } from 'react-router';
import MediaGrid from '@/pages/DataBrowser/ModelPerformance/ModelDetailsDialogV2/MediaGrid';
import LoadingProgress from '../LoadingProgress';

const atBottom = (el: HTMLElement): boolean => {
  const sh = el.scrollHeight,
    st = Math.ceil(el.scrollTop),
    ht = el.offsetHeight;
  if (ht == 0) return true;
  return st + ht >= sh - 20; // 20 pixels for buffer
};

export interface DataSnapshotProps {
  version: number | undefined;
  modelId: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    width: '100%',
    height: theme.spacing(8),
  },
  zoomIconBox: {
    width: 32,
    height: 32,
    background: theme.palette.greyBlue[100],
    borderRadius: 10,
  },
  infoFont: {
    color: theme.palette.greyModern[900],
    whiteSpace: 'pre-wrap',
  },
  toolbarOps: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingImagesProgress: {
    top: 102,
  },
  mediaGridWrapper: {
    position: 'relative',
    flexGrow: 1,
    marginTop: theme.spacing(3),
    width: 'auto',
    margin: theme.spacing(3, -3, 0, -1),
    overflowY: 'auto',
  },
  splitSelect: {
    minWidth: 80,
    padding: theme.spacing(2, 3),
  },
  goToSnapshotBtn: {
    fontSize: 12,
  },
  menuItemText: {
    fontSize: 12,
  },
  greyText: {
    color: theme.palette.greyModern[500],
  },
}));

const RoundedInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.greyModern[400]}`,
      fontSize: 12,
      '&:focus': {
        borderRadius: 10,
        borderColor: theme.palette.blue[500],
      },
    },
  }),
)(InputBase);

const ALL_SPLIT_NAME_SELECT = 'all';

const DataSnapshot: React.FC<DataSnapshotProps> = props => {
  const { version, modelId } = props;
  const styles = useStyles();
  const history = useHistory();

  const { datasetId, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { versionedDatasetContentId } = useCurrentProjectModelInfoQuery();

  const { state, dispatch } = useModelDetailsDialogState();
  const { itemPerRowOffset, paginationLimit } = state;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [selectedSplitName, setSelectedSplitName] = useState<string>(ALL_SPLIT_NAME_SELECT);
  const { data: projectSplits = [] } = useGetProjectSplitQuery();
  const projectSplitNameToId = projectSplits.reduce((obj: Record<string, number>, projectSplit) => {
    obj[projectSplit.splitSetName] = projectSplit.id;
    return obj;
  }, {});

  const columnFilterMap = useMemo(() => {
    const buildAppliedFilterMapping = (splitName: string): AppliedFilterMapping => {
      if (splitName === ALL_SPLIT_NAME_SELECT) {
        return {};
      }

      return {
        [FilterOptionName.Split]: {
          o: 'CONTAINS_ANY',
          t: 'column',
          fi: undefined,
          v: [projectSplitNameToId[splitName]],
        },
      };
    };
    const appliedFilterMapping = buildAppliedFilterMapping(selectedSplitName);
    const [col] = appliedFilterMappingToFormattedFilterMapping(appliedFilterMapping);
    const colWithModelId = getColumnFilterMapWithModelId(col, modelId);
    return colWithModelId;
  }, [modelId, version, selectedSplitName]);

  const { data: mediaCount, isLoading: mediaCountLoading } = useDatasetMediaCountQuery(
    {
      version: versionedDatasetContentId,
      selectOptions: { ...defaultSelectOptions, columnFilterMap: columnFilterMap },
    },
    !!versionedDatasetContentId,
  );

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useDatasetMediaListQuery({
    datasetId,
    projectId,
    includeMediaStatus: true,
    version: versionedDatasetContentId,
    paginationLimit,
    columnFilterMap: columnFilterMap,
  });

  // Flatten the pages into a single array for easy access
  const allMedias = useMemo(() => {
    return data?.pages.flat() || [];
  }, [data?.pages, selectedSplitName]);

  const containerRefElement = containerRef
    ? (containerRef as MutableRefObject<HTMLDivElement>).current
    : undefined;

  const handleGoToSnapshotPage = () => {
    history.push(CLEF_PATH.data.datasetSnapshot + '/' + version);
  };

  useElementEventListener(
    containerRefElement ? containerRefElement : undefined,
    'scroll',
    !hasNextPage || !containerRefElement
      ? () => {}
      : // throttle scroll event so it doesn't trigger too often
        throttle(
          () => {
            if (
              !!containerRefElement &&
              atBottom(containerRefElement) &&
              hasNextPage &&
              !isLoading
            ) {
              fetchNextPage();
            }
          },
          200,
          { leading: false },
        ),
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.toolbar}>
        <Box className={styles.toolbarOps}>
          <Select
            id="split-select"
            value={selectedSplitName}
            variant="outlined"
            input={<RoundedInput />}
            classes={{ root: styles.splitSelect }}
            onChange={e => {
              setSelectedSplitName(e.target.value as string);
            }}
          >
            <MenuItem value={ALL_SPLIT_NAME_SELECT}>
              <Typography className={styles.menuItemText}>{t('All')} </Typography>
            </MenuItem>
            <MenuItem value={MediaSplitName.Train}>
              <Typography component="div" className={styles.menuItemText}>
                {t('Train set {{defaultText}}', {
                  defaultText: (
                    <Typography
                      display="inline"
                      className={cx(styles.menuItemText, styles.greyText)}
                    >
                      {t('(default)')}
                    </Typography>
                  ),
                })}
              </Typography>
            </MenuItem>
            <MenuItem value={MediaSplitName.Dev}>
              <Typography component="div" className={styles.menuItemText}>
                {t('Dev set {{defaultText}}', {
                  defaultText: (
                    <Typography
                      display="inline"
                      className={cx(styles.menuItemText, styles.greyText)}
                    >
                      {t('(default)')}
                    </Typography>
                  ),
                })}
              </Typography>
            </MenuItem>
            <MenuItem value={MediaSplitName.Test}>
              <Typography component="div" className={styles.menuItemText}>
                {t('Test set {{defaultText}}', {
                  defaultText: (
                    <Typography
                      display="inline"
                      className={cx(styles.menuItemText, styles.greyText)}
                    >
                      {t('(default)')}
                    </Typography>
                  ),
                })}
              </Typography>
            </MenuItem>
          </Select>
          {/* </FormControl> */}
          {!mediaCountLoading ? (
            <Box marginLeft={2}>
              <Typography display="inline" variant="body1" className={styles.infoFont}>
                {`${mediaCount} Images`}
              </Typography>
            </Box>
          ) : (
            <Box />
          )}
        </Box>
        <Box display="flex">
          <Tooltip title={t('Enlarge image size')} arrow placement="top">
            <Box
              className={styles.zoomIconBox}
              display="flex"
              alignItems="center"
              justifyContent={'center'}
            >
              <IconButton
                id="model-details-increase-media-size"
                size="small"
                disabled={calcItemPerRow(undefined, itemPerRowOffset) < 2}
                onClick={() => {
                  dispatch(draft => {
                    draft.itemPerRowOffset--;
                  });
                }}
              >
                <ZoomInIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Box marginRight={2}></Box>
          <Tooltip title={t('Reduce image size')} arrow placement="top">
            <Box
              className={styles.zoomIconBox}
              display="flex"
              alignItems="center"
              justifyContent={'center'}
            >
              <IconButton
                id="model-details-decrease-media-size"
                size="small"
                disabled={calcItemPerRow(undefined, itemPerRowOffset) > 7 || itemPerRowOffset > 4}
                onClick={() => {
                  dispatch(draft => {
                    draft.itemPerRowOffset++;
                  });
                }}
              >
                <ZoomOutIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Box marginRight={2}></Box>
          <Button
            variant="outlined"
            id={'training-info-go-to-snapshot-page'}
            onClick={handleGoToSnapshotPage}
            className={styles.goToSnapshotBtn}
          >
            {t('Go to snapshot page')}
          </Button>
        </Box>
      </Box>

      {/* media grid */}
      {/* this progress is for loading more data */}
      {isFetching && allMedias.length > 0 && (
        <LinearProgress className={styles.loadingImagesProgress} />
      )}
      <div className={styles.mediaGridWrapper} ref={containerRef}>
        {isLoading ? (
          <LoadingProgress />
        ) : allMedias.length ? (
          <MediaGrid
            containerRef={containerRef}
            medias={allMedias as Media[]}
            showClassChip
            showGroundTruth={true}
            showPredictions={false}
          />
        ) : (
          <Box
            display="flex"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              className={styles.greyText}
              data-test-id="no-medias-found-data-browser"
            >
              {t('No image found')}
            </Typography>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default DataSnapshot;
