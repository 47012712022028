import React from 'react';
import { makeStyles } from '@material-ui/core';

import { Media } from '@clef/shared/types';
import { VirtualGrid } from '@clef/client-library';

import { useModelDetailsDialogState } from './modelDetailsDialogState';
import MediaContainer from '../../MediaContainer';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

const useStyles = makeStyles(() => ({
  virtualGrid: {
    overflow: 'visible',
  },
}));

export const calcOptimalRatio = (medias: Media[]) => {
  const allRatios = medias
    .filter(media => media.properties && media.properties.height && media.properties.width)
    .map(media => media.properties!.height / media.properties!.width)
    .sort((a, b) => a - b);
  // Lazy here to calculate the most frequent item
  return allRatios.length ? allRatios[Math.floor(allRatios.length / 2)] : 16 / 9;
};

export interface MediaGridProps {
  containerRef: React.ForwardedRef<HTMLDivElement | null>;
  medias: Media[];
  disableVirtualGrid?: boolean;
  showClassChip?: boolean;
  showGroundTruth?: boolean;
  showPredictions?: boolean;
  segmentationOpacity?: number;
  enableGrayscaleImage?: boolean;
  renderMediaOverlay?(media: Media, index: number): JSX.Element | null;
  imageRatio?: number;
}

const MediaGrid: React.FC<MediaGridProps> = ({
  containerRef,
  medias,
  disableVirtualGrid,
  showClassChip = false,
  showGroundTruth: showGroundTruthProps,
  showPredictions: showPredictionsProps,
  segmentationOpacity = 0.3,
  enableGrayscaleImage = false,
  renderMediaOverlay,
  imageRatio,
}) => {
  const styles = useStyles();
  const { state } = useModelDetailsDialogState();

  const { versionedDatasetContentId = 0 } = useCurrentProjectModelInfoQuery();

  const { showGroundTruth, showPredictions, showHeatmap, itemPerRowOffset } = state;

  return (
    <VirtualGrid
      // Media size is interpreted to number of media per row, the larger the mediaSize, the smaller
      // the number of media we place in a row.
      containerRef={containerRef}
      disabled={disableVirtualGrid}
      itemPerRowCap={8}
      itemPerRowOffset={itemPerRowOffset}
      imageRatio={imageRatio ? imageRatio : calcOptimalRatio(medias!)}
      componentList={medias!}
      className={styles.virtualGrid}
      visibleOverflow
      bufferRowLength={10}
      style={{ margin: 0 }}
    >
      {(media, index, itemPerRow) => (
        <MediaContainer
          key={media.id + versionedDatasetContentId}
          media={media}
          versionId={versionedDatasetContentId}
          showGroundTruth={showGroundTruthProps ?? showGroundTruth}
          showPredictions={showPredictionsProps ?? showPredictions}
          showHeatmap={showHeatmap}
          thumbnailSize={itemPerRow > 1 ? 'medium' : 'large'}
          showClassChip={showClassChip}
          disableSelection
          disableSetClass
          segmentationOpacity={segmentationOpacity}
          enableGrayscaleImage={enableGrayscaleImage}
        >
          {renderMediaOverlay?.(media, index)}
        </MediaContainer>
      )}
    </VirtualGrid>
  );
};

export default MediaGrid;
