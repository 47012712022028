import React, { useState } from 'react';
import cx from 'classnames';
import { useAtom } from 'jotai';
import { Box, makeStyles } from '@material-ui/core';
import { Media } from '@clef/shared/types';
import MediaContainer from '@/pages/DataBrowser/MediaContainer';
import { calcOptimalRatio } from '@/pages/DataBrowser/MediaGrid/MediaGrid';
import { Typography, useLocalStorage, VirtualList } from '@clef/client-library';
import { OnboardingTips } from '@/components/OnboardingTips';
import { modelListFilterOptionsAtom } from '../atoms';
import { useSegmentationInfoWithFilterOptions } from '@/pages/DataBrowser/utils';
import { DefectColorChip } from '@/pages/DataBrowser/ModelPerformance/ConfusionMatrix';

const useStyles = makeStyles(theme => ({
  mediaListRoot: {
    width: '100%',
  },
  highlightTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  onboardingWrapper: {
    border: `4px solid ${theme.palette.blue[100]}`,
    background: theme.palette.blue[50],
  },
  onboardingInnerWrapper: {
    border: `1px solid ${theme.palette.blue[500]}`,
    background: theme.palette.blue[25],
  },
  mediaView: {
    position: 'relative',
    transition: 'outline 0.3s',
    borderRadius: 8,
    border: `2px solid transparent`,
    '&:hover': {
      border: `2px solid ${theme.palette.greyModern[400]}`,
      padding: theme.spacing(1),
    },
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 106,
      cursor: 'pointer',
    },
  },
}));

export type ModelImageListSegmentationProps = {
  mediaList?: Media[];
  modelId?: string;
  version?: number;
  threshold?: number;
  containerWidth: number;
  onImageClick?: (mediaId: number) => void;
};

const MediaView: React.FC<{
  media: Media & { count?: number };
  containerWidth: number;
  imageRatio: number;
  modelId?: string;
  version?: number;
  threshold?: number;
  onInfoClick: () => void;
}> = ({ media, containerWidth, imageRatio, modelId, version, threshold, onInfoClick }) => {
  const styles = useStyles();
  const columns = 2;
  const columnWidth = 100 / columns;
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const [onMediaHovered, setOnMediaHovered] = useState<boolean>(false);

  const filteredInfo = useSegmentationInfoWithFilterOptions(
    media.id,
    modelId,
    threshold,
    version,
    filterOptions,
  );

  return (
    <div>
      {media.count && filterOptions && (
        <Box className={styles.highlightTitle}>
          <Typography>
            {t('{{count}} pixels', {
              count: media.count,
            })}
          </Typography>
          <DefectColorChip defectId={filterOptions.gtClassId} />
          <Typography>{t('to')}</Typography>
          <DefectColorChip isPrediction defectId={filterOptions.predClassId} />
          <Typography>{t('filtered')}</Typography>
        </Box>
      )}
      <Box
        display="flex"
        height={(containerWidth * imageRatio) / columns}
        marginBottom={1.5}
        className={styles.mediaView}
        onMouseOver={() => setOnMediaHovered(true)}
        onMouseOut={() => setOnMediaHovered(false)}
        onClick={onInfoClick}
      >
        <Box width={columnWidth + '%'}>
          <MediaContainer
            media={media}
            showGroundTruth
            maskHitFilterUrl={onMediaHovered ? undefined : filteredInfo?.gt.dataUrl}
            filterOptions={onMediaHovered ? undefined : filterOptions}
            modelId={modelId}
            versionId={version}
            threshold={threshold}
            disableSelection
            noSpacing
          />
        </Box>
        <Box width={columnWidth + '%'} marginLeft={1.5}>
          <MediaContainer
            media={media}
            showPredictions
            segmentationOpacity={0.5}
            maskHitFilterUrl={onMediaHovered ? undefined : filteredInfo?.prediction.dataUrl}
            modelId={modelId}
            versionId={version}
            threshold={threshold}
            filterOptions={onMediaHovered ? undefined : filterOptions}
            disableSelection
            noSpacing
          />
        </Box>
      </Box>
    </div>
  );
};

const ModelImageListSegmentation: React.FC<ModelImageListSegmentationProps> = props => {
  const styles = useStyles();
  const { mediaList, modelId, version, threshold, onImageClick, containerWidth } = props;
  const onboardingTipsKey = 'hightlightGtAndPredictionPairTips';
  const [shownOnboardingTips] = useLocalStorage(onboardingTipsKey);
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const isShowingIncorrectMapping =
    filterOptions && filterOptions.gtClassId !== filterOptions.predClassId;

  if (!mediaList) {
    return null;
  }

  const imageRatio = calcOptimalRatio(mediaList);

  return (
    <>
      <Box display="flex" alignItems="center" marginBottom={3} id="model-list-titles">
        <Box flex={1}>
          <Typography variant="body_bold">{t('Ground truth mask')}</Typography>
        </Box>
        <Box flex={1}>
          <Typography variant="body_bold">{t('Prediction mask')}</Typography>
        </Box>
      </Box>
      {isShowingIncorrectMapping && (
        <Box position="relative">
          <OnboardingTips
            storageKey={onboardingTipsKey}
            title={t('Now this specific type of ground truth and prediction pair is highlighted.')}
            placement="top"
            tooltipStyles={{
              width: 230,
              position: {
                left: 100,
                bottom: 1,
              },
            }}
          />
        </Box>
      )}
      <Box
        className={cx({
          [styles.onboardingWrapper]: !shownOnboardingTips && isShowingIncorrectMapping,
        })}
      >
        <Box
          className={cx({
            [styles.onboardingInnerWrapper]: !shownOnboardingTips && isShowingIncorrectMapping,
          })}
        >
          <VirtualList
            dataList={mediaList}
            itemHeight={(filterOptions ? 21 : 0) + (containerWidth * imageRatio) / 2}
            containerMaxHeight={1000}
          >
            {media => (
              <MediaView
                key={media.id}
                media={media}
                containerWidth={containerWidth}
                imageRatio={imageRatio}
                modelId={modelId}
                version={version}
                threshold={threshold}
                onInfoClick={() => onImageClick?.(media.id)}
              />
            )}
          </VirtualList>
        </Box>
      </Box>
    </>
  );
};

export default ModelImageListSegmentation;
