import * as Sentry from '@sentry/react';
import { createReducer } from '@reduxjs/toolkit';

import { EventLogger } from '@clef/client-library';
import { pendoEntity } from '../../utils/pendo';
import snackbar from '../../utils/snackbar_singleton';
import {
  startLogin,
  loginSuccess,
  loginFailure,
  setAuthenticated,
  resetError,
  signupSuccess,
  requestCodeSuccess,
  passwordResetSuccess,

  // New
  loginAccountSuccess,
  loginAccountFailure,
  loginOrgUserSuccess,
  loginOrgUserFailure,
  refreshAuthenticatedStates,
  fetchOrgUserProfile,
  signOut,
} from './actions';
import { LoginState } from '../types';

export const initialState: LoginState = {
  user: null,
  account: null,
  isAccountAuthenticated: !!localStorage.getItem('clef_account_is_login'),
  isOrgUserAuthenticated: !!localStorage.getItem('clef_is_login'),

  // To be removed when legacy auth flow is fully deprecated
  isAuthenticated: !!localStorage.getItem('clef_is_login'),
  isLoading: false,
  error: null,
  success: null,
  message: null,
};

export default createReducer(initialState, builder => {
  builder.addCase(setAuthenticated, (state, action) => {
    return {
      ...state,
      isAuthenticated: action.payload,
      isAccountAuthenticated: action.payload,
      isOrgUserAuthenticated: action.payload,
    };
  });

  // To be removed when legacy auth flow is fully deprecated
  builder.addCase(startLogin, state => {
    return {
      ...state,
      isLoading: true,
    };
  });

  // To be removed when legacy auth flow is fully deprecated
  builder.addCase(loginSuccess, (state, action) => {
    localStorage.setItem('clef_is_login', '1');
    localStorage.setItem('clef_account_is_login', '1');

    // Edge case: If user is already logged in, there it is 200 but payload.data = undefined
    if (action.payload.data) {
      const { email, id: userId, orgId } = action.payload.data;
      EventLogger.setDefaultAttributes({
        orgId,
        userId,
        email,
      });
      Sentry.setUser({ email, orgId, userId });
    }

    return {
      ...state,
      isLoading: false,
      isAuthenticated: true,
      isAccountAuthenticated: true,
      isOrgUserAuthenticated: true,
      user: action.payload.data,
      error: null,
    };
  });

  // To be removed when legacy auth flow is fully deprecated
  builder.addCase(loginFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: 'login failed',
      success: false,
      message: action.payload.message,
    };
  });

  // To be removed when legacy auth flow is fully deprecated
  builder.addCase(resetError, state => {
    return {
      ...state,
      error: false,
    };
  });

  // To be removed when legacy auth flow is fully deprecated
  builder.addCase(signupSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isAuthenticated: false,
      isAccountAuthenticated: false,
      isOrgUserAuthenticated: false,
      error: null,
      success: true,
      message: action.payload.message || 'Signup Successful, please continue to login',
    };
  });

  // To be removed when legacy auth flow is fully deprecated
  builder.addCase(requestCodeSuccess, state => {
    return {
      ...state,
      isLoading: false,
      isAuthenticated: false,
      isAccountAuthenticated: false,
      isOrgUserAuthenticated: false,
      error: null,
      success: true,
      message: 'Email containing verification code is sent if account is registered',
    };
  });

  // To be removed when legacy auth flow is fully deprecated
  builder.addCase(passwordResetSuccess, state => {
    return {
      ...state,
      isLoading: false,
      isAuthenticated: false,
      isAccountAuthenticated: false,
      isOrgUserAuthenticated: false,
      error: null,
      success: true,
      message: 'Your password has been changed successfully',
    };
  });

  // New

  /**
   * Account-level user related action creators
   *
   * Note, any account-level login state change should make the org-level user login state unauthenticated
   */
  builder.addCase(loginAccountSuccess, (state, action) => {
    localStorage.setItem('clef_account_is_login', '1');

    // Success to log in account should mark the org user is not authenticated
    localStorage.removeItem('clef_is_login');

    if (action.payload) {
      const { email, id: userId } = action.payload;
      EventLogger.setDefaultAttributes({
        userId,
        email,
      });
      Sentry.setUser({ email, userId });
    }

    return {
      ...state,
      message: 'Account login succeeded',
      account: action.payload,
      isAccountAuthenticated: true,

      // Success to log in account should mark the org user is not authenticated
      user: null,
      isOrgUserAuthenticated: false,
    };
  });
  builder.addCase(loginAccountFailure, (state, action) => {
    localStorage.removeItem('clef_account_is_login');

    // Failure to log in account should mark the org user is not authenticated
    localStorage.removeItem('clef_is_login');

    return {
      ...state,
      message: action.payload?.message,
      account: null,
      isAccountAuthenticated: false,

      // Failure to log in account should also mark org user is not authenticated
      user: null,
      isOrgUserAuthenticated: false,
    };
  });

  /**
   * Org-level user related action creators
   *
   * Note, successful org-level user login state should also make account-level login state authenticated
   */
  builder.addCase(loginOrgUserSuccess, (state, action) => {
    localStorage.setItem('clef_is_login', '1');

    // Success to log in org user should also mark account is authenticated
    localStorage.setItem('clef_account_is_login', '1');

    if (action.payload) {
      const { email, id: userId, orgId } = action.payload;
      EventLogger.setDefaultAttributes({
        orgId,
        userId,
        email,
      });
      Sentry.setUser({ email, orgId, userId });
    }

    // Note, let fetch profile API be the source of truth of the user state
    return {
      ...state,
      message: 'Org user login succeeded',
      isOrgUserAuthenticated: true,

      // Success to log in org user should also mark account is authenticated
      isAccountAuthenticated: true,
    };
  });
  builder.addCase(loginOrgUserFailure, (state, action) => {
    localStorage.removeItem('clef_is_login');

    return {
      ...state,
      message: action.payload.message,
      user: null,
      isOrgUserAuthenticated: false,
    };
  });
  builder.addCase(fetchOrgUserProfile.fulfilled, (state, action) => {
    const { email, id: userId, orgId, company, name, intercomUserHash } = action.payload;
    EventLogger.setDefaultAttributes({
      orgId,
      userId,
      email,
    });
    Sentry.setUser({ email, orgId, userId });
    pendoEntity?.initialize({
      visitor: {
        id: userId,
        email, // Recommended if using Pendo Feedback, or NPS Email
        organization: company,
      },

      account: {
        id: orgId,
        organization: company,
        organization_credits_remaining: undefined,
      },
    });

    if (intercomUserHash) {
      // @ts-ignore
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'pwgi3mm8',
        name: name, // Full name
        email: email, // Email address
        user_id: userId,
        user_hash: intercomUserHash,
      };
    }

    return {
      ...state,
      user: action.payload,
    };
  });
  builder.addCase(fetchOrgUserProfile.rejected, (state, action) => {
    snackbar.error(
      action.error?.message ?? t("Failed to fetch user's profile. Please refresh the page."),
    );

    return state;
  });

  /**
   * Shared action creators
   */
  builder.addCase(signOut.fulfilled, state => {
    // return undefined;
    return {
      ...state,
      isAuthenticated: false,
      isAccountAuthenticated: false,
      isOrgUserAuthenticated: false,
      user: null,
    };
  });
  builder.addCase(signOut.rejected, (state, action) => {
    snackbar.error(action.error?.message ?? t('Failed to sign out. Please retry later.'));

    return state;
  });
  builder.addCase(refreshAuthenticatedStates, state => {
    const isAuthenticated = !!localStorage.getItem('clef_is_login');
    const isAccountAuthenticated = !!localStorage.getItem('clef_account_is_login');
    const isOrgUserAuthenticated = !!localStorage.getItem('clef_is_login');

    return {
      ...state,
      isAuthenticated,
      isAccountAuthenticated,
      isOrgUserAuthenticated,
      user: isAuthenticated || isOrgUserAuthenticated ? state.user : null,
      account: isAccountAuthenticated ? state.account : null,
    };
  });
});
