import { ConfusionMatrixPerThreshold, LabelType } from '@clef/shared/types';
import { makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ConfusionMatrix, getConfusionMatrix } from '../../store/projectModelInfoState/utils';
import {
  PerformanceType,
  useProjectModelDetailsState,
} from '../../pages/DataBrowser/ProjectModelDetails/states';
import { Palette } from '../../pages/DataBrowser/ProjectModelDetails/utils';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { range } from 'lodash';
import { CONFIDENCE_THRESHOLD_OPTIONS } from '@clef/shared/constants';

const useStyles = makeStyles(({ spacing }) => ({
  legends: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: spacing(-1, -1),
    justifyContent: 'center',
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
    // '& + &': {
    //   marginLeft: spacing(4),
    // },
    padding: spacing(1, 1),
    whiteSpace: 'nowrap',
  },
  legendColor: {
    width: 12,
    height: 12,
    borderRadius: 2,
    marginRight: spacing(1),
  },
  legendValue: {
    marginLeft: spacing(1),
    fontSize: '14px',
    color: '#000',
    fontWeight: 500,
  },
}));

type LegendsProps = {
  threshold?: number;
  confusionMatrixPerThreshold?: ConfusionMatrixPerThreshold;
};

const Legends: React.FC<LegendsProps> = ({ threshold, confusionMatrixPerThreshold }) => {
  const styles = useStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const showTrueNegativeLegend = selectedProject?.labelType !== LabelType.BoundingBox;
  const { thresholdsMin, thresholdsMax, thresholdsStep } = CONFIDENCE_THRESHOLD_OPTIONS;

  const confusionMatrix = useMemo(
    () =>
      !threshold || !confusionMatrixPerThreshold
        ? null
        : getConfusionMatrix(
            confusionMatrixPerThreshold,
            range(thresholdsMin, thresholdsMax, thresholdsStep),
            threshold,
          ),
    [confusionMatrixPerThreshold, threshold, thresholdsMax, thresholdsMin, thresholdsStep],
  );

  const toLocaleString = (x?: number) => (x === undefined ? '-' : x.toLocaleString());

  const isRenderValue = useMemo(() => {
    if (!confusionMatrix || !('trueNegativeCount' in confusionMatrix)) {
      return false;
    }
    return true;
  }, [confusionMatrix]);

  const {
    state: { performanceType },
  } = useProjectModelDetailsState();
  return (
    <div className={styles.legends} id="legends" data-testid="legends">
      {showTrueNegativeLegend && (
        <div
          className={styles.legend}
          id="legends-true-negative-stats"
          data-testid="legends-true-negative-stats"
        >
          <div className={styles.legendColor} style={{ backgroundColor: Palette.TN }}></div>
          <div>{t('True Negative')}</div>
          {isRenderValue && (
            <div className={styles.legendValue}>
              {toLocaleString((confusionMatrix as ConfusionMatrix).trueNegativeCount)}
            </div>
          )}
        </div>
      )}
      <div
        className={styles.legend}
        id="legends-false-positive-stats"
        data-testid="legends-false-positive-stats"
      >
        <div className={styles.legendColor} style={{ backgroundColor: Palette.FP }}></div>
        <div>{t('False Positive')}</div>
        {isRenderValue && (
          <div className={styles.legendValue}>
            {toLocaleString((confusionMatrix as ConfusionMatrix).falsePositiveCount)}
          </div>
        )}
      </div>
      <div
        className={styles.legend}
        id="legends-true-positive-stats"
        data-testid="legends-true-positive-stats"
      >
        <div className={styles.legendColor} style={{ backgroundColor: Palette.TP }}></div>
        <div>{t('True Positive')}</div>
        {isRenderValue && (
          <div className={styles.legendValue}>
            {toLocaleString((confusionMatrix as ConfusionMatrix).truePositiveCount)}
          </div>
        )}
      </div>
      <div
        className={styles.legend}
        id="legends-false-negative-stats"
        data-testid="legends-false-negative-stats"
      >
        <div className={styles.legendColor} style={{ backgroundColor: Palette.FN }}></div>
        <div>{t('False Negative')}</div>
        {isRenderValue && (
          <div className={styles.legendValue}>
            {toLocaleString((confusionMatrix as ConfusionMatrix).falseNegativeCount)}
          </div>
        )}
      </div>
      {performanceType === PerformanceType.AnnotationLevel && (
        <div
          className={styles.legend}
          id="legends-misclassified-stats"
          data-testid="legends-misclassified-stats"
        >
          <div className={styles.legendColor} style={{ backgroundColor: Palette.MC }}></div>
          <div>{t('Misclassified')}</div>
          {isRenderValue && (
            <div className={styles.legendValue}>
              {toLocaleString((confusionMatrix as ConfusionMatrix).misclassifiedCount)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Legends;
