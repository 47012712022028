import { SelectMediaOption } from '@clef/shared/types';

export const defaultSelectOptions: SelectMediaOption = {
  fieldFilterMap: {},
  columnFilterMap: {},
  selectedMedia: [],
  unselectedMedia: [],
  isUnselectMode: true,
};

// localStorage key for selected model id for projects
export const SelectedModelIdForProjectsStorageKey = 'selectedModelIdForProjects';
// localStorage key for model switcher tips
export const ShownModelSwitcherTipsKey = 'shownModelSwitcherTips';
// localStorage key for model switcher tips
export const ShownModelListTipsKey = 'shownModelListTips';
