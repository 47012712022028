import subscription_api, { InvoiceHistoryItem } from '@/api/subscription_api';
import { useInvoiceHistory } from '@/hooks/api/useSubscriptionApi';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@clef/client-library';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { usePlanStyles } from '.';
import { Button as CustomButton } from '@clef/client-library';
import classNames from 'classnames';
import { isNumber } from 'lodash';

export type InvoiceHistoryProps = {};
const useStyles = makeStyles(theme => ({
  invoiceHistory: {},
  invoiceHistoryTitle: {
    color: theme.palette.grey[800],
  },
  invoiceHistoryItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.blue[25],
    },
  },
  payment: {
    color: theme.palette.greyModern[400],
    marginLeft: theme.spacing(5),
    fontWeight: 500,
    width: 100,
  },
  status: {
    padding: theme.spacing(1, 2),
    borderRadius: 4,
    backgroundColor: theme.palette.greyModern[100],
    display: 'inline-block',
    fontWeight: 500,
    '&.paid': {
      backgroundColor: theme.palette.green[100],
      color: theme.palette.green[800],
    },
    '&.open': {
      backgroundColor: theme.palette.blue[100],
      color: theme.palette.blue[800],
    },
  },
}));

const InvoiceHistory: React.FC<InvoiceHistoryProps> = () => {
  const styles = useStyles();
  const planStyles = usePlanStyles();
  const [invoiceHistoryData] = useInvoiceHistory({});
  const formatPayments = useCallback((payments: InvoiceHistoryItem['payments']) => {
    const { base, overage } = payments;
    if (isNumber(base?.amount) && isNumber(overage?.amount)) {
      return `$${base.amount} + $${overage!.amount}`;
    } else if (!isNumber(base?.amount) && isNumber(overage?.amount)) {
      return `$${overage!.amount}`;
    } else {
      return `$${base?.amount ?? 0}`;
    }
  }, []);
  const historyList = useMemo(() => {
    return (invoiceHistoryData ?? []).map(
      ({ periodStart, periodEnd, payments, plan, ...others }) => ({
        label: t('{{start}} - {{end}}', {
          start: format(new Date(periodStart), 'MMM dd, yyyy'),
          end: format(new Date(periodEnd), 'MMM dd, yyyy'),
        }),
        payment: formatPayments(payments),
        plan:
          isNumber(payments.overage?.amount) && !isNumber(payments.base.amount)
            ? t('{{plan}} - Usage Fee', { plan })
            : plan,
        ...others,
      }),
    );
  }, [formatPayments, invoiceHistoryData]);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const viewAllHistory = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const url = await subscription_api.getBillingPortalUrl();
      window.open(url);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
    }
    setLoading(false);
  }, [enqueueSnackbar, loading]);

  return (
    <Box className={styles.invoiceHistory} marginTop={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" className={styles.invoiceHistoryTitle}>
          {t('Invoice History')}
        </Typography>
        {invoiceHistoryData && (
          <CustomButton id="view-all-btn" variant="tonal" onClick={viewAllHistory}>
            {loading && (
              <Box marginRight={3} display="flex" alignItems="center">
                <CircularProgress size={20} />
              </Box>
            )}
            {t('View All')}
          </CustomButton>
        )}
      </Box>
      <Box marginTop={4}>
        {invoiceHistoryData && historyList.length > 0 ? (
          historyList.map(item => {
            return (
              <Box
                key={item.label}
                display="flex"
                alignItems="center"
                className={styles.invoiceHistoryItem}
                onClick={() => {
                  window.open(item.url);
                }}
              >
                <Box width={200}>
                  <Typography maxWidth={200}>{item.label}</Typography>
                </Box>
                <Box width={100} marginLeft={15}>
                  <Box className={classNames(styles.status, item.status)}>{item.status}</Box>
                </Box>
                <Box className={styles.payment}>{item.payment}</Box>
                {item.plan && (
                  <Box marginLeft={3}>
                    <Typography variant="body_medium">{item.plan}</Typography>
                  </Box>
                )}
                <div style={{ flex: 1 }}></div>
                <Button id="view-invoice" variant="text" color="primary">
                  {t('View')}
                </Button>
              </Box>
            );
          })
        ) : (
          <Box className={planStyles.noData}>{t('No invoice history yet')}</Box>
        )}
      </Box>
    </Box>
  );
};

export default InvoiceHistory;
