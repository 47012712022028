import AntSwitch from '@clef/client-library/src/components/AntSwitch';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import React, { useCallback, useEffect, useState } from 'react';
import { useSubscriptionPlanSettings } from '../../hooks/api/useSubscriptionApi';
import predictOnUploadImg from '../../images/predict_on_upload.png';

const useStyles = makeStyles(theme => ({
  uploadLimitDialog: {
    width: 516,
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.grey[900],
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  gotItButton: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.blue[600],
  },
  img: {
    verticalAlign: 'middle',
    width: '100%',
    borderRadius: 10,
    boxShadow: '0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -4px rgba(24, 39, 75, 0.08)',
  },
}));

const UploadLimitDialog: React.FC<
  DialogProps & {
    onGotIt?: (checked?: boolean) => void;
  }
> = props => {
  const styles = useStyles();
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [orgPlanSetting] = useSubscriptionPlanSettings('anystring');

  const onGotIt = useCallback(async () => {
    props.onGotIt?.(checked);
    setOpen(false);
  }, [checked, props]);

  useEffect(() => {
    setOpen(props.open);
  }, [open, orgPlanSetting, props.open]);

  return (
    <Dialog {...{ ...props, open }}>
      <DialogContent className={styles.uploadLimitDialog}>
        <Box flexDirection="column" justifyContent="center" alignItems="center" display="flex">
          <Typography className={styles.title} variant="h4">
            {t('LandingLens automatically makes predictions on newly uploaded images')}
          </Typography>
        </Box>
        <Box marginTop={6}>
          <Typography>
            {t(
              'Viewing predictions immediately after upload will use credits. You can change this setting on the Plan and Billing page anytime.',
            )}
          </Typography>
        </Box>
        <Box marginTop={6}>
          <img src={predictOnUploadImg} alt="" className={styles.img} />
        </Box>
        <Box display="flex" alignItems="center" marginTop={6}>
          <AntSwitch
            checked={checked}
            onChange={(...[, checked]) => {
              setChecked(checked);
            }}
          ></AntSwitch>
          <Typography>{t('Make predictions on newly uploaded images')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className={styles.gotItButton}
          onClick={onGotIt}
          id="upload-got-it-button"
        >
          {t('Got it')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadLimitDialog;
