import { AnnotationSortOptions, AnnotationSortType, UploadType } from '../types';

export const MAX_LABELING_QUORUM_SIZE = 5;

export const DRAWER_WIDTH = 240;
export const NEW_DRAWER_WIDTH = 80;
export const SIDEBAR_ICON_SIZE = 24;

export const STANDARD_ACCESS_CODE = 'LL-SALES-DEMO';

export enum PageName {
  MyProfile = 'MyProfile',
  ProjectsPage = 'ProjectsPage',
  DevicesPage = 'DevicesPage',
  MembersPage = 'MembersPage',
  FirstProjectPage = 'FirstProjectPage',
  ApiKeyPage = 'ApiKeyPage',
  PlanPage = 'PlanPage',
  CancelPlanPage = 'CancelPlanPage',
  ApiDocumentationPage = 'ApiDocumentationPage',
  LandingPage = 'LandingPage',
  OrganizationSettingsPage = 'OrganizationSettingsPage',
  MyTasks = 'MyTasks',
  ProjectSettings = 'ProjectSettings',
  DataBrowserOld = 'DataBrowserOld',
  DataBrowserNew = 'DataBrowserNew',
  MediaDetails = 'MediaDetails',
  InstantLearningImageDetails = 'InstantLearningImageDetails',
  ExportedDataset = 'ExportedDataset',
  MetadataPage = 'MetadataPage',
  TagsPage = 'TagsPage',
  DefectBook = 'DefectBook',
  DefectBookPrint = 'DefectBookPrint',
  DatasetSnapshot = 'DatasetSnapshot',
  CustomTraining = 'CustomTraining',
  DefectConsensus = 'DefectConsensus',
  DefectConsensusResult = 'DefectConsensusResult',
  LabelingPage = 'LabelingPage',
  LabelingTool = 'LabelingTool',
  LabelingTask = 'LabelingTask',
  LabelingReview = 'LabelingReview',
  ModelListPage = 'ModelListPage',
  ModelV2ListPage = 'ModelV2ListPage',
  ModelComparisonReportsPage = 'ModelComparisonReportsPage',
  ModelAnalysisFailedJobs = 'ModelAnalysisFailedJobs',
  ErrorAnalysis = 'ErrorAnalysis',
  ComparisonReportPage = 'ComparisonReportPage',
  TrainingJobsPage = 'TrainingJobsPage',
  CreateJobsPage = 'CreateJobsPage',
  JobDetailsPage = 'JobDetailsPage',
  DeploymentPage = 'DeploymentPage',
  DeployPage = 'DeployPage',
  HomePage = 'HomePage',
  ExamplesPage = 'ExamplesPage',
}

export const OnpremPage = [
  PageName.MyProfile,
  PageName.ProjectsPage,
  PageName.MembersPage,
  PageName.FirstProjectPage,
  PageName.ApiKeyPage,
  PageName.ApiDocumentationPage,
  PageName.MyTasks,
  PageName.ProjectSettings,
  PageName.DataBrowserOld,
  PageName.DataBrowserNew,
  PageName.ExportedDataset,
  PageName.MetadataPage,
  PageName.DefectBook,
  PageName.DefectBookPrint,
  PageName.DefectConsensus,
  PageName.DefectConsensusResult,
  PageName.LabelingPage,
  PageName.LabelingTool,
  PageName.LabelingTask,
  PageName.LabelingReview,
];

// https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
// https://stackoverflow.com/questions/730133/what-are-invalid-characters-in-xml
export const NOT_ALLOWED_SPECIAL_CHARACTERS = /[` \\{}^%`[\]<>~()#|&?'"]/;
export const NOT_ALLOWED_SPECIAL_CHARACTERS_IN_EXPORT_DATASET_NAME = /[`\\{}^%`[\]<>~()|&'"]/;
export const NOT_PRINTABLE_CHARACTERS = /[^ -~]/;
export const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/bmp', 'image/png', 'text/xml', '.mpo'];
export const ACCEPTED_IMAGE_EXTENSIONS = ['.jpeg', '.jpg', '.bmp', '.png'];
export const ACCEPTED_LABEL_EXTENSIONS = ['.xml'];
export const LATEST_RELEASE_VERSION = 'v2024.jul.2';

// This is the default bucket to store customer data for orgs in onprem.
// In the future it has the potential to become bucket for the whole platform.
export const DEFAULT_CUSTOMER_BUCKET = 'platform-customer-bucket';

export const CURRENT_ANNOTATION_VERSION = 3;

export const DEFAULT_MODEL_CONFIDENCE = 0.5;

export const UNLABELED_MEDIA_TEXT = 'Unlabeled';

export const MAX_NAME_LENGTH = 255;

/*  -- Sort Related options -- */
export enum SORTABLE_MODEL_TYPE {
  MEDIA = 'media',
  LABEL = 'groundTruth',
  PREDICTION = 'prediction',
}

export enum SORTABLE_OBJECT_TYPE {
  MEDIA = 'media',
  LABEL = 'groundTruth',
  PREDICTION = 'prediction',
  RANDOM = 'random',
}

export const NO_SPLIT_DISPLAY_NAME = 'unassigned';

export const DefaultGTSortOptions: AnnotationSortOptions = {
  sortType: AnnotationSortType.labelTime,
  sortOrder: 'desc',
};
export const DefaultPredSortOptions: AnnotationSortOptions = {
  sortType: AnnotationSortType.confidence,
  sortOrder: 'desc',
};

export const LIMIT_TO_PATH = {
  project: '/project/create',
  user: '/account/invite_user',
  media: '/medias/sign',
};

export const LIMIT_SKIP_RULE = {
  // Only limits dataset image uploads, no limit for defect book image uploads
  '/medias/sign': (_path: string, body: { uploadType: UploadType }) =>
    body.uploadType !== UploadType.Dataset,
} as Record<string, undefined | ((path: string, body: any) => boolean)>;

export const RESERVED_CLASS_NAMES = ['ok'];

export const COLOR_ARCHIVED_DEFECT = '#808080';

export const RENAME_ARCHIVED_DEFECT = ' (deleted)';

export const MAX_PARALLEL_TRAINING_JOBS = 10;

export const CONFIDENCE_THRESHOLD_OPTIONS = {
  thresholdsMin: 0,
  thresholdsMax: 1,
  thresholdsStep: 0.01,
};
