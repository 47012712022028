import React, { useRef, useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { makeStyles, Box, Theme } from '@material-ui/core';
import { AnnotationInstance, Media } from '@clef/shared/types';
import { useVirtualizer } from '@tanstack/react-virtual';
import { calcOptimalRatio } from '@/pages/DataBrowser/MediaGrid/MediaGrid';
import { useLocalStorage, Typography } from '@clef/client-library';
import { useGetModelMediaListInfiniteQuery } from '@/serverStore/modelAnalysis';
import { modelListFilterOptionsAtom } from '../atoms';
import { OnboardingTips } from '@/components/OnboardingTips';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { SortOrder } from '@/api/model_analysis_api';
import LoadingProgress from '../LoadingProgress';

const useStyles = makeStyles<Theme, { height: number }>(theme => ({
  mediaListRoot: {
    width: '100%',
  },
  listContainer: {
    height: '1000px',
    width: '100%',
    overflow: 'auto',
  },
  scrollContainer: {
    height: props => `${props.height}px`,
    width: '100%',
    position: 'relative',
  },
  onboardingWrapper: {
    border: `4px solid ${theme.palette.blue[100]}`,
    background: theme.palette.blue[50],
  },
  onboardingInnerWrapper: {
    border: `1px solid ${theme.palette.blue[500]}`,
    background: theme.palette.blue[25],
  },
  listItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
}));

export type ModelImageVirtualListWrapperProps = {
  evaluationSet?: EvaluationSetItem;
  modelId?: string;
  candidate?: string;
  candidateThreshold?: number;
  threshold?: number;
  titles: string[];
  onboardingTipsKey?: string;
  containerWidth: number;
  sortOrder: SortOrder;
  sortFunctions?: (a: Media, b: Media) => number;
  columns?: number;
  rowRender: (
    media: Media,
    rowWidth: number,
    allInstances?: AnnotationInstance[],
    candidateAllInstances?: AnnotationInstance[],
  ) => React.ReactNode;
};

const ModelImageVirtualListWrapper: React.FC<ModelImageVirtualListWrapperProps> = props => {
  const {
    modelId,
    threshold,
    candidate,
    candidateThreshold,
    evaluationSet,
    titles,
    columns = 2,
    sortFunctions,
    onboardingTipsKey,
    containerWidth,
    sortOrder,
    rowRender,
  } = props;
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const {
    data: mediaListPages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetModelMediaListInfiniteQuery(
    modelId,
    threshold,
    evaluationSet,
    filterOptions,
    candidate,
    candidateThreshold,
    candidate ? undefined : sortOrder, // sort in frontend for model comparison
  );

  const sortedMedias = useMemo(() => {
    const allMedias = (mediaListPages?.pages
      .flatMap(page => page?.mediaList)
      .filter(media => !!media) ?? []) as Media[];
    return sortFunctions ? allMedias.sort(sortFunctions) : allMedias;
  }, [mediaListPages, sortFunctions]);
  const allInstances = mediaListPages?.pages
    .flatMap(page => page?.allInstances)
    .filter(instance => !!instance) as AnnotationInstance[] | undefined;
  const candidateAllInstances = mediaListPages?.pages
    .flatMap(page => page?.candidateAllInstances)
    .filter(instance => !!instance) as AnnotationInstance[] | undefined;

  const [shownOnboardingTips] = useLocalStorage(onboardingTipsKey ?? '');
  const isShowingIncorrectMapping =
    filterOptions && filterOptions.gtClassId !== filterOptions.predClassId;
  const listRef = useRef<HTMLDivElement | null>(null);
  const imageRatio = calcOptimalRatio(sortedMedias);

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? sortedMedias.length + 1 : sortedMedias.length,
    estimateSize: () => (filterOptions ? 45 : 20) + (containerWidth * imageRatio) / columns,
    getScrollElement: () => listRef.current,
    overscan: 5,
  });
  const styles = useStyles({ height: rowVirtualizer.getTotalSize() });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= sortedMedias.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    sortedMedias.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ]);

  return (
    <>
      <Box display="flex" alignItems="center" marginBottom={3} id="model-list-titles">
        {titles.map(title => (
          <Box flex={1} key={title}>
            <Typography variant="body_bold">{title}</Typography>
          </Box>
        ))}
      </Box>
      <div className={styles.listContainer} ref={listRef}>
        <div className={styles.scrollContainer}>
          {rowVirtualizer.getVirtualItems().map(virtualRow => {
            const isLoaderRow = virtualRow.index > sortedMedias.length - 1;
            const media = sortedMedias[virtualRow.index];
            if (isLoaderRow) {
              return (
                <div
                  key={virtualRow.index}
                  className={styles.listItem}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <Box>{hasNextPage ? <LoadingProgress size={24} /> : 'Nothing more to load'}</Box>
                </div>
              );
            }

            return (
              <div
                key={virtualRow.index}
                className={styles.listItem}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {!shownOnboardingTips &&
                isShowingIncorrectMapping &&
                onboardingTipsKey &&
                virtualRow.index === 0 ? (
                  <Box height="100%" width="100%" className={styles.onboardingWrapper}>
                    <OnboardingTips
                      storageKey={onboardingTipsKey}
                      title={t(
                        'Now this type of ground truth and prediction pair is highlighted for each image.',
                      )}
                      placement={'top'}
                      tooltipStyles={{ width: 240 }}
                    />
                    <Box height="100%" width="100%" className={styles.onboardingInnerWrapper}>
                      {rowRender(
                        media,
                        imageRatio * containerWidth,
                        allInstances,
                        candidateAllInstances,
                      )}
                    </Box>
                  </Box>
                ) : (
                  rowRender(media, imageRatio * containerWidth, allInstances, candidateAllInstances)
                )}{' '}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ModelImageVirtualListWrapper;
