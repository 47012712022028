import React, { useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import OpenInNew from '@material-ui/icons/OpenInNew';
import CLEF_PATH from '@/constants/path';
import classNames from 'classnames';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import { Skeleton } from '@material-ui/lab';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { LabelType } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  modelImageDetailsStatusBar: {
    padding: theme.spacing(4, 6),
    backgroundColor: theme.palette.greyModern[50],
    color: theme.palette.greyModern[600],
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    borderRadius: '10px 10px 0 0',
  },
  deleted: {
    backgroundColor: theme.palette.red[50],
    color: theme.palette.red[600],
  },
  changed: {
    backgroundColor: theme.palette.yellow[50],
    color: theme.palette.yellow[600],
  },
}));

export type ModelImageDetailStatusBarProps = {
  mediaId?: number;
  modelId?: string;
  datasetId?: number;
  version?: number;
};

const ModelImageDetailStatusBar: React.FC<ModelImageDetailStatusBarProps> = props => {
  const styles = useStyles();
  const { mediaId, datasetId, modelId, version } = props;
  const {
    data: mediaDetails,
    isInitialLoading,
    isRefetching,
  } = useDatasetMediaDetailsQuery({
    datasetId,
    mediaId,
    modelId,
    ...(version && { versionId: version }),
  });
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  const deleted = mediaDetails?.hasBeenDeletedSinceSnapshot;
  const changed = mediaDetails?.hasChangedSinceSnapshot;

  const textContent = useMemo(() => {
    if (isInitialLoading || isRefetching) {
      return <Skeleton variant="text" width={200} />;
    }
    if (deleted) {
      return t('You are viewing the snapshot. This image {{bold}} after the snapshot was taken.', {
        bold: <strong>{t('was deleted')}</strong>,
      });
    }
    if (changed) {
      return t(
        'You are viewing the snapshot. The ground truth of this image has {{bold}} since the snapshot was taken.',
        {
          bold: <strong>{t('changed')}</strong>,
        },
      );
    }
    return t(
      'You are viewing the snapshot. The ground truth of this image has {{bold}} since the snapshot was taken.',
      {
        bold: <strong>{t('remained unchanged')}</strong>,
      },
    );
  }, [changed, deleted, isInitialLoading, isRefetching]);

  return (
    <Box
      className={classNames(
        styles.modelImageDetailsStatusBar,
        changed && styles.changed,
        deleted && styles.deleted,
      )}
      marginBottom={4}
    >
      {textContent}
      <div style={{ flex: 1 }}></div>
      <Button
        id="open-current-version-image-button"
        startIcon={<OpenInNew />}
        variant="outlined"
        size="small"
        onClick={() => {
          window.open(
            CLEF_PATH.data.mediaDetails.replace(
              CLEF_PATH.root,
              window.location.href.split('/models-v2')[0],
            ) +
              '/' +
              mediaId +
              `${labelType === LabelType.BoundingBox ? `?modelId=${modelId}` : ''}`,
            '_blank',
          );
        }}
      >
        {t('Open Current Version')}
      </Button>
    </Box>
  );
};

export default ModelImageDetailStatusBar;
