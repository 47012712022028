import React, { useState } from 'react';
import { Box, Popover, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { getColumnFilterMapWithModelId } from '@/pages/DataBrowser/dataBrowserState';
import { useDatasetModelMetricsQuery } from '@/serverStore/dataset';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';
import ControlledPerformanceCharts from '@/pages/DataBrowser/ModelPerformance/ControlledPerformanceCharts';
import { RegisteredModel } from '@clef/shared/types';
import { BundleInfo } from '@/api/model_api';

const useStyles = makeStyles(theme => ({
  chartContainer: {
    width: 400,
    maxHeight: 400,
    padding: theme.spacing(6),
  },
  actionItemsContainer: {
    paddingBottom: theme.spacing(6),
  },
  actionItemCancel: {
    margin: theme.spacing(0, 4),
  },
}));

export type AdjustThresholdDropdownProps = {
  model?: RegisteredModel & { bundles?: BundleInfo[] };
  initialThreshold: number;
  anchorEl: any;
  handleClose: () => void;
  onSave: (threshold: number) => void;
  onCancel: () => void;
};

const AdjustThresholdDropdown = (props: AdjustThresholdDropdownProps) => {
  const { model, initialThreshold, anchorEl, handleClose, onSave, onCancel } = props;
  const [threshold, setThreshold] = useState(initialThreshold);
  const styles = useStyles();

  const { data: datasetModelMetrics, isLoading: modelMetricsLoading } = useDatasetModelMetricsQuery(
    {
      modelId: model?.id,
      columnFilterMap: getColumnFilterMapWithModelId({}, model?.id, true),
      useVersionedDataset: true,
    },
    isModelTrainingSuccessful(model?.status, model?.metricsReady),
  );

  return modelMetricsLoading || !datasetModelMetrics ? null : (
    <Popover
      id="adjust-threshold-dropdown"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <Box display="flex" flexDirection="column" className={styles.chartContainer}>
        <ControlledPerformanceCharts
          modelId={model?.id}
          threshold={threshold}
          modelMetrics={datasetModelMetrics}
          onThresholdChange={newThreshold => setThreshold(newThreshold)}
          alwaysShowChart={true}
          marks={model?.bundles?.map(bundle => {
            return { value: bundle.threshold, label: `${bundle.threshold} (saved)` };
          })}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        className={styles.actionItemsContainer}
      >
        <Button
          id="save-threshold"
          color="primary"
          variant="contained"
          onClick={() => onSave(threshold)}
        >
          {t('Save')}
        </Button>
        <Button
          id="cancel-threshold"
          color="default"
          className={styles.actionItemCancel}
          onClick={() => {
            onCancel();
            setThreshold(initialThreshold);
          }}
        >
          {t('Cancel')}
        </Button>
      </Box>
    </Popover>
  );
};

export default AdjustThresholdDropdown;
