import { useTypedSelector } from '@/hooks/useTypedSelector';
import {
  AnnotationInstance,
  Dataset,
  DatasetGroupOptions,
  DatasetId,
  DatasetVersionId,
  FormattedColumnFilterMap,
  FormattedFieldFilterMap,
  LabelType,
  MediaDetailsWithPrediction,
  MediaStatsAPIResponse,
  MediaWithDatasetContent,
  PerformanceMetrics,
  ProjectId,
  SelectMediaOption,
  SortFieldInfo,
  SortOptions,
  SnapshotModelInfo,
  RegisteredModelId,
} from '@clef/shared/types';
import { useQuery, useInfiniteQuery, Query } from '@tanstack/react-query';
import DatasetAPI from '@/api/dataset_api';
import { useGetSelectedProjectQuery } from '../projects';
import { ApiErrorType } from '@/api/base_api';
import { useCurrentProjectModelInfoQuery } from '../projectModels';
import { isEqual } from 'lodash';

interface DatasetStatsParams {
  selectOptions?: SelectMediaOption;
  groupOptions: DatasetGroupOptions[];
  version?: DatasetVersionId;
}

interface DatasetExportedWithVersionsParams {
  withCount?: boolean;
  includeNotCompleted?: boolean;
  includeFastEasy?: boolean;
}

interface DatasetMediaDetailsParams {
  datasetId?: DatasetId;
  mediaId?: number | null;
  versionId?: number;
  modelId?: string;
}

interface DatasetMediaCountParams {
  projectId?: ProjectId;
  datasetId?: DatasetId;
  selectOptions?: SelectMediaOption;
  labeledOnly?: boolean;
  version?: number;
}

interface DatasetMediaListParams {
  datasetId: DatasetId | undefined;
  projectId: ProjectId | undefined;
  sortOptions?: SortOptions;
  columnFilterMap?: object;
  metadataFilterMap?: object;
  includeMediaStatus?: boolean;
  paginationLimit?: number;
  version?: DatasetVersionId;
}

interface DatasetModelMetricsParams {
  modelId?: string;
  metadataFilterMap?: FormattedFieldFilterMap;
  columnFilterMap?: FormattedColumnFilterMap;
  viewMode?: string;
  useVersionedDataset?: boolean;
}

enum DatasetQueryProperties {
  FilterOptions = 'filterOptions',
  Stats = 'stats',
  FieldInfo = 'fieldInfo',
  ExportedWithVersions = 'exportedWithVersions',
  Medias = 'medias',
  MediaCount = 'mediaCount',
  MediaDetails = 'mediaDetails',
  MediaList = 'mediaList',
  AnnotationInstances = 'annotationInstances',
  AnnotationInstancesCount = 'annotationInstancesCount',
  ModelMetrics = 'modelMetrics',
  SnapshotModels = 'snapshotModels',
}

interface SnapshotModelsParams {
  datasetVersionId: DatasetVersionId;
}

export const datasetQueryKeys = {
  all: (projectId: ProjectId) => [projectId, 'dataset'] as const,
  allWithFilters: (projectId: ProjectId) =>
    [...datasetQueryKeys.all(projectId), 'withFilters'] as const,
  allWithFiltersExcludingMediasAndCounts: (projectId: ProjectId) => ({
    predicate: (query: Query) => {
      const withFiltersKeys = datasetQueryKeys.allWithFilters(projectId);
      return (
        isEqual(query.queryKey.slice(0, withFiltersKeys.length), withFiltersKeys) &&
        !query.queryKey.includes(DatasetQueryProperties.MediaCount) &&
        !query.queryKey.includes(DatasetQueryProperties.Medias)
      );
    },
  }),
  filterOptions: (projectId: ProjectId, version?: number) =>
    [...datasetQueryKeys.all(projectId), DatasetQueryProperties.FilterOptions, version] as const,
  allStats: (projectId: ProjectId) =>
    [...datasetQueryKeys.allWithFilters(projectId), DatasetQueryProperties.Stats] as const,
  stats: (projectId: ProjectId, datasetId: DatasetId, params: DatasetStatsParams) =>
    [
      ...datasetQueryKeys.allStats(projectId),
      datasetId,
      params.groupOptions,
      params.selectOptions ?? {},
      params.version,
    ] as const,
  fieldInfo: () => ['dataset', DatasetQueryProperties.FieldInfo] as const,
  exportedWithVersions: (projectId: ProjectId, params?: DatasetExportedWithVersionsParams) => {
    if (params) {
      return [
        ...datasetQueryKeys.all(projectId),
        DatasetQueryProperties.ExportedWithVersions,
        {
          withCount: params.withCount || false,
          includeNotCompleted: params.includeNotCompleted || false,
          includeFastEasy: params.includeFastEasy,
        },
      ] as const;
    }
    return [
      ...datasetQueryKeys.all(projectId),
      DatasetQueryProperties.ExportedWithVersions,
    ] as const;
  },
  mediaCount: (projectId: ProjectId, datasetId?: DatasetId, params?: DatasetMediaCountParams) =>
    [
      ...datasetQueryKeys.allWithFilters(projectId),
      DatasetQueryProperties.MediaCount,
      ...(datasetId ? [datasetId] : []),
      ...(params
        ? [
            params.selectOptions || {
              selectedMedia: [],
              unselectedMedia: [],
              fieldFilterMap: {},
              columnFilterMap: {},
              isUnselectMode: false,
            },
            params.version,
            params.labeledOnly,
          ]
        : []),
    ] as const,
  mediaDetails: (datasetId: DatasetId, params?: DatasetMediaDetailsParams) =>
    [
      'dataset',
      DatasetQueryProperties.MediaDetails,
      datasetId,
      'mediaDetails',
      ...(params
        ? [
            ...(params?.mediaId ? [{ mediaId: params.mediaId }] : []),
            ...(params?.modelId ? [{ modelId: params.modelId }] : []),
            ...(params?.versionId ? [{ versionId: params.versionId }] : []),
          ]
        : []),
    ] as const,
  medias: (projectId: ProjectId, params?: Omit<DatasetMediaListParams, 'projectId'>) =>
    [
      ...datasetQueryKeys.allWithFilters(projectId!),
      DatasetQueryProperties.Medias,
      ...(params
        ? [
            params.datasetId,
            params.sortOptions,
            params.columnFilterMap,
            params.metadataFilterMap,
            params.includeMediaStatus,
            params.version,
          ]
        : []),
    ] as const,
  mediaList: (params: DatasetMediaListParams) =>
    [
      ...datasetQueryKeys.allWithFilters(params.projectId!),
      DatasetQueryProperties.MediaList,
      params.datasetId,
      params.sortOptions,
      params.columnFilterMap,
      params.metadataFilterMap,
      params.includeMediaStatus,
      params.version,
    ] as const,
  annotationInstances: (
    projectId: ProjectId,
    modelId: RegisteredModelId | undefined,
    params?: Omit<Parameters<typeof DatasetAPI.getAnnotationInstances>[0], 'projectId' | 'modelId'>,
  ) => [
    ...datasetQueryKeys.allWithFilters(projectId),
    DatasetQueryProperties.AnnotationInstances,
    modelId,
    ...(params
      ? [
          params.sortOptions,
          params.mode,
          params.columnFilterMap,
          params.metadataFilterMap,
          params.threshold,
        ]
      : []),
  ],
  annotationInstancesCount: (
    projectId: ProjectId,
    modelId: RegisteredModelId | undefined,
    params?: Omit<
      Parameters<typeof DatasetAPI.getAnnotationInstancesCount>[0],
      'projectId' | 'modelId'
    >,
  ) => [
    ...datasetQueryKeys.allWithFilters(projectId),
    DatasetQueryProperties.AnnotationInstancesCount,
    modelId,
    ...(params
      ? [
          params.instanceViewMode,
          params.columnFilterMap,
          params.metadataFilterMap,
          params.threshold,
        ]
      : []),
  ],
  modelMetrics: (
    projectId: ProjectId,
    params?: { datasetId?: DatasetId } & DatasetModelMetricsParams,
  ) => [
    ...datasetQueryKeys.allWithFilters(projectId),
    DatasetQueryProperties.ModelMetrics,
    ...(params
      ? [
          params.datasetId,
          params.modelId,
          params.columnFilterMap,
          params.metadataFilterMap,
          params.viewMode,
          params.useVersionedDataset,
        ]
      : []),
  ],
  snapshotModels: (projectId: ProjectId, params: SnapshotModelsParams) => [
    projectId,
    DatasetQueryProperties.SnapshotModels,
    params.datasetVersionId,
  ],
};

export const useGetDatasetFilterOptionsQuery = (version?: number) => {
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId) ?? 0;
  return useQuery({
    queryKey: datasetQueryKeys.filterOptions(selectedProjectId, version),
    queryFn: async () => DatasetAPI.getDatasetFilterOptions(selectedProjectId, version),
    enabled: !!selectedProjectId,
  });
};

export const useGetDatasetStatsQuery = (params: DatasetStatsParams, isEnabled = true) => {
  const { datasetId = 0, id: projectId = 0 } = useGetSelectedProjectQuery().data ?? {};
  return useQuery<MediaStatsAPIResponse[], ApiErrorType>({
    queryKey: datasetQueryKeys.stats(projectId, datasetId, params),
    queryFn: async () =>
      DatasetAPI.getStats(
        datasetId,
        projectId,
        params.selectOptions ?? {},
        params.groupOptions,
        params.version,
      ),
    enabled: isEnabled && !!datasetId && !!projectId,
  });
};

export const useDatasetSortableFieldInfoQuery = () => {
  return useQuery<SortFieldInfo[], ApiErrorType>({
    queryKey: datasetQueryKeys.fieldInfo(),
    queryFn: async () => DatasetAPI.getSortableFieldInfo(),
  });
};

export const useDatasetExportedWithVersionsQuery = (params: DatasetExportedWithVersionsParams) => {
  const { datasetId = 0, id: projectId = 0 } = useGetSelectedProjectQuery().data ?? {};
  return useQuery({
    queryKey: datasetQueryKeys.exportedWithVersions(projectId, params),
    queryFn: async () =>
      DatasetAPI.getExportedDatasetsWithVersions(
        projectId,
        params.withCount || false,
        params.includeNotCompleted || false,
        params.includeFastEasy,
      ),
    select: (data: Dataset[]) => data.find(_ => _.id === datasetId),
    enabled: !!projectId,
  });
};

export const useDatasetMediaDetailsQuery = (
  params: DatasetMediaDetailsParams,
  enabled = true,
  keepPreviousData = false,
) => {
  return useQuery<MediaDetailsWithPrediction, ApiErrorType>({
    queryKey: datasetQueryKeys.mediaDetails(params.datasetId ?? 0, params),
    queryFn: async () =>
      DatasetAPI.getMediaDetails(
        params.datasetId!,
        params.mediaId!,
        params.versionId,
        params.modelId,
      ),
    enabled: !!params.datasetId && !!params.mediaId && enabled,
    keepPreviousData,
  });
};

export const useDatasetMediaListQuery = (params: DatasetMediaListParams) => {
  const {
    datasetId,
    projectId,
    sortOptions = {},
    columnFilterMap = {},
    metadataFilterMap = {},
    includeMediaStatus = false,
    version,
    paginationLimit = 50,
  } = params;
  const fetchMediaData = async ({ pageParam = 0 }) => {
    if (!projectId || !datasetId) return [];

    const res = await DatasetAPI.getMedias(
      datasetId,
      projectId,
      {
        ...sortOptions,
        offset: pageParam * paginationLimit,
        limit: paginationLimit,
      },
      columnFilterMap,
      metadataFilterMap,
      includeMediaStatus,
      version,
    );
    return res;
  };

  return useInfiniteQuery(datasetQueryKeys.mediaList(params), fetchMediaData, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < paginationLimit) return undefined;
      return pages.length;
    },
    enabled: !!projectId && !!datasetId,
  });
};

export const useDatasetMediasQuery = (
  params: Omit<DatasetMediaListParams, 'projectId' | 'datasetId'>,
  isEnabled = true,
) => {
  const { datasetId, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  return useQuery<MediaWithDatasetContent[], ApiErrorType>({
    queryKey: datasetQueryKeys.medias(projectId!, { datasetId: datasetId!, ...params }),
    queryFn: async () =>
      DatasetAPI.getMedias(
        datasetId!,
        projectId!,
        params.sortOptions,
        params.columnFilterMap,
        params.metadataFilterMap,
        params.includeMediaStatus,
        params.version,
      ),
    enabled: !!projectId && !!datasetId && isEnabled,
  });
};

export const useAnnotationInstancesQuery = (
  params: Omit<Parameters<typeof DatasetAPI.getAnnotationInstances>[0], 'projectId' | 'modelId'>,
  isEnabled = true,
) => {
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  return useQuery<AnnotationInstance[], ApiErrorType>({
    queryKey: [datasetQueryKeys.annotationInstances(projectId!, currentModelId, params)],
    queryFn: async () => {
      return DatasetAPI.getAnnotationInstances({
        projectId: projectId!,
        modelId: currentModelId,
        ...params,
      });
    },
    enabled: !!projectId && !!currentModelId && isEnabled,
  });
};

export const useAnnotationInstancesCountQuery = (
  params: Omit<
    Parameters<typeof DatasetAPI.getAnnotationInstancesCount>[0],
    'projectId' | 'modelId'
  >,
  isEnabled = true,
) => {
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  return useQuery({
    queryKey: datasetQueryKeys.annotationInstancesCount(projectId!, currentModelId, params),
    queryFn: async () => {
      return DatasetAPI.getAnnotationInstancesCount({
        projectId: projectId!,
        modelId: currentModelId,
        ...params,
      });
    },
    enabled: !!projectId && !!currentModelId && isEnabled,
  });
};

export const useDatasetModelMetricsQuery = (
  params: DatasetModelMetricsParams,
  isEnabled = true,
) => {
  const { datasetId, id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  return useQuery<PerformanceMetrics, ApiErrorType>({
    queryKey: datasetQueryKeys.modelMetrics(projectId!, {
      datasetId,
      ...params,
    }),
    queryFn: async () =>
      DatasetAPI.getModelMetrics(
        datasetId!,
        projectId!,
        params.modelId!,
        params.columnFilterMap,
        params.metadataFilterMap,
        params.viewMode,
        params.useVersionedDataset,
      ),
    enabled:
      !!projectId &&
      !!datasetId &&
      labelType !== LabelType.SegmentationInstantLearning &&
      !!params.modelId &&
      isEnabled,
  });
};

export const useDatasetMediaCountQuery = (params: DatasetMediaCountParams, isEnabled = true) => {
  const project = useGetSelectedProjectQuery().data;
  const projectId = params.projectId || project?.id;
  const datasetId = params.datasetId || project?.datasetId;
  return useQuery<number, ApiErrorType>({
    queryKey: datasetQueryKeys.mediaCount(projectId!, datasetId!, params),
    queryFn: async () => {
      const response = await DatasetAPI.getMediaCount(
        datasetId!,
        projectId!,
        params.selectOptions || {
          selectedMedia: [],
          unselectedMedia: [],
          fieldFilterMap: {},
          columnFilterMap: {},
          isUnselectMode: false,
        },
        params.labeledOnly,
        params.version,
      );
      return response.count;
    },
    enabled: Boolean(projectId && datasetId && isEnabled),
  });
};

export const useDatasetSnapshotModelsQuery = (params: SnapshotModelsParams, isEnabled = true) => {
  const { id: projectId = 0 } = useGetSelectedProjectQuery().data ?? {};
  return useQuery<SnapshotModelInfo[], ApiErrorType>({
    queryKey: datasetQueryKeys.snapshotModels(projectId, params),
    queryFn: async () => {
      const response = await DatasetAPI.getSnapshotModels(projectId, params.datasetVersionId);
      return response.data;
    },
    enabled: !!projectId && !!params.datasetVersionId && isEnabled,
  });
};
