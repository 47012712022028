import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: theme.palette.blue[600],
    boxShadow: `0 0 0 ${alpha(theme.palette.blue[700], 0.8)}`,
    animation: `$pulsing 1500ms ${theme.transitions.easing.easeOut} infinite`,
  },
  '@keyframes pulsing': {
    '0%': {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.blue[700], 0.8)}`,
    },
    '70%': {
      boxShadow: `0 0 0 10px ${alpha(theme.palette.blue[700], 0.0)}`,
    },
    '100%': {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.blue[700], 0.0)}`,
    },
  },
}));

export interface PulsingDotProps {
  style?: React.CSSProperties;
  className?: string;
}

export const PulsingDot: React.FC<PulsingDotProps> = ({ style, className }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.container, className)} style={style}>
      <div className={classes.circle} />
    </div>
  );
};
