import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Predict } from '@/components/Predict';
import { UpdateThreshold } from '@/pages/DataBrowser/FirstRunLiveExperienceDialog/UpdateThreshold';
import {
  useGetSelectedProjectQuery,
  useGetProjectVersionedDefectsQuery,
} from '@/serverStore/projects';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import { useDatasetExportedWithVersionsQuery } from '@/serverStore/dataset';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxWidth: 1200,
    maxHeight: 900,
  },
  modalPredictContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
  },
  modalPredictWrapper: {
    padding: theme.spacing(6, 10, 10),
  },
  modalSidebar: {
    backgroundColor: theme.palette.common.white,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
  },
  modalBanner: {
    padding: theme.spacing(3),
  },
}));

export type PredictDialogProps = {
  open: boolean;
  onClose: () => void;
  modelInfo:
    | {
        id: string | undefined;
        threshold: number | undefined;
      }
    | undefined
    | null;
  selectedEndpointId?: string | null;
};

export const PredictDialog: React.FC<PredictDialogProps> = ({
  open,
  onClose,
  modelInfo,
  selectedEndpointId,
}) => {
  const styles = useStyles();
  const {
    name,
    labelType,
    id: projectId,
    datasetId,
  } = useGetSelectedProjectQuery().data ?? {
    name: '',
    labelType: undefined,
    id: undefined,
    datasetId: undefined,
  };
  const { data: projectModelsData } = useGetProjectModelListQuery();
  const registeredModel = projectModelsData?.find(model => model.id === modelInfo?.id)!;
  const selectedDatasetVersion = registeredModel?.datasetVersionId!;
  const { data: datasetExported } = useDatasetExportedWithVersionsQuery({
    withCount: false,
    includeNotCompleted: true,
    includeFastEasy: true,
  });
  const datsetVersions = datasetExported?.datasetVersions;
  const currentVersion = datsetVersions?.find(version => version.id === selectedDatasetVersion);

  const allDefects = useGetProjectVersionedDefectsQuery(currentVersion?.version).data!;
  const [currentThreshold, setCurrentThreshold] = useState<number | undefined>(
    modelInfo?.threshold,
  );

  useEffect(() => {
    setCurrentThreshold(modelInfo?.threshold);
  }, [modelInfo?.threshold]);

  if (!projectId || !datasetId) return null;

  return open ? (
    <Predict
      selectedProject={{
        projectId,
        datasetId,
        name,
        labelType,
      }}
      modelInfo={{
        id: modelInfo?.id,
        threshold: currentThreshold,
      }}
      defectMap={allDefects}
      title="Try this model"
      onCloseModal={onClose}
      classes={{
        container: styles.modalPredictContainer,
        wrapper: styles.modalPredictWrapper,
        bannerContainer: styles.modalBanner,
      }}
      updateThreshold={setCurrentThreshold}
      deployComponent={
        <UpdateThreshold
          modelInfo={{
            id: modelInfo?.id,
            threshold: currentThreshold,
          }}
          selectedEndpointId={selectedEndpointId}
          disabled={currentThreshold === modelInfo?.threshold}
          onResetThresholdButtonClick={() => setCurrentThreshold(modelInfo?.threshold)}
        />
      }
    />
  ) : null;
};

export default PredictDialog;
