import React from 'react';
import { Tooltip, Box, Typography, makeStyles } from '@material-ui/core';

import { useLocalStorage } from '@clef/client-library';
import { PulsingDot } from '@/components/OnboardingHighlightBadge';

const useStyles = makeStyles(theme => ({
  popper: {
    pointerEvents: 'auto',
    zIndex: 1300,
  },
  arrow: {
    color: theme.palette.blue[700],
    left: '278px !important',
  },
  tooltip: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  SnapshotActionButtonPos: {
    position: 'absolute',
    left: 6,
    top: 22,
  },
}));

export interface SnapshotActionButtonTipsProps {}

export const SnapshotActionButtonTips: React.FC<SnapshotActionButtonTipsProps> = () => {
  const styles = useStyles();

  const [shownSnapshotActionButtonTips, setSnapshotActionButtonTips] = useLocalStorage(
    'shownSnapshotActionButtonTips',
  );

  return (
    <>
      {shownSnapshotActionButtonTips ? (
        <></>
      ) : (
        <Tooltip
          open
          arrow
          placement="bottom-end"
          classes={{
            popper: styles.popper,
            arrow: styles.arrow,
            tooltip: styles.tooltip,
          }}
          title={
            <Box display="flex" alignItems="flex-end" flexDirection="column">
              <Typography>
                {t(
                  '{{action}} You can use snapshots to train models and create new projects. You can revert back to a snapshot at any time.',
                  {
                    action: (
                      <strong>{'Save your entire dataset or a subset of it as a snapshot.'}</strong>
                    ),
                  },
                )}
              </Typography>
              <Box
                onClick={() => setSnapshotActionButtonTips(true)}
                className={styles.gotIt}
                marginTop={3}
              >
                {t('Got it')}
              </Box>
            </Box>
          }
        >
          <Box className={styles.SnapshotActionButtonPos}>
            <PulsingDot />
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default SnapshotActionButtonTips;
