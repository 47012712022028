import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  ClickAwayListener,
  TextField,
  Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import { useAtom } from 'jotai';

import { Typography, useWindowEventListener, IconButton } from '@clef/client-library';

import { getReadableTime } from '@/utils';
import {
  useDatasetExportedWithVersionsQuery,
  useDatasetMediaCountQuery,
  useEditSnapshotInfoMutation,
} from '@/serverStore/dataset';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { appliedFiltersAtom } from '@/uiStates/mediaFilter';

import {
  appliedFilterMappingToFormattedFilterMapping,
  getColumnFilterMapWithModelId,
} from '@/pages/DataBrowser/dataBrowserState';

import SnapshotListDrawer, { rightDrawerWidth } from './SnapshotListDrawer';
import SnapshotSummary from './SnapshotSummary';
import SnapshotMedias from './SnapshotMedias';
import SnapshotMediaController from './SnapshotMedias/MediaController';
import SnapshotMainUse from './SnapshotAction/SnapshotMainUse';
import SnapshotMoreAction from './SnapshotAction/SnapshotMoreAction';
import SnapshotTabsTips from './Onboardingtips/SnapshotTabsTips';
import SnapshotUsageTips from './Onboardingtips/SnapshotUsageTips';

interface DatasetSnapshotProps {}

const a11yProps = (index: any) => ({
  id: `tab-${index}`,
  'aria-controls': `dataset-snapshot-${index}`,
});

const useStyles = makeStyles(theme => ({
  mainContent: {
    marginRight: rightDrawerWidth - 32,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minWidth: 620,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    marginBottom: theme.spacing(3),
  },
  leftContent: {
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    // overflow: 'hidden',
  },
  nameWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  snapshotNameText: {
    fontSize: 24,
    marginRight: theme.spacing(2),
  },
  snapshotNameInput: {
    width: 400,
    marginRight: theme.spacing(2),
  },
  inputError: {
    position: 'relative',
    top: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    position: 'relative',
  },
  tabsWrapper: {
    position: 'relative',
    flexShrink: 0,
  },
  tabsRoot: {
    minHeight: 36,
    overflow: 'hidden',
  },
  tabsFlexContainer: {
    height: '100%',
    columnGap: theme.spacing(7),
  },
  tabsIndicator: {
    backgroundColor: theme.palette.blue[600],
    bottom: theme.spacing(0.5),
  },
  tab: {
    flexBasis: 'fit-content',
    height: '100%',
    minWidth: 0,
    minHeight: 32,
    padding: 0,
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#4D5761',
    whiteSpace: 'nowrap',
    opacity: 1,
  },
  tabSelected: {
    color: theme.palette.blue[600],
    fontWeight: 700,
  },
  tabContent: {
    flexGrow: 1,
    width: '100%',
    paddingRight: theme.spacing(3),
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  tabContentFixedHeight: {
    overflowY: 'hidden',
  },
  rightContent: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    position: 'relative',
    flexShrink: 1,
    minWidth: 200,
  },
  creatorInfo: {
    display: 'inline-flex',
    fontSize: 14,
    color: '#4D5761',
    marginRight: theme.spacing(4),
    flexShrink: 1,
    minWidth: 60,
    cursor: 'default',

    '& > span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap !important',
      textOverflow: 'ellipsis',

      '& > span': {
        whiteSpace: 'unset !important',
      },
    },
  },
}));

const DatasetSnapshot: React.FC<DatasetSnapshotProps> = () => {
  const styles = useStyles();

  const { version } = useParams<{ version: string }>();

  const { data: datasetExported, isLoading: datasetExportedLoading } =
    useDatasetExportedWithVersionsQuery({
      withCount: true,
      includeNotCompleted: true,
      includeFastEasy: true,
    });

  const editSnapshot = useEditSnapshotInfoMutation();

  const [appliedFilters] = useAtom(appliedFiltersAtom);

  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const [columnFilterMap, metadataFilterMap] = useMemo(() => {
    const [col, metadata] = appliedFilterMappingToFormattedFilterMapping(
      appliedFilters[parseInt(version)] ?? {},
    );
    const colWithModelId = getColumnFilterMapWithModelId(col, currentModelId);
    return [colWithModelId, metadata];
  }, [appliedFilters, currentModelId, version]);

  const { data: mediaCount, isLoading: mediaCountLoading } = useDatasetMediaCountQuery(
    {
      version: parseInt(version),
      selectOptions: {
        fieldFilterMap: metadataFilterMap,
        columnFilterMap: columnFilterMap,
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
      },
    },
    Boolean(version),
  );

  const currentSnapshot = useMemo(() => {
    return datasetExported?.datasetVersions?.find(v => v.version === parseInt(version));
  }, [datasetExported?.datasetVersions, version]);

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const [mainContentHeight, setMainContentHeight] = useState<number>(
    // 48: header height, 88: upper content height, 28: margin bottom，50: breadcrumb height
    window.innerHeight - (48 + 88 + 28 + 50),
  );
  useWindowEventListener('resize', () => {
    setMainContentHeight(window.innerHeight - (48 + 88 + 28));
  });

  const [isEditingName, setIsEditingName] = useState(false);
  const [snapshotName, setSnapshotName] = useState('');
  useEffect(() => {
    if (currentSnapshot?.name !== undefined) {
      setSnapshotName(currentSnapshot?.name ?? '');
    }
  }, [currentSnapshot?.name]);
  const [nameError, setNameError] = useState('');

  const onSnapshotNameChange = useCallback(
    (name: string) => {
      if (!version || name === currentSnapshot?.name || !currentSnapshot) {
        return;
      }
      editSnapshot.mutate(
        { datasetVersionId: currentSnapshot?.id, name },
        {
          onError: () => {
            setSnapshotName(currentSnapshot?.name);
          },
        },
      );
    },
    [currentSnapshot, editSnapshot, version],
  );

  return (
    <>
      <Box className={styles.mainContent} style={{ height: mainContentHeight }}>
        <Box className={styles.header}>
          <Box className={styles.leftContent}>
            <Box className={styles.nameWrapper}>
              {datasetExportedLoading ? null : (
                <>
                  {isEditingName ? (
                    <>
                      <ClickAwayListener
                        onClickAway={() => {
                          if (!snapshotName) {
                            setNameError('Name is required to create a snapshot.');
                          } else if (snapshotName.length > 100) {
                            setNameError('Sorry, the name exceeds the 100-character limit.');
                          } else {
                            onSnapshotNameChange(snapshotName);
                            setIsEditingName(false);
                            setNameError('');
                          }
                        }}
                      >
                        <TextField
                          variant="outlined"
                          value={snapshotName}
                          size="small"
                          className={classNames(
                            styles.snapshotNameInput,
                            !!nameError && styles.inputError,
                          )}
                          onChange={e => {
                            setSnapshotName(e.target.value as string);
                          }}
                          error={!!nameError}
                          helperText={nameError}
                        />
                      </ClickAwayListener>

                      <IconButton
                        id="snapshot-save-name"
                        size="small"
                        onClick={() => {
                          if (!snapshotName) {
                            setNameError('Name is required to create a snapshot.');
                          } else if (snapshotName.length > 100) {
                            setNameError('Sorry, the name exceeds the 100-character limit.');
                          } else {
                            onSnapshotNameChange(snapshotName);
                            setIsEditingName(false);
                            setNameError('');
                          }
                        }}
                      >
                        <CheckIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Typography variant="h3" className={styles.snapshotNameText} maxWidth={360}>
                        {currentSnapshot?.name}
                      </Typography>

                      <IconButton
                        id="snapshot-edit-name"
                        size="small"
                        onClick={() => setIsEditingName(true)}
                        disabled={editSnapshot.isLoading}
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
          {!!currentSnapshot && (
            <Box className={styles.rightContent}>
              {currentSnapshot && (
                <Tooltip
                  placement="top"
                  title={`By ${currentSnapshot?.creator} at ${getReadableTime(
                    currentSnapshot.creationTime,
                  )}`}
                >
                  <Box className={styles.creatorInfo}>
                    {currentSnapshot.creator &&
                      t('By {{userName}} at {{time}}', {
                        userName: <strong>{currentSnapshot?.creator}</strong>,
                        time: getReadableTime(currentSnapshot.creationTime),
                      })}
                  </Box>
                </Tooltip>
              )}
              <SnapshotUsageTips />
              <SnapshotMainUse snapshot={currentSnapshot} />
              <SnapshotMoreAction snapshot={currentSnapshot} />
            </Box>
          )}
        </Box>

        <Box className={styles.toolbar}>
          <Box className={styles.tabsWrapper}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              classes={{
                root: styles.tabsRoot,
                flexContainer: styles.tabsFlexContainer,
                indicator: styles.tabsIndicator,
              }}
            >
              <Tab
                label={t(`Images${mediaCountLoading ? '' : ` (${mediaCount})`}`)}
                selected={selectedTab === 0}
                {...a11yProps(0)}
                classes={{
                  root: styles.tab,
                  selected: styles.tabSelected,
                }}
              />
              <Tab
                label="Snapshot summary"
                selected={selectedTab === 1}
                {...a11yProps(1)}
                classes={{
                  root: styles.tab,
                  selected: styles.tabSelected,
                }}
              />
            </Tabs>
            <SnapshotTabsTips />
          </Box>
          {selectedTab === 0 ? <SnapshotMediaController /> : null}
        </Box>

        <Box
          className={classNames(
            styles.tabContent,
            selectedTab === 0 && styles.tabContentFixedHeight,
          )}
        >
          {selectedTab === 0 && <SnapshotMedias />}
          {selectedTab === 1 && <SnapshotSummary />}
        </Box>
      </Box>

      <SnapshotListDrawer />
    </>
  );
};

export default DatasetSnapshot;
