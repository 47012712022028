// Create a new enum when you want to use a new feature toggle.
export enum ServerFeatures {
  CLIJobs = 'cli-jobs',
  FastNEasyWorkerForceArgo = 'mi.fastneasy.force-argo-workers',
  MultiFoldMislabelDetection = 'mi.mislabel-detection.run-on-fastneasy-flow',
  PreventSpinningUpCeleryWorker = 'mi.fastneasy.prevent-spinning-up-celery-worker',
  FastNEasyLargeImageSupport = 'fne-od-large-image-support',
  EnableCalculateEmbeddingOnUpload = 'quick-labeling-exp',
  IntercomIntegration = 'dm.intercom.integration',
  PricingV2 = 'dm.pricing.v2.stripe',
}

export enum ClientFeatures {
  SiteSwitch = 'site_switch_do_not_touch',
  PaulExportInternalAction = 'paul-pascal-export-internal-action',
  MingruiAnomalyDetection = 'mingrui-anomaly-detection',
  CristobalMedtronicDisableCloudDevice = 'cristobal-medtronic-disable-cloud-device',
  CristobalMedtronicDisableSupportCenter = 'cristobal-medtronic-disable-support-center',
  MIActionableErrorAnalysis = 'mi-actionable-error-analysis',
  J4679JunjieOnpremUiFeatures = 'j4679-junjie-onprem-ui-features',
  ClassificationGradcam = 'mi-lan-4022-gradcam',
  GreenfieldAppDownloadEnabled = 'GreenfieldAppDownloadEnabled',
  SCAppsEnabled = 'SCAppsEnabled',
  EnableLEappDownloadLink = 'leapp-vs-winapp-and-clapp',
  SegmentationInstantLearning = 'segmentation-instant-learning',
  NMSThresholdAdvTrain = 'nms-threshold-advance-train',
  EnableInternalSettings = 'internal-settings',
  EnableLanguageSwitch = 'language-switch',
  InstantLearningPostprocessing = 'dm.instant-learning.post-processing',
  QuickLabelingExp = 'quick-labeling-exp',
  ShouldAllowDownloadingBundle = 'new-edge-bundle-download-page',
  AdvancedUsageBasedPricing = 'advanced-usage-based-pricing',
  StreamYardIntegration = 'dm.streamyard.oct.3',
  ModelRunnerReady = 'edge.model-runner-ready',
}
