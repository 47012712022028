import React from 'react';
import { Typography } from '@material-ui/core';

import Column from '../../components/Layout/components/Column';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { TabManager } from '@clef/client-library';
import useStyles from './styles';
import { useFeatureGateEnabled } from '../../hooks/useFeatureGate';
import { ClientFeatures } from '@clef/shared/features';
import PlanAndBilling from './PlanAndBilling';
import Usage from './Usage';
import InternalSettings from './Settings/InternalSettings';
import ApiKeyV2 from '@/components/ApiKeyV2';
import MembersSection from './MembersSection';
import ActiveProjects from './ActiveProjects';
import { useActiveProjectsEnabled } from '@/hooks/useSubscriptions';

const OrganizationSettings: React.FC<{}> = () => {
  const styles = useStyles();
  const currentUser = useTypedSelector(state => state.login.user!);
  const enableInternalSettings = useFeatureGateEnabled(ClientFeatures.EnableInternalSettings);
  const isActiveProjectEnabled = useActiveProjectsEnabled();
  return (
    <div className={styles.orgSettingsPage}>
      <Column className={styles.pageTitleContainer}>
        <Typography variant="h1" className={styles.titleText}>
          {currentUser.company}
        </Typography>
      </Column>

      <TabManager
        tabs={[
          ...[
            {
              key: 'planAndBilling',
              title: t('Plan and Billing'),
              component: <PlanAndBilling />,
            },
          ],
          ...[
            {
              key: 'usage',
              title: t('Usage'),
              component: <Usage />,
            },
          ],
          {
            key: 'member',
            title: t('Members'),
            component: <MembersSection />,
          },
          ...(isActiveProjectEnabled
            ? [
                {
                  key: 'activeProject',
                  title: t('Active Projects'),
                  component: <ActiveProjects />,
                },
              ]
            : []),
          {
            key: 'api_key_v2',
            title: t('API Keys'),
            component: <ApiKeyV2 />,
          },
          ...(enableInternalSettings
            ? [
                {
                  key: 'internal_settings',
                  title: t('Internal Settings'),
                  component: <InternalSettings />,
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

export default OrganizationSettings;
