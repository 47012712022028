import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Button, IconButton, ToggleButton, useDebounce } from '@clef/client-library';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import SnowflakePathTreeView, { findNode } from '../TreeView/SnowflakePathTreeView';
import { Autocomplete } from '@material-ui/lab';
import {
  useGetSnowflakeDatabaseQuery,
  useGetSnowflakeFolderPathListQuery,
  useGetSnowflakeSchemaQuery,
  useGetSnowflakeStageQuery,
  useSyncSnowflakeDataMutation,
} from '@/serverStore/snowflake';
import LoadingProgress from '@/pages/model_iteration/componentsV2/LoadingProgress';
import { isExternalStage } from '@/api/pictor_api';
import { useSnackbar } from 'notistack';
import { useTypedSelector } from '@/hooks/useTypedSelector';

const SYNC_FOLDER_MEDIA_NUMBER_MAX_LIMIT = 1000;

export interface IProps {
  open: boolean;
  onClose: () => void;
  showClassificationToggle?: boolean;
}

const useStyles = makeStyles(theme => ({
  textFields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // width: '100%',
    margin: theme.spacing(2, 0, 5, 0),
    gap: theme.spacing(3),
  },
  schemaTextField: {
    width: '320px',
  },
  prefixTextField: {
    width: '200px',
  },
  label: {
    color: theme.palette.grey[800],
    fontWeight: 700,
  },
  copyToClipboardBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  selectInput: {
    padding: theme.spacing(2, 3),
  },
  copyIcon: {
    width: 20,
    height: 20,
  },
  treeViewContainer: {
    overflow: 'auto',
    minHeight: 120,
    maxHeight: 160,
    width: '100%',
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  commonAutocomplete: {
    flex: 1,
  },
  selectedFolderPath: {
    paddingLeft: 4,
    color: theme.palette.greyModern[500],
  },
  selectedFolderPathContainer: {
    wordWrap: 'break-word',
  },
}));

type CodeHighlighterProps = {
  command: string;
};

const CodeHighlighter = (props: CodeHighlighterProps) => {
  const { command } = props;
  const styles = useStyles();

  const copyToClipboard = useCopyToClipboard({
    text: command,
    successMessage: t('Command copied to clipboard.'),
  });

  return (
    <Box position="relative">
      <Box overflow="scroll">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          position="relative"
          width="100%"
          minWidth={600}
        >
          <Box position="relative" width="100%">
            <SyntaxHighlighter
              wrapLongLines={true}
              lineNumberStyle={{ display: 'none' }}
              language="bash"
              style={lightfair}
              customStyle={{
                margin: 0,
                background: 'rgba(188, 198, 212, 0.3)',
                width: '100%',
              }}
            >
              {command}
            </SyntaxHighlighter>
          </Box>
        </Box>
        <IconButton size="medium" className={styles.copyToClipboardBtn} onClick={copyToClipboard}>
          <FileCopyOutlinedIcon className={styles.copyIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

const getStageCommand = (
  isExternalStage: boolean,
  snowflakeApplicationName: string | undefined,
  database: string | undefined,
  schema: string | undefined,
  stage: string | undefined,
) => {
  const applicationName = snowflakeApplicationName ?? '<THIS APPLICATION NAME>';
  const isFullStageSelected = database && schema && stage;
  const databaseStr = isFullStageSelected ? database : 'YOUR_DB';
  const schemaStr = isFullStageSelected ? schema : 'YOUR_SCHEMA';
  const stageStr = isFullStageSelected ? stage : 'YOUR_STAGE';
  if (isExternalStage) {
    return `GRANT USAGE ON DATABASE ${databaseStr} TO APPLICATION ${applicationName};
GRANT USAGE ON SCHEMA ${databaseStr}.${schemaStr} TO APPLICATION ${applicationName};
GRANT USAGE ON STAGE ${databaseStr}.${schemaStr}.${stageStr} TO APPLICATION ${applicationName};`;
  }
  return `GRANT USAGE ON DATABASE ${databaseStr} TO APPLICATION ${applicationName};
GRANT USAGE ON SCHEMA ${databaseStr}.${schemaStr} TO APPLICATION ${applicationName};
GRANT READ ON STAGE ${databaseStr}.${schemaStr}.${stageStr} TO APPLICATION ${applicationName};`;
};

type CommonAutocompleteProps = {
  id: string;
  label: string;
  options: { title: string }[];
  loading: boolean;
  error: boolean;
  inputValue: string | undefined;
  setInputValue: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const CommonAutocomplete = (props: CommonAutocompleteProps) => {
  const { id, label, options, inputValue, setInputValue, loading, error } = props;
  const styles = useStyles();
  const [value, setValue] = React.useState<string | null>(null);
  const debouncedSetInputValue = useDebounce(setInputValue, 3000);
  return (
    <Autocomplete
      loading={loading}
      classes={{
        root: styles.commonAutocomplete,
      }}
      id={id}
      value={value}
      onChange={(_event: any, newValue: string | null) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        debouncedSetInputValue(newInputValue);
      }}
      freeSolo
      options={options.map(option => option.title)}
      renderInput={params => (
        <TextField error={error} {...params} label={label} margin="normal" variant="outlined" />
      )}
    />
  );
};

export const SnowflakeSyncDataDialog: React.FC<IProps> = ({
  open,
  onClose,
  showClassificationToggle = false,
}) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [database, setDatabase] = useState<string | undefined>();
  const [schema, setSchema] = useState<string | undefined>();
  const [stage, setStage] = useState<string | undefined>();
  const snowflakeEnv = useTypedSelector(state => state.login.user?.snowflakeEnv);
  const { applicationName: snowflakeApplicationName } = snowflakeEnv ?? {};
  const { data: databaseOptions, isLoading: isDatabaseOptionsLoading } =
    useGetSnowflakeDatabaseQuery();
  const { data: schemaOptions, isLoading: isSchemaOptionsLoading } =
    useGetSnowflakeSchemaQuery(database);
  const { data: stageOptions, isLoading: isStageOptionsLoading } = useGetSnowflakeStageQuery(
    database,
    schema,
  );

  const {
    data: folderPathList,
    isLoading: isFolderPathListLoading,
    isError: isFolderPathListError,
  } = useGetSnowflakeFolderPathListQuery(database, schema, stage);

  const [customPrefix, setCustomPrefix] = useState<string>(''); // only used when isFolderPathListError=true

  const [errors, setErrors] = useState<{
    database: boolean;
    schema: boolean;
    stage: boolean;
    prefix: boolean;
    customPrefix: boolean;
  }>({
    database: false,
    schema: false,
    stage: false,
    prefix: false,
    customPrefix: false,
  });
  const [selectedFolderPathNodeId, setSelectedFolderPathNodeId] = React.useState<string>('');
  const [clsToggleBtnOn, setClsToggleBtnOn] = useState<boolean>(false);

  const selectedFolderPath = selectedFolderPathNodeId; // backend has processed id = full folder path
  const selectedFolderPathNode = useMemo(() => {
    if (!folderPathList) {
      return undefined;
    }
    return findNode(folderPathList, selectedFolderPathNodeId || '');
  }, [folderPathList, selectedFolderPathNodeId]);
  const selectedFolderPathNodeMediaNum = selectedFolderPathNode?.num_medias;
  const folderFilesReachLimit =
    selectedFolderPathNodeMediaNum !== undefined &&
    selectedFolderPathNodeMediaNum > SYNC_FOLDER_MEDIA_NUMBER_MAX_LIMIT;

  const selectedStage = stageOptions?.find(stageOption => stageOption.stage === stage);
  const syncMutation = useSyncSnowflakeDataMutation();

  const isEmptyStr = (strToCheck: string | undefined) => {
    return !strToCheck || strToCheck.trim().length === 0;
  };
  const isDatabaseEmpty = isEmptyStr(database);
  const isSchemaEmpty = isEmptyStr(schema);
  const isStageEmpty = isEmptyStr(stage);
  const isFullStageSelected = !isDatabaseEmpty && !isSchemaEmpty && !isStageEmpty;
  const showCustomPrefixTextfield =
    !folderPathList && !isFolderPathListLoading && isFolderPathListError;

  useEffect(() => {
    setSelectedFolderPathNodeId('');
  }, [database, schema, stage]);

  const onSync = () => {
    if (!isFullStageSelected) {
      setErrors({
        database: isDatabaseEmpty,
        schema: isSchemaEmpty,
        stage: isStageEmpty,
        prefix: false,
        customPrefix: false,
      });
      return;
    }
    if (folderFilesReachLimit) {
      enqueueSnackbar(
        t(`Folder {{folderPath}} has {{numMedias}} medias, reaching max limit {{upperLimit}}`, {
          folderPath: selectedFolderPath,
          numMedias: selectedFolderPathNodeMediaNum,
          upperLimit: SYNC_FOLDER_MEDIA_NUMBER_MAX_LIMIT,
        }),
        { variant: 'error' },
      );
      return;
    }
    const fullStage = `${database}.${schema}.${stage}`;
    syncMutation.mutate(
      {
        stage: fullStage,
        prefix: showCustomPrefixTextfield ? customPrefix : selectedFolderPath ?? '',
        ...(clsToggleBtnOn && { create_label_for_classification: clsToggleBtnOn }),
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const renderFolderTree = () => {
    return (
      <>
        <Box paddingBottom={2}>
          {isFolderPathListLoading ? (
            <Box padding={4}>
              <LoadingProgress size={20} />
            </Box>
          ) : (
            <Box paddingTop={2} className={styles.selectedFolderPathContainer}>
              <Typography>
                {t('selected folder path: {{selectedFolderPath}}{{selectedFolderMediaNumber}}', {
                  selectedFolderPath: (
                    <Typography component="span" className={styles.selectedFolderPath}>
                      {selectedFolderPath}
                    </Typography>
                  ),
                  selectedFolderMediaNumber:
                    selectedFolderPathNodeMediaNum === undefined ? null : (
                      <Typography component="span" className={styles.selectedFolderPath}>
                        {t('({{selectedFolderPathNodeMediaNum}} medias)', {
                          selectedFolderPathNodeMediaNum,
                        })}{' '}
                      </Typography>
                    ),
                })}
              </Typography>
            </Box>
          )}
        </Box>
        {folderPathList && (
          <Box marginBottom={4} display={'flex'} className={styles.treeViewContainer}>
            <SnowflakePathTreeView
              data={folderPathList}
              selected={selectedFolderPathNodeId ?? ''}
              setSelected={setSelectedFolderPathNodeId}
            />
          </Box>
        )}
      </>
    );
  };

  const renderCustomizedPrefixTextField = () => {
    return (
      <Box className={styles.textFields}>
        <TextField
          className={styles.prefixTextField}
          placeholder="/Example/Path/To/Folder"
          required
          error={errors.customPrefix}
          value={customPrefix}
          onChange={e => {
            errors.prefix && setErrors(_errors => ({ ..._errors, prefix: false }));
            setCustomPrefix(e.target.value);
          }}
          variant="outlined"
        />
      </Box>
    );
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">{t('Sync Snowflake Data')}</Typography>
          <IconButton
            id="close-snowflake-sync-data-dialog"
            aria-label="close"
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography className={styles.label}>
            {t('Please fill out database, schema, stage')}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          paddingBottom={4}
        >
          <CommonAutocomplete
            id={'database-auto-complete'}
            label={'Database'}
            loading={isDatabaseOptionsLoading}
            error={errors.database}
            inputValue={database}
            setInputValue={setDatabase}
            options={
              databaseOptions?.map(databaseOption => {
                return {
                  title: databaseOption.database,
                };
              }) ?? []
            }
          />
          <Box width={12} />
          <CommonAutocomplete
            id={'schema-auto-complete'}
            label={'Schema'}
            loading={isSchemaOptionsLoading}
            error={errors.schema}
            inputValue={schema}
            setInputValue={setSchema}
            options={
              schemaOptions?.map(schemaOption => {
                return {
                  title: schemaOption.schema,
                };
              }) ?? []
            }
          />
          <Box width={12} />
          <CommonAutocomplete
            error={errors.stage}
            loading={isStageOptionsLoading}
            id={'stage-auto-complete'}
            label={'Stage'}
            inputValue={stage}
            setInputValue={setStage}
            options={
              stageOptions?.map(stageOption => {
                return {
                  title: stageOption.stage,
                };
              }) ?? []
            }
          />
        </Box>

        {isFullStageSelected && (
          <>
            <Box>
              <Typography className={styles.label}>
                {t('Specify the path to an existing folder')}
              </Typography>
              {showClassificationToggle && (
                <Box display="flex" flexDirection={'row'} alignItems={'center'}>
                  <Typography>
                    {t('Uploaded images will be classified based on your folder structure')}
                  </Typography>
                  <ToggleButton
                    isOn={clsToggleBtnOn}
                    onToggle={() => {
                      setClsToggleBtnOn(!clsToggleBtnOn);
                    }}
                    id={'cls-folder-toggle-btn'}
                  />
                </Box>
              )}
            </Box>
            {showCustomPrefixTextfield ? renderCustomizedPrefixTextField() : renderFolderTree()}
          </>
        )}
        <Box paddingBottom={4} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography className={styles.label}>
            {t('Please run the Snowflake SQL to grant stage read permission')}
          </Typography>
        </Box>
        <CodeHighlighter
          command={getStageCommand(
            isExternalStage(selectedStage?.stage_type ?? null),
            snowflakeApplicationName,
            database,
            schema,
            stage,
          )}
        />
      </DialogContent>
      <DialogActions>
        <Tooltip
          placement="top"
          arrow={true}
          title={t(
            `Folder {{folderPath}} has {{numMedias}} medias, reaching max limit {{upperLimit}}`,
            {
              folderPath: selectedFolderPath,
              numMedias: selectedFolderPathNodeMediaNum,
              upperLimit: SYNC_FOLDER_MEDIA_NUMBER_MAX_LIMIT,
            },
          )}
          open={folderFilesReachLimit}
        >
          <Button
            id="confirm-snowflake-sync-data"
            color="primary"
            onClick={() => onSync()}
            disabled={syncMutation.isLoading || folderFilesReachLimit}
          >
            {syncMutation.isLoading ? t('Syncing...') : t('Sync')}
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
