import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { Button, useLocalStorage } from '@clef/client-library';

import BaseDialog from '@/components/Dialogs/BaseDialog';
import MediaDetailsPageIntroImg from '@/images/media_details/ImageDetails.png';

const useStyles = makeStyles(theme => ({
  introImg: {
    width: '100%',
    objectFit: 'contain',
    margin: theme.spacing(6, 0),
  },
  confirmButton: {
    width: '100%',
  },
}));

export type MediaDetailsPageIntroDialogProps = {};

const MediaDetailsPageIntroDialog: React.FC<MediaDetailsPageIntroDialogProps> = () => {
  const styles = useStyles();
  const [skipMediaDetailsPageIntroDialog, setSkipMediaDetailsPageIntroDialog] = useLocalStorage(
    'skipMediaDetailsPageIntroDialog',
  );

  if (skipMediaDetailsPageIntroDialog) {
    return null;
  }

  return (
    <BaseDialog
      open
      maxWidth="xs"
      onClose={() => setSkipMediaDetailsPageIntroDialog(true)}
      title={t('Introducing a New Labeling Experience')}
    >
      <Typography variant="body1">
        {t(`We’ve simplified the layout and added more viewing options.`)}
      </Typography>

      <img src={MediaDetailsPageIntroImg} className={styles.introImg} />

      <Button
        id="media-details-page-dialog-confirm-button"
        variant="contained"
        color="primary"
        onClick={() => setSkipMediaDetailsPageIntroDialog(true)}
        className={styles.confirmButton}
      >
        {t('Got it')}
      </Button>
    </BaseDialog>
  );
};

export default MediaDetailsPageIntroDialog;
