import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Tooltip, Box, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useAtom, useSetAtom } from 'jotai';
import { cloneDeep } from 'lodash';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';

import {
  TransformType,
  Transform,
  Pipeline,
  Media,
  TransformId,
  TransformParamValue,
} from '@clef/shared/types';
import { greyModernScale, IconButton, Typography } from '@clef/client-library';

import { TRANSFORM_TEXTS } from '@/constants/model_train';
import { convertToShortNumberFormat } from '@/utils';
import { PreviewIcon } from '@/images/custom_training/icons';
import TransformsMenu from '@/pages/model_iteration/components/TransformsMenu';
import TransformModal from '@/pages/model_iteration/components/TransformModal';
import { useIsShowWarnAlert } from '@/pages/DataBrowser/TrainModelButtonGroup/state';
import {
  getMultipleAdjustedPipelineSections,
  handleSectionUpdate,
  extractNewTransform,
  getAppliedResizeOptions,
  getMaxTransformPixels,
} from '@/utils/job_train_utils';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import {
  modelsConfigListAtom,
  showLargeImagesWarningAtom,
  transformsUiSchemaAtom,
} from '@/uiStates/customTraining/pageUIStates';

const useStyles = makeStyles(theme => ({
  tooltip: {
    color: theme.palette.grey[400],
  },
  pipelineCell: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  cellValueList: {
    position: 'relative',
    color: theme.palette.greyModern[500],
    padding: theme.spacing(1, 16, 1, 2),
    border: `1px solid transparent`,
    borderRadius: 5,

    '&:hover': {
      borderColor: theme.palette.greyModern[300],

      '& $cellOps': {
        display: 'inline-flex',
      },
    },
  },
  cellValueItem: {
    display: 'flex',
    gap: theme.spacing(2),
    color: theme.palette.greyModern[500],
    lineHeight: '24px',
  },
  cellOps: {
    position: 'absolute',
    display: 'none',
    top: theme.spacing(1.5),
    right: theme.spacing(1),
    gap: theme.spacing(1),
    cursor: 'pointer',
  },
  buttonWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    background: theme.palette.greyBlue[100],
    borderRadius: 10,
  },
  alerts: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 8,
    margin: theme.spacing(4, 0),
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '14px',
  },
  warnAlert: {
    border: `1px solid ${theme.palette.orange[600]}`,
    backgroundColor: '#FFF5E7',
    padding: theme.spacing(2, 4),
    borderRadius: 4,
  },
  warnIcon: {
    color: theme.palette.orange[600],
    marginRight: theme.spacing(2),
  },
  alertText: {
    color: theme.palette.yellow[900],
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
  },
}));

interface ConfigListProps {
  rowIndex: number;
  transformType: TransformType;
  medias?: Media[];
  transformParams: Transform[];
  appliedPipeline: Pipeline['sections'];
  resizeId: string;
  rescaleId: string;
  cropId: string;
  applyPipelineSections: (
    pipelineSection: Pipeline['sections'],
    sectionType: TransformType,
  ) => void;
  onOpenPreview: (step: string) => void;
  showSizeAlert?: boolean;
  hasResizeOrRescale?: boolean;
}

const ConfigList: React.FC<ConfigListProps> = ({
  rowIndex,
  transformType,
  medias = [],
  transformParams,
  appliedPipeline,
  resizeId,
  rescaleId,
  cropId,
  applyPipelineSections,
  onOpenPreview,
  showSizeAlert = false,
  hasResizeOrRescale = true,
}) => {
  const styles = useStyles();
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  const [modelsConfigList] = useAtom(modelsConfigListAtom);
  const { limits, currentSchema } = modelsConfigList[rowIndex].config;

  const setShowLargeImagesWarning = useSetAtom(showLargeImagesWarningAtom);

  const transformsUiSchemaIndex = transformType === TransformType.TRANSFORM ? 1 : 2;
  const [transformsUiSchema, setTransformUiSchema] = useAtom(transformsUiSchemaAtom);
  const uiSchema = useMemo(
    () => transformsUiSchema[transformsUiSchemaIndex],
    [transformsUiSchema, transformsUiSchemaIndex],
  );

  const appliedTransforms = cloneDeep(appliedPipeline.train);

  const transformsList = appliedTransforms.filter(
    transform => transformParams.find(t => t.id === transform.id)?.name,
  );

  const resizeOptions = Object.values(uiSchema).filter(option => option.isResizeOption);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [subAnchorEl, setSubAnchorEl] = useState<HTMLLIElement | null>(null);
  const [transformModalOpen, setTransformModalOpen] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);

  const [pipelineSections, setPipelineSections] = useState<Pipeline['sections']>(appliedPipeline);
  const [prevPipelineSections, setPrevPipelineSections] =
    useState<Pipeline['sections']>(appliedPipeline);

  const [schemaType, setSchemaType] = useState<string>(Object.values(uiSchema)[0].name);
  const [selectedResize, setSelectedResize] = useState<string>(resizeOptions[0]?.name);
  const [localParam, setLocalParam] = useState<number[]>([]);

  const transformNames = useMemo(() => {
    return pipelineSections.train
      .map(transform => transformParams.find(transParam => transParam?.id === transform?.id)?.name!)
      .filter(Boolean);
  }, [pipelineSections, transformParams]);

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubAnchorEl(null);
  };

  const handleModalClose = () => {
    prevPipelineSections && setPipelineSections(prevPipelineSections);
    setTransformModalOpen(false);
  };

  const setInitialTransform = (transform: Transform, isResizeSwitch: boolean) => {
    setPipelineSections(sections => {
      let updatedTrainSections = [...sections.train];
      const rule =
        transformType === TransformType.TRANSFORM
          ? currentSchema?.preprocessing.params[transform.name].properties
          : undefined;
      const newSection = extractNewTransform(transform, rule);
      if (isResizeSwitch && transformType === TransformType.TRANSFORM) {
        updatedTrainSections = getAppliedResizeOptions(resizeOptions, transformParams, sections);
        // resize / rescale should go before the other transforms
        if (newSection) {
          updatedTrainSections.unshift(newSection);
        }
      } else {
        if (newSection) {
          updatedTrainSections.push(newSection);
        }
      }
      return { ...sections, train: updatedTrainSections };
    });
  };

  const handleCreateConfirmed = useCallback(async () => {
    setTransformUiSchema(prev => {
      const _transformsUiSchema = cloneDeep(prev);
      const updatedUiSchema = _transformsUiSchema[transformsUiSchemaIndex];
      updatedUiSchema[schemaType].isAdded = true;
      if (updatedUiSchema[schemaType].isResizeOption) {
        Object.keys(updatedUiSchema).forEach(uiSchemaType => {
          updatedUiSchema[uiSchemaType].isAdded = uiSchemaType === schemaType;
        });
      }
      return _transformsUiSchema;
    });

    setIsExecuting(true);
    setTransformModalOpen(false);

    let sections = pipelineSections;
    // if current section is resize/rescale, and pipelineSections includes crop, remove crop
    if (
      (schemaType === 'Resize' || schemaType === 'RescaleWithPadding') &&
      cropId &&
      pipelineSections.train.find(i => i.id === cropId)
    ) {
      sections = {
        ...pipelineSections,
        train: pipelineSections.train.filter(section => section.id !== cropId),
      };
      setTransformUiSchema(prev => {
        const _transformsUiSchema = cloneDeep(prev);
        const updatedUiSchema = _transformsUiSchema[1];
        updatedUiSchema['Crop'].isAdded = false;
        if (updatedUiSchema['Crop'].isResizeOption) {
          updatedUiSchema.ResizeOptions.isAdded = false;
        }
        return _transformsUiSchema;
      });
    }

    const multipleAdjustedPipelineSections = getMultipleAdjustedPipelineSections(
      sections,
      transformParams,
    );
    setPrevPipelineSections(multipleAdjustedPipelineSections);
    setPipelineSections(multipleAdjustedPipelineSections);
    await applyPipelineSections(multipleAdjustedPipelineSections, transformType);
    setIsExecuting(false);
  }, [
    pipelineSections,
    cropId,
    transformParams,
    applyPipelineSections,
    transformType,
    schemaType,
    setTransformUiSchema,
    transformsUiSchemaIndex,
  ]);

  const updateTransformParam = (
    transformId: TransformId,
    params: TransformParamValue,
    isTransformListUpdate = false,
  ) => {
    setPipelineSections(section => handleSectionUpdate(section, transformId, params));
    isTransformListUpdate &&
      setPrevPipelineSections(section => handleSectionUpdate(section, transformId, params));
  };

  const handleMenuClick = (
    transformName: string,
    isNewEntry = false,
    isResizeOption = false,
    event: React.MouseEvent<HTMLLIElement> | null = null,
    isResizeSwitch = false,
  ) => {
    if (
      transformName === 'Resize' &&
      !isResizeOption &&
      transformType === TransformType.TRANSFORM
    ) {
      event && setSubAnchorEl(event.currentTarget);
      return;
    }
    if (isResizeOption && transformType === TransformType.TRANSFORM) {
      setSelectedResize(transformName);
    }

    setSchemaType(transformName);
    if (isNewEntry) {
      setInitialTransform(transformParams.find(t => t.name === transformName)!, isResizeSwitch);
    }
    setLocalParam([]);
    handleMenuClose();
    setTransformModalOpen(true);
  };

  const removeSectionFromPipeline = (transformId: TransformId, transformName: string) => {
    const sections = {
      ...pipelineSections,
      train: pipelineSections.train.filter(section => section.id !== transformId),
    };
    setTransformUiSchema(prev => {
      const _transformsUiSchema = cloneDeep(prev);
      const updatedUiSchema = _transformsUiSchema[transformsUiSchemaIndex];
      updatedUiSchema[transformName].isAdded = false;
      if (updatedUiSchema[transformName].isResizeOption) {
        updatedUiSchema.ResizeOptions.isAdded = false;
      }
      return _transformsUiSchema;
    });

    setPrevPipelineSections(sections);
    setPipelineSections(sections);
    applyPipelineSections(sections, transformType);
  };

  const titleByTransformType = useMemo(() => {
    return transformType === TransformType.TRANSFORM ? t('Transform') : t('Augmentation');
  }, [transformType]);

  const maxTransformPixels = getMaxTransformPixels(labelType, limits?.largeImage);

  const isShowWarnAlert = useIsShowWarnAlert(pipelineSections, limits);
  useEffect(() => {
    setShowLargeImagesWarning(isShowWarnAlert);
  }, [isShowWarnAlert, setShowLargeImagesWarning]);

  return (
    <>
      {transformsList.map(transform => {
        const transformName = transformParams.find(t => t.id === transform.id)?.name || '';
        const transformLabel = transformParams.find(t => t.id === transform.id)?.label || '';
        const probabilityIndex = transform.params.findIndex(p => p.name === 'p');
        if (probabilityIndex > -1) {
          transform.params.unshift(transform.params.splice(probabilityIndex, 1)[0]);
        }
        return (
          <Box key={transform.id} className={styles.pipelineCell}>
            <Box display="flex" alignItems="center">
              <Tooltip arrow placement="top" title={TRANSFORM_TEXTS[transformName]?.tooltip ?? ''}>
                <Box style={{ width: 130 }}>{t(`${transformLabel}`)}</Box>
              </Tooltip>
              {/* TODO: it seems that AutoResize is no longer used */}
              {transformName === 'AutoResize' && (
                <Box display="flex" alignItems="center" mx={2} className={styles.tooltip}>
                  <Tooltip
                    title={
                      t(`${transformParams.find(t => t.id === transform.id)?.description}`) || ''
                    }
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Box className={styles.cellValueList}>
              {transform.params.map(param => {
                return param.name === 'always_apply' ||
                  param.name === 'interpolation' ||
                  param.name === 'border_mode' ||
                  (!uiSchema[transformName].probability && param.name === 'p') ||
                  transformName === 'AutoResize' ? null : (
                  <Box key={param.name} className={styles.cellValueItem}>
                    <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                      {param.name == 'p' ? 'Probability' : param?.name?.replace(/_/g, ' ')}:{' '}
                    </span>
                    <span>{param.value}</span>
                  </Box>
                );
              })}
              <Box className={styles.cellOps}>
                <EditOutlinedIcon
                  fontSize="small"
                  htmlColor={greyModernScale[500]}
                  onClick={() => handleMenuClick(transformName!, false, true)}
                />
                {removeSectionFromPipeline && ![resizeId, rescaleId].includes(transform.id) && (
                  <DeleteOutlineIcon
                    fontSize="small"
                    htmlColor={greyModernScale[500]}
                    onClick={() => removeSectionFromPipeline(transform.id, transformName!)}
                  />
                )}
              </Box>
            </Box>
          </Box>
        );
      })}

      {transformType == TransformType.TRANSFORM &&
        limits?.largeImage?.thresholdArea &&
        isShowWarnAlert && (
          <Box className={styles.warnAlert} display="flex" alignItems="center" mt={4} mb={4}>
            <ReportProblemIcon className={styles.warnIcon} />
            <Typography className={styles.alertText}>
              {t(
                'Currently we do not support cloud deployment for models trained on images greater than {{thresholdArea}} pixels. You can still continue training  but you can only use edge deployment.',
                {
                  thresholdArea: convertToShortNumberFormat(limits?.largeImage?.thresholdArea),
                },
              )}
            </Typography>
          </Box>
        )}

      {transformType == TransformType.TRANSFORM && showSizeAlert && (
        <Alert className={styles.alerts} severity="error" data-testid={'defaultSizeAlert'}>
          {t(
            'Models need a consistent size for all media. Please add resize, crop or rescale with padding transform. To ensure good performance keep media size below {{num}} megapixels.',
            {
              num: Math.round(maxTransformPixels / 10_000) / 100,
            },
          )}
        </Alert>
      )}

      {transformType == TransformType.TRANSFORM && !hasResizeOrRescale && (
        <Alert className={styles.alerts} severity="error" data-testid="transform-error-alert">
          {t('Resize or Rescale with padding is required for Data transform')}
        </Alert>
      )}

      {!!transformParams.length && (
        <>
          <Box display={'flex'} alignItems="center" style={{ gap: '8px' }}>
            <Tooltip arrow placement="top" title={t(`Add ${titleByTransformType}`)}>
              <Box className={styles.buttonWrapper}>
                <IconButton id="add-pipeline" size="small" onClick={handleAddClick}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Tooltip>

            <Tooltip arrow placement="top" title={t(`Preview ${titleByTransformType} Effect`)}>
              <Box className={styles.buttonWrapper}>
                <IconButton
                  id="preview-pipeline"
                  size="small"
                  onClick={() => onOpenPreview(titleByTransformType)}
                >
                  <PreviewIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>

          <TransformsMenu
            anchorEl={anchorEl}
            subAnchorEl={subAnchorEl}
            transformNames={transformNames}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            uiSchema={uiSchema}
            title={titleByTransformType}
            isTransformStep={transformType === TransformType.TRANSFORM}
          />

          {transformModalOpen && (
            <TransformModal
              transformModalOpen
              handleModalClose={handleModalClose}
              medias={medias}
              transformParams={transformParams}
              uiSchema={uiSchema}
              resizeId={resizeId}
              rescaleId={rescaleId}
              cropId={cropId}
              handleCreateConfirmed={handleCreateConfirmed}
              isExecuting={isExecuting}
              updateTransformParam={updateTransformParam}
              pipelineSections={pipelineSections}
              handleMenuClick={handleMenuClick}
              schemaType={schemaType}
              selectedResize={selectedResize}
              localParam={localParam}
              setLocalParam={setLocalParam}
              isTransformStep={transformType === TransformType.TRANSFORM}
              disableResizeRescaleSwitch
              limits={limits}
              displayAllTransforms={false}
              schema={currentSchema}
            />
          )}
        </>
      )}
    </>
  );
};

export default ConfigList;
