import React from 'react';
import { Route } from 'react-router-dom';

import CLEF_PATH from '../../../constants/path';
import { transformLegacyPathWithOrgId } from '../utils';
import { PageLayout } from '../PageLayout';

import DevTools from '../../../pages/devTools/DevToolsPage';
import Member from '../../../pages/members/MemberView';
import Projects from '../../../pages/projects';
import Home from '../../../pages/home';
import Examples from '../../../pages/examples';
import { PageName } from '@clef/shared/constants';
import OrganizationSettingsPage from '../../../pages/OrgnizationSettings';
import PlanPage from '../../../pages/plan/PlanPage';
import CancelPlanPage from '@/pages/cancel_plan/CancelPlanPage';
import PlanPageV2 from '@/pages/plan/PlanPageV2';

const organizationLevelRoutes = (currentPath: string) => {
  return [
    <Route
      exact
      key={CLEF_PATH.home}
      path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.home)}
      render={() => (
        <PageLayout
          metaTitle={t('Home')}
          hideSidebar
          pageName={PageName.HomePage}
          fullScreenContent
          showWhiteBackground
          noPadding
        >
          <Home />
        </PageLayout>
      )}
    />,
    <Route
      exact
      key={CLEF_PATH.examples}
      path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.examples)}
      render={() => (
        <PageLayout
          metaTitle={t('Examples')}
          hideSidebar
          showGreyBackground
          pageName={PageName.ExamplesPage}
        >
          <Examples />
        </PageLayout>
      )}
    />,
    // Projects page
    <Route
      exact
      key={CLEF_PATH.projects}
      path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.projects)}
      render={() => (
        <PageLayout
          title={t('Projects')}
          hideSidebar
          pageName={PageName.ProjectsPage}
          showWhiteBackground
        >
          <Projects />
        </PageLayout>
      )}
    />,
    ...(process.env.IS_SNOWFLAKE === 'true'
      ? []
      : [
          // Members page
          <Route
            exact
            key={CLEF_PATH.members}
            path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.members)}
            render={() => (
              <PageLayout title={t('Members')} hideSidebar pageName={PageName.MembersPage}>
                <Member />
              </PageLayout>
            )}
          />,
          // Api dev tools page
          <Route
            exact
            key={CLEF_PATH.api.auth}
            path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.api.auth)}
            render={() => (
              <PageLayout hideSidebar title={t('API keys')} pageName={PageName.ApiKeyPage}>
                <DevTools />
              </PageLayout>
            )}
          />,
          // Plan page
          <Route
            exact
            key={CLEF_PATH.plan}
            path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.plan)}
            render={() => (
              <PageLayout hideSidebar pageName={PageName.PlanPage} showWhiteBackground hideHeader>
                <PlanPage />
              </PageLayout>
            )}
          />,
          // Plan page
          <Route
            exact
            key={CLEF_PATH.planV2}
            path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.planV2)}
            render={() => (
              <PageLayout hideSidebar pageName={PageName.PlanPage} showWhiteBackground hideHeader>
                <PlanPageV2 />
              </PageLayout>
            )}
          />,
          // Cancel Plan page
          <Route
            exact
            key={CLEF_PATH.cancelPlan}
            path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.cancelPlan)}
            render={() => {
              return (
                <PageLayout hideSidebar pageName={PageName.CancelPlanPage} showWhiteBackground>
                  <CancelPlanPage />
                </PageLayout>
              );
            }}
          />,
          <Route
            exact
            key={CLEF_PATH.organizationSettings}
            path={transformLegacyPathWithOrgId(currentPath, CLEF_PATH.organizationSettings)}
            render={() => (
              <PageLayout
                hideSidebar
                metaTitle={t('Organization Management')}
                pageName={PageName.OrganizationSettingsPage}
                showWhiteBackground
              >
                <OrganizationSettingsPage />
              </PageLayout>
            )}
          />,
        ]),
  ];
};

export default organizationLevelRoutes;
