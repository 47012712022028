import React from 'react';
import {
  Tooltip,
  Box,
  Typography,
  makeStyles,
  PopperPlacementType,
  Theme,
} from '@material-ui/core';

import { useLocalStorage } from '@clef/client-library';
import { PulsingDot } from '@/components/OnboardingHighlightBadge';

interface TooltipStyle {
  width?: number;
  position?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
}

const useStyles = makeStyles<Theme, TooltipStyle>(theme => ({
  popper: {
    pointerEvents: 'auto',
    width: props => (props.width ? props.width : 'auto'),
  },
  arrow: {
    color: theme.palette.blue[700],
  },
  tooltip: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  pulsingDot: props =>
    props.position
      ? {
          position: 'absolute',
          ...props.position,
        }
      : { position: 'absolute' },
}));

interface OnboardingTipsProps {
  storageKey: string;
  title: string;
  placement: PopperPlacementType;
  showPulsingDot?: boolean;
  tooltipStyles?: TooltipStyle;
  onlyShowWhenHover?: boolean;
}

export const OnboardingTips: React.FC<OnboardingTipsProps> = ({
  storageKey,
  title,
  placement,
  showPulsingDot,
  tooltipStyles = {},
  onlyShowWhenHover = false,
}) => {
  const styles = useStyles(tooltipStyles);
  const [shownOnboardingTips, setShownOnboardingTips] = useLocalStorage(storageKey);
  if (shownOnboardingTips) return null;

  return (
    <Tooltip
      open={onlyShowWhenHover ? undefined : true}
      interactive={onlyShowWhenHover ? true : undefined}
      arrow
      placement={placement}
      classes={{
        popper: styles.popper,
        arrow: styles.arrow,
        tooltip: styles.tooltip,
      }}
      title={
        <Box display="flex" alignItems="flex-end" flexDirection="column">
          <Typography>{title}</Typography>
          <Box onClick={() => setShownOnboardingTips(true)} className={styles.gotIt} marginTop={3}>
            {t('Got it')}
          </Box>
        </Box>
      }
    >
      <Box className={styles.pulsingDot}>{showPulsingDot && <PulsingDot />}</Box>
    </Tooltip>
  );
};
