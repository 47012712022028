import React from 'react';
import { Tooltip, Box, Typography } from '@material-ui/core';

import { useLocalStorage } from '@clef/client-library';
import { PulsingDot } from '@/components/OnboardingHighlightBadge';

import useStyles from './styles';

export interface SnapshotTabsTipsProps {}

export const SnapshotTabsTips: React.FC<SnapshotTabsTipsProps> = () => {
  const styles = useStyles();

  const [shownSnapshotTabsTips, setSnapshotTabsTips] = useLocalStorage('shownSnapshotTabsTips');

  return (
    <>
      {shownSnapshotTabsTips ? (
        <></>
      ) : (
        <Tooltip
          arrow
          interactive
          placement="bottom"
          classes={{
            popper: styles.popper,
            arrow: styles.arrow,
            tooltip: styles.tooltip,
          }}
          title={
            <Box display="flex" alignItems="flex-end" flexDirection="column">
              <Typography>
                {t('{{action}} View snapshot data visually or a quick summary here.', {
                  action: <strong>{'Understand Snapshots.'}</strong>,
                })}
              </Typography>
              <Box onClick={() => setSnapshotTabsTips(true)} className={styles.gotIt} marginTop={3}>
                {t('Got it')}
              </Box>
            </Box>
          }
        >
          <Box className={styles.SnapshotTabsTipsPos}>
            <PulsingDot />
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default SnapshotTabsTips;
