import React from 'react';
import { Tooltip, Box, Typography, makeStyles } from '@material-ui/core';

import { LabelType } from '@clef/shared/types';
import { useLocalStorage } from '@clef/client-library';

import { useFirstRunExperienceWorkflowAssistantEnabled } from '@/hooks/useFeatureGate';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';

const useStyles = makeStyles(theme => ({
  popper: {
    pointerEvents: 'auto',
  },
  arrow: {
    color: theme.palette.blue[700],
  },
  tooltip: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  tipPlaceHolder: {
    position: 'absolute',
    left: 115,
    top: 90,
  },
}));

const AfterFirtstModelTip: React.FC = () => {
  const styles = useStyles();

  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { labelType } = selectedProject ?? {};

  const firstRunExperienceWorkflowAssistantEnabled =
    useFirstRunExperienceWorkflowAssistantEnabled();
  const { data: models } = useGetProjectModelListQuery(firstRunExperienceWorkflowAssistantEnabled);

  const [skipMediaDetailsPageIntroDialog] = useLocalStorage('skipMediaDetailsPageIntroDialog');
  const [skipAfterFirtstModelTip, setSkipAfterFirtstModelTip] =
    useLocalStorage('skipAfterFirtstModelTip');

  if (
    labelType === LabelType.Classification || // classification has no labeling tools
    skipAfterFirtstModelTip ||
    !skipMediaDetailsPageIntroDialog
  ) {
    return null;
  }

  return (
    <>
      {models?.length === 1 && !skipAfterFirtstModelTip && (
        <Tooltip
          open
          arrow
          placement="bottom-end"
          classes={{
            popper: styles.popper,
            arrow: styles.arrow,
            tooltip: styles.tooltip,
          }}
          title={
            <Box display="flex" alignItems="flex-end" flexDirection="column">
              <Typography>
                {t('You can continue labeling this image by choosing one of the labeling tools.')}
              </Typography>
              <Box
                onClick={() => {
                  setSkipAfterFirtstModelTip(true);
                }}
                className={styles.gotIt}
                marginTop={3}
              >
                {t('Got it')}
              </Box>
            </Box>
          }
        >
          <Box className={styles.tipPlaceHolder} />
        </Tooltip>
      )}
    </>
  );
};

export default AfterFirtstModelTip;
