import React, {
  MutableRefObject,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Box, Slider, TextField, Tooltip } from '@material-ui/core';
import { useAtom, useSetAtom } from 'jotai';
import cx from 'classnames';
import { isNumber, isEmpty } from 'lodash';

import {
  MediaInteractiveCanvas,
  Button,
  ToggleButton,
  Typography,
  useKeyPress,
  AnnotationChangeType,
  AnnotationSourceType,
  useLocalStorage,
  ApiResponseLoader,
} from '@clef/client-library';
import { RegisteredModel, AnnotationType, MediaLevelLabel } from '@clef/shared/types';
import { CONFIDENCE_THRESHOLD_OPTIONS } from '@clef/shared/constants';

import Check from '@/images/media_details/label_suggestion/check.svg';
import Cross from '@/images/media_details/label_suggestion/cross.svg';
import Resize from '@/images/media_details/label_suggestion/resize1.svg';
import Threshold from '@/images/media_details/label_suggestion/threshold.svg';
import LabelAssistImg from '@/images/media_details/labelAssist.gif';
import { convertToCanvasAnnotations } from '@/components/Labeling/utils';
import { useLabelingState, BoxLabelingAnnotation } from '@/components/Labeling/labelingState';
import useGetDefectColorById from '@/hooks/defect/useGetDefectColorById';
import {
  toggleOnModelAssistLabelingAtom,
  isLabelModeAtom,
  useCurrentMediaStates,
  useSaveAnnotations,
  mediaStatesMapAtom,
} from '@/uiStates/mediaDetails/pageUIStates';
import { showGroundTruthAtom } from '@/uiStates/mediaDetails/labelToggles';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useFetchModelAssistSuggestionsQuery } from '@/serverStore/label';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { useModelInferenceCost } from '@/hooks/api/useExperimentReportApi';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { pendoEntity } from '@/utils/pendo';
import LabelPreviewList from '@/components/Labeling/LabelPreviewList';

import useStyles from './styles';

export const labelAssistOnboardingTips = 'skipLabelAssistOnboardingTips';

type ModelAssistLabelingProps = {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  modelInfo: RegisteredModel;
};

export type ModelAssistLabelingRef = {
  turnOnAssistMode: (hasPrediction?: boolean) => void;
};

export const ModelAssistLabeling = forwardRef<ModelAssistLabelingRef, ModelAssistLabelingProps>(
  ({ mediaCanvasRef, modelInfo }, ref) => {
    const styles = useStyles();
    const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
    const currentUser = useTypedSelector(state => state.login.user);
    const {
      state: { labelingType },
    } = useLabelingState();
    const getDefectColorById = useGetDefectColorById();

    const currentMediaStates = useCurrentMediaStates();
    const saveAnnotation = useSaveAnnotations();

    const shouldUseAdvancedPricing = useFeatureGateEnabled(
      ClientFeatures.AdvancedUsageBasedPricing,
    );
    const unpredicted =
      currentMediaStates.mediaLevelLabel !== MediaLevelLabel.OK &&
      !currentMediaStates.predictionAnnotations;
    const [inferenceCost, inferenceCostLoading] = useModelInferenceCost(
      shouldUseAdvancedPricing && projectId
        ? {
            projectId,
            jobId: modelInfo.id,
          }
        : undefined,
    );
    const [shouldGetSuggestionFirst, setShouldGetSuggestionFirst] = useState(unpredicted);

    const [toggleOnModelAssistLabeling, setToggleOnModelAssistLabeling] = useAtom(
      toggleOnModelAssistLabelingAtom,
    );
    const [isLabelMode, setIsLabelMode] = useAtom(isLabelModeAtom);
    const setShowGroundTruth = useSetAtom(showGroundTruthAtom);
    const [mediaStatesMap] = useAtom(mediaStatesMapAtom);

    const [threshold, setThreshold] = useState(modelInfo.confidence);

    const { data: modelAssistSuggestions, isLoading } = useFetchModelAssistSuggestionsQuery(
      currentMediaStates?.mediaDetails?.id,
      modelInfo.id,
      toggleOnModelAssistLabeling && !shouldGetSuggestionFirst,
    );

    const [showLabelAssistTips, setShowLabelAssistTips] = useState(false);

    const [skipLabelAssistOnboardingTips, setSkipLabelAssistOnboardingTips] =
      useLocalStorage(labelAssistOnboardingTips);

    const currentSuggestions = useMemo(
      () =>
        modelAssistSuggestions
          ?.map(suggestion => ({
            id: suggestion.id.toString(),
            defectId: suggestion.defectId,
            confidence: suggestion.confidence,
            minThreshold: suggestion.minThreshold,
            maxThreshold: suggestion.maxThreshold,
            data: suggestion.rangeBox,
            annotationType: AnnotationType.bndbox,
          }))
          .filter(annotation => {
            if (currentMediaStates.annotations.findIndex(a => a.id === annotation.id) !== -1) {
              return false;
            }
            return true;
          })
          .filter(
            annotation =>
              isNumber(threshold) &&
              threshold <= annotation.maxThreshold &&
              threshold >= annotation.minThreshold,
          ) || [],
      [currentMediaStates.annotations, modelAssistSuggestions, threshold],
    );

    const displaySuggestions = useCallback(() => {
      const gt = currentMediaStates.annotations as BoxLabelingAnnotation[];
      mediaCanvasRef.current?.setAnnotations(
        [
          ...convertToCanvasAnnotations(labelingType, gt, getDefectColorById),
          ...convertToCanvasAnnotations(
            labelingType,
            currentSuggestions,
            getDefectColorById,
            AnnotationSourceType.Suggestion,
          ),
        ],
        AnnotationChangeType.Reset,
        true,
      );
    }, [
      currentSuggestions,
      currentMediaStates.annotations,
      mediaCanvasRef,
      labelingType,
      getDefectColorById,
    ]);

    const turnOnAssistMode = (hasPrediction?: boolean) => {
      if (!isNumber(threshold)) return;
      if (hasPrediction) {
        setShouldGetSuggestionFirst(false);
      }
      setToggleOnModelAssistLabeling(true);
      if (!isLabelMode) {
        setShowGroundTruth(true);
        setIsLabelMode(true);
      }
      pendoEntity?.track('user_enter_label_assist_mode', {
        userName: currentUser ? `${currentUser.name} ${currentUser.lastName}` : undefined,
        orgName: currentUser ? currentUser.company : undefined,
        orgId: currentUser ? currentUser.orgId : undefined,
        projectId,
      });
    };

    useImperativeHandle(ref, () => ({
      turnOnAssistMode,
    }));

    // display suggestions when toggle on assist mode and modelAssistSuggestions is fetched or threshold is changed
    useEffect(() => {
      if (toggleOnModelAssistLabeling && modelAssistSuggestions && threshold) {
        displaySuggestions();
      }
      // don't add displaySuggestions to the dependency list, it will trigger the useEffect
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      modelAssistSuggestions,
      toggleOnModelAssistLabeling,
      threshold,
      currentMediaStates.annotations, // for calling displaySuggestions when delete a accepted suggestion (ground truth)
    ]);

    const acceptAllSuggestions = () => {
      mediaCanvasRef.current?.setAnnotations(prev => {
        const newAnnotations = prev.map(ann => ({
          ...ann,
          data: { ...ann.data, dashed: false },
          selected: false,
          group:
            ann.group === AnnotationSourceType.Suggestion
              ? AnnotationSourceType.GroundTruth
              : ann.group,
        }));
        return newAnnotations;
      }, AnnotationChangeType.Edit);
    };

    const exitAssistMode = () => {
      mediaCanvasRef.current?.setAnnotations(
        prev =>
          prev
            .filter(annotation => annotation.group === AnnotationSourceType.GroundTruth)
            .map(ann => ({
              ...ann,
              selected: false,
            })),
        AnnotationChangeType.Reset,
      );
      if (!isEmpty(mediaStatesMap)) {
        saveAnnotation();
      }
      setToggleOnModelAssistLabeling(false);
    };
    useKeyPress('esc', toggleOnModelAssistLabeling ? exitAssistMode : undefined);

    const { thresholdsMin, thresholdsMax, thresholdsStep } = CONFIDENCE_THRESHOLD_OPTIONS;

    return (
      <>
        {isLabelMode && (
          <Box className={styles.section} paddingBottom={9}>
            <Box className={styles.sectionTitleWithToggle}>
              <Box display="flex" alignItems="center">
                {!skipLabelAssistOnboardingTips && (
                  <Tooltip
                    open
                    arrow
                    placement="left-end"
                    classes={{
                      arrow: styles.arrow,
                      tooltip: styles.tooltip,
                    }}
                    title={
                      <Box className={styles.onboardingTipsContent}>
                        <img className={styles.tipsGif} src={LabelAssistImg} alt="" />
                        <Typography variant="h4_semibold">
                          {t('Introducing Label Assist')}
                        </Typography>
                        <Typography>
                          {t('Accept model predictions as ground truth labels.')}
                        </Typography>
                        <Box display="flex" justifyContent="flex-end" width="100%">
                          <Button
                            id="learn-more-about-LabelAssistOnboardingTips-inner"
                            variant="text"
                            color="primary"
                            role="link"
                            target="_blank"
                            href="https://support.landing.ai/docs/label-assist"
                          >
                            {t('Learn More')}
                          </Button>
                          <Box mr={2} />
                          <Button
                            id="skipLabelAssistOnboardingTips"
                            variant="contained"
                            color="primary"
                            onClick={() => setSkipLabelAssistOnboardingTips(true)}
                          >
                            {t('Got It')}
                          </Button>
                        </Box>
                      </Box>
                    }
                  >
                    <Box />
                  </Tooltip>
                )}
                <Tooltip
                  arrow
                  placement="top"
                  title={t(
                    'By enabling label assist, you can accept model predictions as ground truth labels.',
                  )}
                >
                  <Typography variant="body_bold" className={styles.sectionTitleText}>
                    {t('Label Assist')}
                  </Typography>
                </Tooltip>
              </Box>
              <Box>
                <Button
                  id="show-suggestion-tips"
                  variant="text"
                  className={styles.modelAssistBlueBtn}
                  onClick={() => {
                    if (!showLabelAssistTips) {
                      setShowLabelAssistTips(true);
                    }
                  }}
                >
                  {t('Tips')}
                </Button>
                <ToggleButton
                  id="toggle-model-assist-labeling"
                  isOn={toggleOnModelAssistLabeling}
                  onToggle={toggleOnModelAssistLabeling ? exitAssistMode : turnOnAssistMode}
                />
              </Box>
            </Box>

            {toggleOnModelAssistLabeling && unpredicted && !modelAssistSuggestions && (
              <>
                <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body_medium">{modelInfo.modelName}</Typography>
                  <Typography variant="body_medium">{t('Unpredicted')}</Typography>
                </Box>
                <Button
                  id="model-assist-labeling-btn"
                  onClick={() => setShouldGetSuggestionFirst(false)}
                  variant="outlined"
                  color="primary"
                  className={styles.modelAssistDefaultBtn}
                >
                  <Box mr={1} />
                  {t('Get Suggestion')}
                  {shouldUseAdvancedPricing && !inferenceCostLoading && (
                    <Typography className={styles.modelAssistBlockBtnSub}>
                      {t(`({{credit}} credit)`, {
                        credit: inferenceCost?.data.inferenceCredits,
                      })}
                    </Typography>
                  )}
                </Button>
              </>
            )}

            {toggleOnModelAssistLabeling && !shouldGetSuggestionFirst && (
              <ApiResponseLoader
                response={modelAssistSuggestions}
                loading={isLoading}
                defaultHeight={100}
              >
                {_ => (
                  <>
                    <Box mb={4}>
                      <Typography variant="body_medium">{modelInfo.modelName}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box className={styles.thresholdWrapper}>
                        <Typography className={styles.modelAssistBlockSubTitle}>
                          {t('Confidence threshold')}
                        </Typography>
                        <Box className={styles.thresholdSetting}>
                          <Slider
                            min={thresholdsMin}
                            max={thresholdsMax}
                            step={thresholdsStep}
                            value={threshold!}
                            onChange={(_, newThreshold) => {
                              setThreshold(newThreshold as number);
                            }}
                            classes={{
                              root: styles.sliderRoot,
                              rail: styles.sliderRail,
                              track: styles.sliderTrack,
                              thumb: styles.sliderThumb,
                              active: styles.sliderActive,
                              mark: styles.sliderMark,
                              markActive: styles.sliderMark,
                              markLabel: styles.sliderMarkLabel,
                            }}
                          />
                          <TextField
                            variant="outlined"
                            type="number"
                            value={threshold}
                            InputProps={{
                              classes: {
                                root: styles.thresholdInputRoot,
                                input: styles.thresholdInput,
                              },
                            }}
                            inputProps={{
                              className: styles.thresholdInput,
                              step: 0.01,
                              min: 0,
                              max: 0.99,
                              'data-testid': 'threshold-input',
                            }}
                            onChange={e => {
                              const value = Math.max(Number(e.target.value || 0), 0);
                              setThreshold(value);
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography className={styles.modelAssistBlockSubTitle}>
                        {t(`{{suggestionCount}} Suggestions`, {
                          suggestionCount: currentSuggestions.length,
                        })}
                      </Typography>
                      {currentSuggestions.length > 0 ? (
                        <Button
                          id="model-assist-accept-btn"
                          variant="contained"
                          color="primary"
                          className={cx(styles.modelAssistBlockBtn, styles.acceptAllBtn)}
                          onClick={acceptAllSuggestions}
                        >
                          {t('Accept All')}
                        </Button>
                      ) : (
                        <Box />
                      )}
                    </Box>
                    {currentSuggestions.length > 0 && (
                      <LabelPreviewList
                        mediaCanvasRef={mediaCanvasRef}
                        annotations={
                          currentSuggestions.sort((a, b) =>
                            a.id.localeCompare(b.id),
                          ) as BoxLabelingAnnotation[]
                        }
                        labelingType={labelingType}
                        mediaDimensions={currentMediaStates.mediaDetails?.properties || undefined}
                        isLabelMode={isLabelMode}
                        annotationSourceType={AnnotationSourceType.Suggestion}
                        mediaLevelLabel={currentMediaStates.mediaLevelLabel || undefined}
                      />
                    )}
                  </>
                )}
              </ApiResponseLoader>
            )}

            {showLabelAssistTips && (
              <Box className={styles.tipsBlock}>
                <Box className={styles.tipsTitleWrapper}>
                  <Typography variant="h4_semibold">{t('Label Assist Tips')}</Typography>
                  <Button
                    id="hide-suggestion-tips"
                    variant="text"
                    className={styles.modelAssistBlueBtn}
                    onClick={() => {
                      setShowLabelAssistTips(false);
                    }}
                  >
                    {t('Hide')}
                  </Button>
                </Box>
                <Typography variant="body1">
                  {t(
                    'If an object is predicted but not labeled already, we will show it as a suggestion.',
                  )}
                </Typography>
                <Box className={styles.tipsContent}>
                  <Box className={styles.tipsItem}>
                    <img src={Check} />
                    <Typography>
                      {t(
                        `Click a suggestion to accept it. Or click {{acceptAll}} to accept all suggestions.`,
                        {
                          acceptAll: (
                            <Box className={styles.tipsItemTextBold}>{t('Accept All')}</Box>
                          ),
                        },
                      )}
                    </Typography>
                  </Box>
                  <Box className={styles.tipsItem}>
                    <img src={Cross} />
                    <Typography>{t('Ignore incorrect suggestions.')}</Typography>
                  </Box>
                  <Box className={styles.tipsItem}>
                    <img src={Resize} />
                    <Typography>
                      {t(
                        'Adjust the confidence threshold to show more or fewer suggestions. This won’t impact the model’s actual threshold.',
                      )}
                    </Typography>
                  </Box>
                  <Box className={styles.tipsItem}>
                    <img src={Threshold} />
                    <Typography>
                      {t(
                        'Adjust the sizes and positions of accepted labels to make them more accurate.',
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  },
);

export default ModelAssistLabeling;
