import React from 'react';
import { Tooltip, Box, Typography, makeStyles } from '@material-ui/core';
import { useAtom } from 'jotai';

import { useLocalStorage } from '@clef/client-library';
import { LabelType } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { PulsingDot } from '@/components/OnboardingHighlightBadge';
import { isLabelModeAtom } from '@/uiStates/mediaDetails/pageUIStates';

const useStyles = makeStyles(theme => ({
  popper: {
    pointerEvents: 'auto',
  },
  arrow: {
    color: theme.palette.blue[700],
  },
  tooltip: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  markAsNoClass: {
    position: 'absolute',
    left: 358,
    top: 144,
    zIndex: 1,
  },
  drawerToggle: {
    position: 'absolute',
    top: 80,
    right: 62,
    zIndex: 2000,
  },
  labelsToggle: {
    position: 'absolute',
    top: 62,
    right: 350,
    zIndex: 1,
  },
}));

export interface OnboardingTipsProps {}

export const OnboardingTips: React.FC<OnboardingTipsProps> = () => {
  const styles = useStyles();

  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { labelType } = selectedProject ?? {};

  const [skipMediaDetailsPageIntroDialog] = useLocalStorage('skipMediaDetailsPageIntroDialog');
  const [shownMediaDetailsPanelsTips, setshownMediaDetailsPanelsTips] = useLocalStorage(
    'shownMediaDetailsPanelsTips',
  );
  const [shownMediaDetailsTogglesTips, setshownMediaDetailsTogglesTips] = useLocalStorage(
    'shownMediaDetailsTogglesTips',
  );
  const [shownMediaDetailsNoClassTips, setshownMediaDetailsNoClassTips] = useLocalStorage(
    'shownMediaDetailsNoClassTips',
  );

  const [isLabelMode] = useAtom(isLabelModeAtom);

  if (
    !skipMediaDetailsPageIntroDialog ||
    (shownMediaDetailsPanelsTips && shownMediaDetailsTogglesTips && shownMediaDetailsNoClassTips)
  )
    return null;

  return (
    <>
      {!shownMediaDetailsPanelsTips && (
        <Tooltip
          open
          arrow
          classes={{
            popper: styles.popper,
            arrow: styles.arrow,
            tooltip: styles.tooltip,
          }}
          title={
            <Box display="flex" alignItems="flex-end" flexDirection="column">
              <Typography>{t('Click this icon to view the image details.')}</Typography>
              <Box
                onClick={() => {
                  setshownMediaDetailsPanelsTips(true);
                }}
                className={styles.gotIt}
                marginTop={3}
              >
                {t('Got it')}
              </Box>
            </Box>
          }
        >
          <Box className={styles.drawerToggle}>
            <PulsingDot />
          </Box>
        </Tooltip>
      )}

      {shownMediaDetailsPanelsTips &&
        !shownMediaDetailsTogglesTips &&
        labelType !== LabelType.Classification &&
        !isLabelMode && (
          <Tooltip
            open
            arrow
            classes={{
              popper: styles.popper,
              arrow: styles.arrow,
              tooltip: styles.tooltip,
            }}
            title={
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Typography>
                  {t('You can toggle the Labels and Predictions on and off.')}
                </Typography>
                <Box
                  onClick={() => {
                    setshownMediaDetailsTogglesTips(true);
                  }}
                  className={styles.gotIt}
                  marginTop={3}
                >
                  {t('Got it')}
                </Box>
              </Box>
            }
          >
            <Box className={styles.labelsToggle}>
              <PulsingDot />
            </Box>
          </Tooltip>
        )}
      {shownMediaDetailsPanelsTips &&
        !shownMediaDetailsNoClassTips &&
        labelType !== LabelType.Classification &&
        isLabelMode && (
          <Tooltip
            open
            arrow
            placement="bottom-start"
            classes={{
              popper: styles.popper,
              arrow: styles.arrow,
              tooltip: styles.tooltip,
            }}
            title={
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Typography>
                  {t(
                    'If there are no objects of interest to label in this image, mark it as No Class.',
                  )}
                </Typography>
                <Box
                  onClick={() => {
                    setshownMediaDetailsNoClassTips(true);
                  }}
                  className={styles.gotIt}
                  marginTop={3}
                >
                  {t('Got it')}
                </Box>
              </Box>
            }
          >
            <Box className={styles.markAsNoClass}>
              <PulsingDot />
            </Box>
          </Tooltip>
        )}
    </>
  );
};

export default OnboardingTips;
