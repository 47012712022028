import React, { useRef, useMemo, MutableRefObject } from 'react';
import { useParams } from 'react-router';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { throttle } from 'lodash';
import { useAtom } from 'jotai';

import { Media } from '@clef/shared/types';
import { ApiResponseLoader, useElementEventListener, VirtualGrid } from '@clef/client-library';

import {
  useGetSelectedProjectQuery,
  useGetProjectVersionedDefectsQuery,
} from '@/serverStore/projects';
import { useDatasetMediaListQuery } from '@/serverStore/dataset';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { ApiErrorType } from '@/api/base_api';
import {
  paginationLimitAtom,
  showGroundTruthAtom,
  itemPerRowOffsetAtom,
  sortFieldAtom,
} from '@/uiStates/datasetSnapshot/pageUIStates';
import { appliedFiltersAtom } from '@/uiStates/mediaFilter';
import { calcOptimalRatio } from '@/pages/DataBrowser/MediaGrid/MediaGrid';
import MediaContainer from '@/pages/DataBrowser/MediaContainer';
import {
  appliedFilterMappingToFormattedFilterMapping,
  getColumnFilterMapWithModelId,
} from '@/pages/DataBrowser/dataBrowserState';
import AppliedFilters from '@/components/MediaFilter/AppliedFilters';

import useStyles from './styles';

const atBottom = (el: HTMLElement): boolean => {
  const sh = el.scrollHeight,
    st = Math.ceil(el.scrollTop),
    ht = el.offsetHeight;
  if (ht == 0) return true;
  return st + ht >= sh - 20; // 20 pixels for buffer
};

const SnapshotMedias: React.FC<{}> = () => {
  const styles = useStyles();

  const { datasetId, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { version } = useParams<{ version: string }>();

  const containerRef = useRef<HTMLDivElement | null>(null);

  // fetch versioned defects
  const {
    data: versionedDefects,
    isLoading: versionedDefectsLoading,
    error: versionedDefectsError,
  } = useGetProjectVersionedDefectsQuery(parseInt(version));

  const [paginationLimit] = useAtom(paginationLimitAtom);
  const [showGroundTruth] = useAtom(showGroundTruthAtom);
  const [itemPerRowOffset] = useAtom(itemPerRowOffsetAtom);
  const [sortField] = useAtom(sortFieldAtom);
  const [appliedFilters] = useAtom(appliedFiltersAtom);

  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const [columnFilterMap, metadataFilterMap] = useMemo(() => {
    const [col, metadata] = appliedFilterMappingToFormattedFilterMapping(
      appliedFilters[parseInt(version)] ?? {},
    );
    const colWithModelId = getColumnFilterMapWithModelId(col, currentModelId);
    return [colWithModelId, metadata];
  }, [appliedFilters, currentModelId, version]);
  const newMetadataFilterMap = Object.fromEntries(
    Object.entries(metadataFilterMap).filter(([, value]) => {
      return Object.values(value).some(subValue => {
        return Array.isArray(subValue) ? subValue.length > 0 : !!subValue;
      });
    }),
  );

  const { data, error, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useDatasetMediaListQuery({
      datasetId,
      projectId,
      sortOptions: sortField,
      columnFilterMap,
      metadataFilterMap: newMetadataFilterMap,
      includeMediaStatus: true,
      version: parseInt(version, 10),
      paginationLimit,
    });

  // Flatten the pages into a single array for easy access
  const allMedias = useMemo(() => {
    return data?.pages.flat() || [];
  }, [data?.pages]);

  const containerRefElement = containerRef
    ? (containerRef as MutableRefObject<HTMLDivElement>).current
    : undefined;

  useElementEventListener(
    containerRefElement ? containerRefElement : undefined,
    'scroll',
    !hasNextPage || !containerRefElement
      ? () => {}
      : // throttle scroll event so it doesn't trigger too often
        throttle(
          () => {
            if (
              !!containerRefElement &&
              atBottom(containerRefElement) &&
              hasNextPage &&
              !isLoading
            ) {
              fetchNextPage();
            }
          },
          200,
          { leading: false },
        ),
  );

  return (
    <>
      <AppliedFilters filterMappingKey={parseInt(version)} className={styles.appliedFiltersCls} />
      <Box className={styles.mediaList}>
        {/* media grid */}
        {/* this progress is for loading more data */}
        {isFetching && allMedias.length > 0 && (
          <LinearProgress className={styles.loadingImagesProgress} />
        )}
        <div className={styles.mediaGridWrapper} ref={containerRef}>
          <ApiResponseLoader
            response={allMedias.length > 0 ? allMedias : undefined}
            loading={isLoading || versionedDefectsLoading}
            error={(error as ApiErrorType) || versionedDefectsError || undefined}
            defaultHeight={500}
          >
            {allMedias => {
              return allMedias.length && versionedDefects ? (
                <VirtualGrid
                  // Media size is interpreted to number of media per row, the larger the mediaSize, the smaller
                  // the number of media we place in a row.
                  containerRef={containerRef}
                  itemPerRowCap={8}
                  itemPerRowOffset={itemPerRowOffset}
                  imageRatio={calcOptimalRatio(allMedias as Media[])}
                  componentList={allMedias as Media[]}
                  className={styles.virtualGrid}
                  visibleOverflow
                  bufferRowLength={10}
                  style={{ margin: 0 }}
                >
                  {(media, index, itemPerRow) => (
                    <MediaContainer
                      key={media.id + index + version}
                      media={media}
                      versionId={parseInt(version)}
                      showGroundTruth={showGroundTruth}
                      showPredictions={false} // don't show predictions in snapshot
                      showHeatmap={false} // don't show heatmap in snapshot
                      thumbnailSize={itemPerRow > 1 ? 'medium' : 'large'}
                      disableSelection
                      disableSetClass
                    />
                  )}
                </VirtualGrid>
              ) : (
                <Typography
                  variant="subtitle1"
                  component="div"
                  gutterBottom
                  className={styles.hintTextNormal}
                  data-test-id="no-medias-found-data-browser"
                >
                  {t('No image found')}
                </Typography>
              );
            }}
          </ApiResponseLoader>
        </div>
      </Box>
    </>
  );
};

export default SnapshotMedias;
