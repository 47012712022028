import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { Badge, Box, Grid, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { SIDEBAR_ICON_SIZE } from '@clef/shared/constants';
import { useHistory } from 'react-router';
import { SIDE_BAR_MODELS_V2_ID } from './structure';
import {
  projectDisplayCheckMarkWhenCompleteAtom,
  setProjectDisplayCheckMarkWhenCompleteAtom,
} from '@/uiStates/projectTrainingInProgress/pageUIStates';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useAtom, useSetAtom } from 'jotai';
import TrainingCompletedCheckMark from './TrainingCompletedCheckMark';
import TrainingInProgressIndicator from './TrainingInProgressIndicator';
import { OnboardingTips } from '@/components/OnboardingTips';

/**
 * Having the following implementation (from line 52 - 94) is to highlight the sidebar item correctly
 * under new unified flow and support backward compatability.
 *
 * After we fully release the new unified flow, a better way is to directly modify the original routing
 * url for each page, e.g. changing "/app/jobs" to "/app/models/jobs" for jobs page and reverting the
 * folling changes, which should work as well
 */
const appProjectUrlPattern = /.*\/app\/\d+\/pr\/\d+\//;
const urlRewriteLookup: Record<string, string> = {
  'data/defects': 'data/databrowser',
  'data/metadata': 'data/databrowser',
  'data/exported_data': 'data/databrowser',
  'data/custom_training': 'data/databrowser',
  jobs: 'models',
  'models/run': 'models',
};
const modelAnalysisPageOnboardingTipsKey = 'modelAnalysisPageOnboardingTips';

const getModifiedUrl = (url: string) => {
  const appProjectUrl = url.match(appProjectUrlPattern)?.[0];
  if (!appProjectUrl) {
    return url;
  }

  const extraUrl = url.replace(appProjectUrl, '');
  return urlRewriteLookup[extraUrl] ? appProjectUrl + urlRewriteLookup[extraUrl] : url;
};

export const isLinkActive = (link: string | undefined): boolean => {
  const url = getModifiedUrl(location.pathname);

  return (
    !!link &&
    /**
     * Support both legacy and new URL types and better support for matching sub-levels
     * This will match "/app/models" with:
     *   /app/models
     *   /app/1/pr/123/models
     *   /app/1/pr/123/models/view/456
     * But not with:
     *   /app/1/pr/123/deployment_models
     */
    new RegExp(link.replace('/app/', '/app(/\\d+/pr/\\d+)?/') + '($|/)').test(url)
  );
};

const useStyles = makeStyles(theme => ({
  mainColor: {
    color: theme.palette.greyBlue[500],
    fontWeight: 500,
    lineHeight: '16px',
  },
  disableBackground: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  linkSelected: {
    backgroundColor: `transparent !important`,
    '& $icon': {
      color: theme.palette.blue[900],
    },
    '& $listItemText, & $mainColor': {
      color: theme.palette.blue[900],
      fontWeight: 600,
    },
    '& $iconGridItem': {
      backgroundColor: theme.palette.blue[200],
      borderRadius: 12,
    },
  },
  listRoot: {
    margin: theme.spacing(0, 0, 6, 0),
    width: 'inherit',
    borderRadius: 8,
    cursor: 'pointer',
    color: theme.palette.greyBlue[500],
    padding: '0!important',
    transition: 'background-color 0.2s',
  },
  linkDense: {
    padding: theme.spacing(1.5, 10),
  },
  iconGridItem: {
    height: 32,
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    transition: '0.3s',
  },
  listItemText: {
    margin: 0,
    textAlign: 'center',
    textWrap: 'wrap',
    width: 80,
  },
  icon: {
    width: SIDEBAR_ICON_SIZE,
    height: SIDEBAR_ICON_SIZE,
    color: theme.palette.greyBlue[500],
  },
  modelsBadge: {
    width: 28,
    height: 20,
  },
}));

export const SidebarItem: React.FC<{
  link: string;
  isChildren?: boolean;
  label?: string;
  icon?: string | JSX.Element | React.FC;
  notificationCount?: number;
  className?: string;
  disabled?: boolean;
  id?: string;
}> = ({ link, isChildren, label, icon, notificationCount, className, disabled, id }) => {
  const history = useHistory();
  const styles = useStyles();
  const isSelected = isLinkActive(link);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [projectDisplayCheckMarkWhenComplete] = useAtom(projectDisplayCheckMarkWhenCompleteAtom);
  const setProjectDisplayCheckMarkWhenComplete = useSetAtom(
    setProjectDisplayCheckMarkWhenCompleteAtom,
  );

  const shouldDisplayCheckMarkWhenComplete =
    selectedProject && projectDisplayCheckMarkWhenComplete[selectedProject?.id];

  useEffect(() => {
    if (id === SIDE_BAR_MODELS_V2_ID && notificationCount && notificationCount > 0) {
      selectedProject &&
        setProjectDisplayCheckMarkWhenComplete({
          projectId: selectedProject.id,
          shouldDisplayCheckMarkWhenComplete: true,
        });
    }
  }, [selectedProject?.id, id, notificationCount]);

  const itemIcon = useMemo(() => {
    if (typeof icon === 'string') {
      return <img src={icon} width={SIDEBAR_ICON_SIZE} height={SIDEBAR_ICON_SIZE} />;
    }
    if (typeof icon === 'function') {
      const Icon = icon;
      return (
        <Box className={styles.icon} display="flex" justifyContent="center">
          <Icon />
        </Box>
      );
    }
    return icon;
  }, [icon, styles.icon]);

  const renderSideBarIcon = (
    id: string | undefined,
    notificationCount: number | undefined,
    itemIcon: JSX.Element | undefined,
  ) => {
    if (id === SIDE_BAR_MODELS_V2_ID) {
      if (notificationCount)
        return (
          <Badge
            classes={{ badge: styles.modelsBadge }}
            color="primary"
            badgeContent={<TrainingInProgressIndicator count={notificationCount} />}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {itemIcon}
          </Badge>
        );
      if (shouldDisplayCheckMarkWhenComplete) {
        return (
          <Badge
            color="primary"
            badgeContent={<TrainingCompletedCheckMark projectId={selectedProject?.id} />}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {itemIcon}
          </Badge>
        );
      }
      return (
        <Badge
          color="primary"
          badgeContent={notificationCount}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {itemIcon}
        </Badge>
      );
    }
    return (
      <Badge
        color="secondary"
        badgeContent={notificationCount}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {itemIcon}
      </Badge>
    );
  };

  return (
    <ListItem
      id={id}
      data-testid={id}
      button
      dense={isChildren}
      selected={isSelected}
      disabled={disabled}
      className={cx(styles.disableBackground, styles.listRoot, className, 'cy-sidebar-link')}
      classes={{
        dense: styles.linkDense,
        selected: styles.linkSelected,
      }}
      onClick={() => {
        history.push(link);
      }}
    >
      <Grid container direction="column" alignItems="center" justifyContent="center" wrap="nowrap">
        <Grid item className={styles.iconGridItem}>
          <>
            {renderSideBarIcon(id, notificationCount, itemIcon)}
            {id === SIDE_BAR_MODELS_V2_ID && (
              <OnboardingTips
                showPulsingDot={true}
                storageKey={modelAnalysisPageOnboardingTipsKey}
                title={t('{{highlightText}} all your models on the dedicated Models page.', {
                  highlightText: <strong>{t('Review, Evaluate and Compare')}</strong>,
                })}
                placement={'right'}
                tooltipStyles={{
                  width: 320,
                  position: {
                    left: 50,
                    bottom: 20,
                  },
                }}
              />
            )}
          </>
        </Grid>
        <Grid item>
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              variant: 'body2',
              className: styles.mainColor,
            }}
            className={styles.listItemText}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};
