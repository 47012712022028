import React from 'react';
import { Tooltip, Box, Typography } from '@material-ui/core';

import { useLocalStorage } from '@clef/client-library';
import { PulsingDot } from '@/components/OnboardingHighlightBadge';

import useStyles from './styles';

export interface SnapshotUsageTipsProps {}

export const SnapshotUsageTips: React.FC<SnapshotUsageTipsProps> = () => {
  const styles = useStyles();

  const [shownSnapshotUsageTips, setSnapshotUsageTips] = useLocalStorage('shownSnapshotUsageTips');

  return (
    <>
      {shownSnapshotUsageTips ? (
        <></>
      ) : (
        <Tooltip
          arrow
          interactive
          placement="bottom"
          classes={{
            popper: styles.popper,
            arrow: styles.arrow,
            tooltip: styles.tooltip,
          }}
          title={
            <Box display="flex" alignItems="flex-end" flexDirection="column">
              <Typography>
                {t(
                  '{{action}} You can use snapshots to train models and create new projects. You can revert back to a snapshot at any time.',
                  {
                    action: <strong>{'Use snapshots.'}</strong>,
                  },
                )}
              </Typography>
              <Box
                onClick={() => setSnapshotUsageTips(true)}
                className={styles.gotIt}
                marginTop={3}
              >
                {t('Got it')}
              </Box>
            </Box>
          }
        >
          <Box className={styles.SnapshotUsageTipsPos}>
            <PulsingDot />
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default SnapshotUsageTips;
