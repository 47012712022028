import React from 'react';
import { Tooltip, Box, Typography } from '@material-ui/core';

import { useLocalStorage } from '@clef/client-library';
import { PulsingDot } from '@/components/OnboardingHighlightBadge';

import useStyles from './styles';

export interface SnapshotListTipsProps {}

export const SnapshotListTips: React.FC<SnapshotListTipsProps> = () => {
  const styles = useStyles();

  const [shownSnapshotListTips, setSnapshotListTips] = useLocalStorage('shownSnapshotListTips');

  return (
    <>
      {shownSnapshotListTips ? (
        <></>
      ) : (
        <Tooltip
          arrow
          interactive
          placement="bottom"
          classes={{
            popper: styles.popper,
            arrow: styles.arrow,
            tooltip: styles.tooltip,
          }}
          title={
            <Box display="flex" alignItems="flex-end" flexDirection="column">
              <Typography>
                {t('{{action}} Browse and select the snapshot you want to view from here.', {
                  action: <strong>{'Snapshot list.'}</strong>,
                })}
              </Typography>
              <Box onClick={() => setSnapshotListTips(true)} className={styles.gotIt} marginTop={3}>
                {t('Got it')}
              </Box>
            </Box>
          }
        >
          <Box className={styles.SnapshotListTipsPos}>
            <PulsingDot />
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default SnapshotListTips;
