// @ts-ignore
import Papa from 'papaparse';
// @ts-ignore
import strftime from 'strftime';
import { AnyFunction, Key, ValueOf } from '@clef/shared/types';
import { format } from 'date-fns';

export * from './media_utils';
export * from './upload_utils';
export * from './time_utils';
export * from './str_utils';
export * from './defect_utils';
export * from './formatting_utils';
export * from './array_utils';
export * from './job_metrics_utils';
export * from './image_enhancer';
export * from './number_formatting_utils';
export * from './str_utils';

export const noop = (..._args: any[]): any => {};
/**
 *  Helper function to constrain a number between 2 number
 */
export const clamp = (number: number, lower: number, upper: number): number => {
  return Math.max(lower, Math.min(upper, number));
};

/**
 * Convert array of objects to dictionary with chosen keyField as key
 * ex: [ {id:1, item: 2}}, {id: 2, item: 3} ]  to
 *     {
 *          1: {id:1, item: 2},
 *          2: {id: 2, item: 3}
 *     }
 *
 * @param array
 * @param keyField      field attribute to use as key
 * @param valueField    field/transform function to use as value (if provided)
 *          if string s is provided, V[s] will be used as the value
 *          if function f is provided, f(V) will be used as the value
 * @returns {*}
 */
export const arrayToObject = <V extends any, K extends keyof V>(
  array: V[],
  keyField: K,
  valueField?: keyof V | AnyFunction<V>,
): Record<string, V> => {
  const init = {} as Record<K, V>;
  if (!array) return init;
  return array.reduce((obj, item) => {
    // @ts-ignore the index signigure is correct
    obj[item[keyField]] = valueField
      ? typeof valueField === 'function'
        ? valueField(item)
        : item[valueField]
      : item;
    return obj;
  }, init);
};

const findDuplicates = (arr: any[]) => {
  const sorted_arr = arr.slice().sort(); // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  const results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] == sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
};

/**
 *  remap the key of map object to use a certain value corresponding to
 *  given key from the map object values
 *  ex: func([{ a: {k: 1}, b: {k : 2}, c: {k : 3}}], 'k')
 *      returns: { 1: {k: 1}, 2: {k : 2}, 3: {k : 3}}
 */
// @ts-ignore
export const remapObjectKey = (obj, key) => {
  const values = Object.values(obj);
  // @ts-ignore
  const keys = values.map(e => e[key]);
  if (keys.includes(undefined)) throw Error('Invalid: some values unmapped');
  const unique = [...new Set(keys)];
  if (unique.length !== keys.length) {
    throw Error(`Invalid: duplicate key detected ${findDuplicates(keys)}`);
  }
  // @ts-ignore
  return arrayToObject(values, key);
};

/**
 * map the value of a key based on given transformer function
 * ex: input:  { 1: 'a',  2: 'b' },  (k) => k + 10
 *     output: { 11: 'a', 12: 'b' }
 */
export const transformKey = <V extends any, K extends string>(
  obj: Record<K, V>,
  transformer: (k: string) => string,
): Record<string, V> => {
  const res = {} as Record<string, V>;
  for (const [key, value] of Object.entries<V>(obj)) {
    res[transformer(key)] = value;
  }
  return res;
};

/**
 * Pull field of object values to the dictionary value
 * ex:
 * from : {
 *     A: { x: 1, y: 2, z: 3 },
 *     B: { x: 1, y: 2, z: 3},
 * }
 * to : { A: 1, B: 1}   (for keyField x)
 * @param obj
 * @param keyField
 */
export const pullFieldUp = <V extends any, K extends keyof V>(
  obj: Record<Key, V>,
  keyField: K,
): Record<Key, V[K]> => {
  const res: Record<Key, V[K]> = {};
  for (const [key, value] of Object.entries(obj)) {
    res[key] = value[keyField];
  }
  return res;
};

/**
 * Convert content of CSV file to objects
 * @param file
 */
export const csvToObject = (file: File): Promise<Record<'data', any>> => {
  return new Promise(resolve => {
    Papa.parse(file, {
      complete: (results: Record<'data', any>) => {
        resolve(results);
      },
      skipEmptyLines: true,
    });
  });
};

// valid s3 path to key or folder
export const isValidS3Path = (path: string): boolean => {
  const reg = RegExp('^s3://([^/]+)/(.*?([^/]+)/?)$');
  return reg.test(path);
};

/**
 * Return entries with the specified value
 * ex: filterValue( { a: 1, b: 2, c: 2}, 2 } ) -> { b: 2, c: 2}
 *
 */
// @ts-ignore
export const filterValue = (obj, value) => {
  return Object.keys(obj).reduce((o, key) => {
    // @ts-ignore
    obj[key] === value && (o[key] = obj[key]);
    return o;
  }, {});
};

/*
 * ex:
 *  input:
        obj: {
            a: { x: 1, y: 2, },
            b: { x: 2, y: 3, },
            c: { x: 3, y: 3, z: 3 } }
        attr: a
        keys: [2,3]
    output:
         {  b: { x: 2, y: 3, },
            c: { x: 3, y: 3, z: 3 } }
 */
// @ts-ignore
export const filterByAttribute = (obj, attr, keys) => {
  return (
    Object.entries(obj)
      // @ts-ignore
      .filter(e => keys.includes(e[1][attr]))
      .reduce((acc, entry) => {
        // @ts-ignore
        acc[entry[0]] = entry[1];
        return acc;
      }, {})
  );
};

// @ts-ignore
export const getReadableTime = ts => {
  return strftime('%b %d, %Y  %l:%M %p', new Date(ts));
};

// extract the file name out of a path to file
// @ts-ignore
export const getFileTypeFromPath = path => {
  const ext = path.split('.').pop().toLowerCase();
  let type = '';
  switch (ext) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
      type = 'image';
      break;
    case 'mkv':
    case 'mp4':
    case 'mpeg':
      type = 'video';
      break;
  }
  return type;
};

// extract the file name out of a path to file
// @ts-ignore
export const getFileNameFromPath = path => {
  return path.replace(/^.*[\\/]/, '');
};

/*
 * Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
 */
// @ts-ignore
export function template(strings, ...keys) {
  // @ts-ignore
  return function (...values) {
    const dict = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach(function (key, i) {
      const value = Number.isInteger(key) ? values[key] : String(dict[key] || key);
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
}

// @ts-ignore
export function registerEventListener(obj, params) {
  if (typeof obj._eventListeners == 'undefined') {
    obj._eventListeners = [];
  }

  obj.addEventListener(params.event, params.callback);

  const eventListeners = obj._eventListeners;
  eventListeners.push(params);
  obj._eventListeners = eventListeners;
}

// @ts-ignore
export function registerEventListeners(obj, eventParams) {
  for (const [event, callback] of Object.entries(eventParams)) {
    registerEventListener(obj, { event: event, callback: callback });
  }
}

// @ts-ignore
export function unRegisterAllEventListeners(obj) {
  if (typeof obj._eventListeners == 'undefined' || obj._eventListeners.length === 0) {
    return;
  }

  for (let i = 0, len = obj._eventListeners.length; i < len; i++) {
    const e = obj._eventListeners[i];
    obj.removeEventListener(e.event, e.callback);
  }

  obj._eventListeners = [];
}

/* Ex:
   1) [...range(1,5)]   => [1,2,3,4,5]
   2) for (i of range(1,5)) { ...... }
 */
// @ts-ignore
export function* range(start, end) {
  for (let i = start; i <= end; i++) {
    yield i;
  }
}

/*
    Ex: omit({a: 1, b: 2, ['b'])  => { a: 1}
 */
export const omit = (obj: Record<string, any>, keys: string[]) => {
  const init: Record<string, any> = {};
  return Object.keys(obj).reduce((result, key) => {
    if (!keys.includes(key)) {
      result[key] = obj[key];
    }
    return result;
  }, init);
};

/*
    Ex: chunk([1,2,3,4,5], 2)  => [ [1,2], [3,4], [5] ]
 */
// @ts-ignore
export function chunk(array, size) {
  const chunked_arr = [];
  const copied = [...array]; // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
  for (let i = 0; i < numOfChild; i++) {
    chunked_arr.push(copied.splice(0, size));
  }
  return chunked_arr;
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle<T extends any>(a: T[]): T[] {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

// random sample non-repeat
export function sample<V extends any>(A: V[], min = 1): V[] {
  return shuffle(A).slice(min, Math.random() * A.length);
}

export function isEmpty(o: object | any[]) {
  // check for objects
  let check = o.constructor === Object && Object.keys(o).length === 0;
  check = check || (Array.isArray(o) && o.length === 0);
  return check;
}

// Note: feel free to extend as needed
export function isKeysExist<
  S extends any,
  K1 extends keyof S,
  K2 extends keyof S[K1],
  K3 extends keyof S[K1][K2],
  K4 extends keyof S[K1][K2][K3],
>(state: S, k1: K1, k2?: K2, k3?: K3, k4?: K4): boolean {
  const v1 = state[k1];
  if (!k2) return !!v1;
  const v2 = v1[k2];
  if (!k3) return !!v2;
  const v3 = v2[k3];
  if (!k4) return !!v3;
  return !!v3[k4];
}

/**
 * Group list of object according specific attributes passed in keyField
 * ex:
 * array: [ {a: 1, b: 2},  {a: 1, b: 3},  {a: 2}  ]
 * keyField: 'a'
 * output: {
 *      1: [{a: 1, b: 2}, {a: 1, b: 3}],
 *      2: [{a: 2}]
 * }
 */
export const groupBy = <O extends any, K extends keyof O, V extends ValueOf<O>>(
  array: O[],
  keyField: K,
  // @ts-ignore
): Record<V, O[]> => {
  // @ts-ignore
  const init = {} as Record<V, O[]>;
  if (!array) return init;
  return array.reduce((obj, item) => {
    const keyGen = item[keyField] as unknown as V;
    if (obj[keyGen]) {
      obj[keyGen].push(item);
    } else {
      obj[keyGen] = [item];
    }
    return obj;
  }, init);
};

/**
 * convert the value of object based on the
 * transform function passed in.
 * ex:
 * object: { a: 1, b: 2, c: 3 }
 * transform: x => x + 2
 * output: { a: 2, b: 4, c: 6 }
 */
export const mapValues = <V extends any, K extends keyof V>(
  object: Record<K, V>,
  transform: (v: V) => any,
) => {
  const init = {} as Record<K, any>;
  Object.entries(object).forEach(([key, value]) => {
    // @ts-ignore
    init[key] = transform(value);
  });
  return init;
};

/**
 * Function to calculate the histogram count of array of items
 * ex:      [1, 1, 1, 2, 2, 9]
 * output: { 1: 3, 2: 2, 9: 1 }
 * @param items
 */
export const histCount = <T extends string | number>(items: T[]): Record<T, number> => {
  const keys = Array.from(items);
  return keys.reduce(
    (res, k) => ({
      ...res,
      [k]: items.filter(v => k === v).length,
    }),
    {} as Record<T, number>,
  );
};

/**
 * set the index of the image selected/notselected from start to end (not included)
 * @param selectionMap
 * @param indexMap
 * @param start
 * @param end
 * @param value
 */
export const setSelectionRangeValue = <T extends any>(
  selectionMap: Record<number, T>,
  indexMap: Record<number, number>,
  start: number,
  end: number,
  value: T,
) => {
  for (let idx = start; idx < end; ++idx) {
    selectionMap[indexMap[idx]] = value;
  }
};

export const dateDiffInDays = (fromDate: Date, toDate: Date) => {
  return (fromDate.getTime() - toDate.getTime()) / (1000 * 60 * 60 * 24);
};

export const formatDateToShort = (dateStringOrTimestamp: string | number) => {
  const date = new Date(dateStringOrTimestamp);
  // Timezone correction that fixes off by 1 day issue
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return format(date, 'MMM d');
};

export const copyToClipboard = (str: string, onCopyFailed: (str: string) => void) => {
  const textarea = document.createElement('textarea');
  textarea.textContent = str;
  document.body.appendChild(textarea);

  const selection = document.getSelection();
  if (!selection) {
    document.body.removeChild(textarea);
    onCopyFailed('Copy Failed');
    return;
  }
  const range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand('copy');
  selection.removeAllRanges();

  document.body.removeChild(textarea);
};

export const handleSliderInputChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setValue: (value: string | number) => void,
  adjustInRange?: (value: number) => number,
) => {
  const { value } = e.target;
  const parsedValue = !isNaN(Number(value))
    ? adjustInRange
      ? adjustInRange(Number(value))
      : value
    : e.target.value.toString() === '-'
    ? '-'
    : '';
  setValue(parsedValue);
};

export const setImmediateInterval: WindowOrWorkerGlobalScope['setInterval'] = (
  caller: Function,
  delay,
) => {
  caller();
  return window.setInterval(caller, delay);
};
