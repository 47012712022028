import React, { useCallback, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import useWindowEventListener from '../hooks/useWindowEventListener';
import throttle from 'lodash/throttle';
import useElementEventListener from '../hooks/useElementEventListener';

const BUFFER_ROW_LENGTH = 10;

const useStyles = makeStyles(() => ({
  scrolledContainer: {
    maxHeight: (props: { maxHeight?: number }) => props.maxHeight,
    overflow: 'auto',
  },
}));

export interface VirtualListProps<T extends { id: number | string }> {
  dataList: T[];
  disabled?: boolean;
  children: (data: T, index: number) => React.ReactNode;
  itemHeight: number;
  // Window scroll listner won't work on scrolling in modals,
  // so you have to add a scroll listner to the container element for calculateRenderRange to trigger
  // for more specific cases like you want it to listen to your own container
  // add a new props to pass container ref
  containerMaxHeight?: number;
}

const VirtualList = <T extends { id: number | string }>({
  dataList,
  children,
  disabled,
  itemHeight,
  containerMaxHeight,
}: VirtualListProps<T>) => {
  const containerRef = useRef<HTMLDivElement>();
  const styles = useStyles({ maxHeight: containerMaxHeight });
  // we keep track of a startIndex and endIndex for all the data to render
  const [renderRange, setRenderRange] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });

  const calculateRenderRange = useCallback(() => {
    if (containerRef.current && !disabled) {
      // CAUTIOUS: This calculation depend on scroll to only happen on the whole window
      // If later we decide to implement a scroll-able container, we need to revisit this implementation
      const containerHeight =
        containerMaxHeight && containerRef.current
          ? containerRef.current.offsetHeight
          : window.innerHeight;
      const containerScrollHeight =
        containerMaxHeight && containerRef.current
          ? containerRef.current.scrollTop
          : window.scrollY;
      // containerTopToScreenTop could be negative, it is fine
      const containerTopToScreenTop = containerScrollHeight - containerRef.current.offsetTop;
      const containerTopToScreenBottom = containerTopToScreenTop + containerHeight;
      // So we can only estimate the height of each media, it's not totally exact, but mostly fine
      setRenderRange({
        start:
          // If containerTopToScreenTop / itemHeight = 4.5, we take 4th row, - 2 bufferRow = 2 row to start render
          Math.floor(containerTopToScreenTop / itemHeight) - BUFFER_ROW_LENGTH,
        end:
          // If containerTopToScreenBottom / itemHeight = 4.5, we take 5th row, + 2 bufferRow = 7 row to end render
          Math.ceil(containerTopToScreenBottom / itemHeight) + BUFFER_ROW_LENGTH,
      });
    }
  }, [disabled, itemHeight, containerRef.current]);

  const containerRefCallback = useCallback(
    (node: HTMLDivElement | null) => {
      if (node && !disabled) {
        containerRef.current = node;
        calculateRenderRange();
      }
    },
    [calculateRenderRange, disabled],
  );

  useElementEventListener(
    containerMaxHeight && containerRef.current ? containerRef.current : undefined,
    'scroll',
    (disabled ? disabled : !containerMaxHeight || !containerRef.current)
      ? () => {}
      : // throttle scroll event so it doesn't trigger too often
        throttle(
          () => {
            calculateRenderRange();
          },
          200,
          { leading: false },
        ),
  );

  useWindowEventListener(
    'scroll',
    disabled
      ? () => {}
      : // throttle scroll event so it doesn't trigger too often
        throttle(
          () => {
            calculateRenderRange();
          },
          200,
          { leading: false },
        ),
  );

  const shouldRender = useCallback(
    (index: number) => (index >= renderRange.start && index < renderRange.end) || disabled,
    [disabled, renderRange.end, renderRange.start],
  );

  return (
    <div
      className={containerMaxHeight ? styles.scrolledContainer : undefined}
      ref={containerRefCallback}
      data-testid="virtual-list"
    >
      {dataList.map((data, index) => (
        <div
          key={data.id}
          style={{ paddingTop: shouldRender(index) ? 0 : itemHeight }}
          data-testid="virtual-list-item"
        >
          {shouldRender(index) && children(data, index)}
        </div>
      ))}
    </div>
  );
};

export default VirtualList;
