import React from 'react';
import { Tooltip, Box, Typography, makeStyles } from '@material-ui/core';
import { useAtom } from 'jotai';

import { useLocalStorage } from '@clef/client-library';

import { annotationInstanceAtom } from '@/uiStates/mediaDetails/pageUIStates';

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: 1300,
    pointerEvents: 'auto',
  },
  arrow: {
    color: theme.palette.blue[700],
  },
  tooltip: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  tipPlaceHolder: {
    position: 'absolute',
    left: '50vw',
    top: 40,
  },
}));

const AfterFirstActionTip: React.FC = () => {
  const styles = useStyles();

  const [annotationInstance] = useAtom(annotationInstanceAtom);

  const [skipAfterFirstActionTip, setSkipAfterFirstActionTip] =
    useLocalStorage('skipAfterFirstActionTip');

  if (skipAfterFirstActionTip || !!annotationInstance) {
    return null;
  }

  return (
    <Tooltip
      open
      arrow
      placement="bottom-end"
      classes={{
        popper: styles.popper,
        arrow: styles.arrow,
        tooltip: styles.tooltip,
      }}
      title={
        <Box display="flex" alignItems="flex-end" flexDirection="column">
          <Typography>{t('Go to the next image when you’re done labeling this one.')}</Typography>
          <Box
            onClick={() => {
              setSkipAfterFirstActionTip(true);
            }}
            className={styles.gotIt}
            marginTop={3}
          >
            {t('Got it')}
          </Box>
        </Box>
      }
    >
      <Box className={styles.tipPlaceHolder} />
    </Tooltip>
  );
};

export default AfterFirstActionTip;
