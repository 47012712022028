import { Draft } from 'immer';
import { createContext, useContext } from 'react';

export type PageLayoutState = {
  showHeader: boolean;
  showSidebar: boolean;
  pageTitle?: string;
  pageTitleBreadcrumb?: { label: string; to: string };
  metaTitle?: string;
  fullScreenContent?: boolean;
  fixedHeightContent?: boolean;
  showWhiteBackground?: boolean;
  showGreyBackground?: boolean;
  showPurpleBackground?: boolean;
  showProjectBanner?: boolean;
  noPadding?: boolean;
  showBetaAnnouncement?: boolean;
};

export const initialState: PageLayoutState = {
  showHeader: true,
  showSidebar: true,
  showBetaAnnouncement: process.env.IS_SNOWFLAKE === 'true',
};

export const PageLayoutContext = createContext<{
  state: PageLayoutState;
  dispatch: (f: (state: Draft<PageLayoutState>) => void | PageLayoutState) => void;
}>({
  state: initialState,
  dispatch: () => {},
});

export const usePageLayoutState = () => useContext(PageLayoutContext);
