import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiResponseLoader, Button } from '@clef/client-library';
import {
  isDeploymentPending,
  isDeploymentReady,
  isModelTrainingSuccessful,
} from '@/store/projectModelInfoState/utils';
import { LabelType } from '@clef/shared/types';
import { useCurrentProjectModelInfoQuery, useSaveModelMutation } from '@/serverStore/projectModels';
import { ModelSwitcher } from '@/components/ModelSwitcher';
import {
  appliedFilterMappingToFormattedFilterMapping,
  getColumnFilterMapWithModelId,
  useDataBrowserState,
} from '@/pages/DataBrowser/dataBrowserState';
import PerformanceRates from '@/pages/DataBrowser/ModelPerformance/PerformanceRates';
import { useSnackbar } from 'notistack';
import { useModels } from '@/hooks/useModels';
import { isUnsavedModel } from '@/utils/models';
import { SaveModelDialog } from '@/pages/DataBrowser/ModelPerformance/SaveModelDialog';
import { useHistory } from 'react-router';
import CLEF_PATH from '@/constants/path';
import ControlledPerformanceCharts from '@/pages/DataBrowser/ModelPerformance/ControlledPerformanceCharts';
import { generateModelNameByDate } from '@/pages/DataBrowser/InstantLearningModels/utils';
import { getDateNumber } from '@/utils';
import { formatDistance } from 'date-fns';
import { useIsLargeImageModel } from '@/hooks/useIsLargeImageModel';
import EmptyModelIcon from '@/images/deploy/empty_model.svg';
import { useDeployModelToEndpointMutation } from '@/serverStore/endpoints';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { useDatasetModelMetricsQuery } from '@/serverStore/dataset';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 24,
    fontWeight: 500,
    color: theme.palette.greyModern[900],
  },
  modelTitle: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.grey[600],
    marginBottom: 8,
  },
  emptyModelBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    marginTop: 16,
    textAlign: 'center',
  },
  emptyModelTitle: {
    fontWeight: 500,
    lineHeight: '20px',
    color: theme.palette.grey[900],
  },
  emptyModelSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.grey[600],
  },
  emptyModelButton: {
    marginTop: 8,
  },
}));

export type SetupDeploymentProps = {
  endpointId: string;
  onModelDeploySuccess?: () => void;
  classes?: {
    root?: string;
    switchModelMenu?: string;
  };
  initialModelId?: string;
  initialThreshold?: number;
};

export const SetupDeployment: React.FC<SetupDeploymentProps> = ({
  endpointId,
  onModelDeploySuccess,
  classes,
  initialModelId,
  initialThreshold,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const {
    state: { appliedFilters, viewMode },
  } = useDataBrowserState();
  const { id: currentModelId, confidence: currentModelThreshold } =
    useCurrentProjectModelInfoQuery();
  const { mutateAsync: deployModelToEndpoint, isLoading } = useDeployModelToEndpointMutation();
  const saveModel = useSaveModelMutation();
  const isDeploying = saveModel.isLoading || isLoading;
  const [modelId, setModelId] = useState<string | undefined>(
    labelType === LabelType.SegmentationInstantLearning
      ? currentModelId
      : initialModelId ?? currentModelId,
  );
  const [threshold, setThreshold] = useState<number | undefined>(
    initialThreshold ?? currentModelThreshold,
  );

  const [saveModelDialogOpen, setSaveModelDialogOpen] = useState(false);
  const { models, findModels } = useModels();

  const { data: modelStatusRes } = useModelStatusQuery(projectId, modelId);
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};

  // Reformat appliedFilters to columnFilterMap and metadataFilterMap
  const [columnFilterMap, metadataFilterMap] = useMemo(() => {
    const [col, metadata] = appliedFilterMappingToFormattedFilterMapping(appliedFilters);
    const colWithModelId = getColumnFilterMapWithModelId(col, modelId, true);
    return [colWithModelId, metadata];
  }, [appliedFilters, modelId]);

  const {
    data: modelMetricsData,
    isLoading: modelMetricsLoading,
    error: modelMetricsError,
  } = useDatasetModelMetricsQuery(
    {
      modelId,
      columnFilterMap,
      metadataFilterMap,
      viewMode,
    },
    isModelTrainingSuccessful(modelStatus, metricsReady),
  );

  const model = useMemo(() => findModels(modelId), [findModels, modelId]);

  useEffect(() => {
    const handler = (event: WindowEventMap['beforeunload']) => {
      if (isDeploying) {
        event.preventDefault();
        event.returnValue = 'There are unsaved changes. Are you sure want to leave?';
      }
    };

    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [isDeploying]);

  const onModelDeploy = useCallback(
    async (modelId: string | undefined, threshold: number | undefined) => {
      if (modelId !== undefined) {
        await deployModelToEndpoint(endpointId, modelId, threshold);
        onModelDeploySuccess?.();
      }
    },
    [endpointId, enqueueSnackbar, onModelDeploySuccess, projectId],
  );
  const deployButtonTooltip = useMemo(
    () =>
      isDeploymentPending(modelStatusRes?.status)
        ? t('Please wait for the training completed before the deployment.')
        : !isDeploymentReady(modelStatusRes?.status)
        ? t('Model training failed. Please use a different model.')
        : !!initialModelId &&
          !!initialThreshold &&
          initialModelId === modelId &&
          initialThreshold === threshold
        ? t('Please update either model or threshold before the deployment.')
        : '',
    [modelStatusRes?.status, initialModelId, initialThreshold, modelId, threshold],
  );

  const autoSaveModel = useCallback(async () => {
    if (modelId === undefined) {
      throw Error('modelId is undefined!');
    }
    await saveModel.mutateAsync({
      id: modelId,
      modelName: generateModelNameByDate(),
    });
    await onModelDeploy(modelId, threshold);
  }, [modelId, onModelDeploy, threshold, saveModel.mutateAsync]);

  const [isLargeImageModel, largeImageModelLoading] = useIsLargeImageModel(modelId);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      width={400}
      className={classes?.root}
    >
      <Typography variant="h2" className={styles.title}>
        {models?.length ? t('Set Up Your Endpoint') : t('Set Up Your Deployment')}
      </Typography>

      {models?.length ? (
        <>
          <Box display="flex" flexDirection="column" width="100%" marginBottom={10}>
            <Box paddingBottom={2} paddingTop={4} marginBottom={10}>
              <Typography className={styles.modelTitle}>{t('Model')}</Typography>
              {labelType === LabelType.SegmentationInstantLearning ? (
                <Typography>
                  {t('Current model was trained {{dateDistance}} ago', {
                    dateDistance: formatDistance(getDateNumber(model!.createdAt), new Date()),
                  })}
                </Typography>
              ) : (
                <ModelSwitcher
                  idPrefix="fre-deploy-page"
                  modelId={modelId}
                  onModelSwitch={setModelId}
                  classes={{ switchModelMenu: classes?.switchModelMenu }}
                />
              )}
            </Box>

            {labelType !== LabelType.SegmentationInstantLearning && (
              <ApiResponseLoader
                response={modelMetricsData}
                loading={modelMetricsLoading || modelMetricsData === undefined}
                error={modelMetricsError}
              >
                {modelMetrics => (
                  <>
                    {[LabelType.BoundingBox, LabelType.Segmentation].includes(labelType!) && (
                      <ControlledPerformanceCharts
                        modelId={modelId}
                        threshold={threshold}
                        modelMetrics={modelMetrics}
                        onThresholdChange={setThreshold}
                      />
                    )}

                    <Box marginBottom={10} />

                    <PerformanceRates
                      threshold={threshold || 0}
                      modelMetrics={modelMetrics}
                      labelType={labelType}
                      showVeriticalLine={false}
                    />
                  </>
                )}
              </ApiResponseLoader>
            )}
          </Box>

          <Grid container>
            <Box flex={1} />

            <Tooltip title={deployButtonTooltip} placement="top" arrow>
              <span>
                <Button
                  id="fre-deploy-page-deploy-button"
                  color="primary"
                  variant="contained"
                  disabled={!!deployButtonTooltip || isLargeImageModel || largeImageModelLoading}
                  isPending={isDeploying || model === null}
                  onClick={async () => {
                    if (model) {
                      if (isUnsavedModel(model)) {
                        if (labelType === LabelType.SegmentationInstantLearning) {
                          await autoSaveModel();
                        } else {
                          setSaveModelDialogOpen(true);
                        }
                      } else {
                        await onModelDeploy(modelId, threshold);
                      }
                    }
                  }}
                >
                  {t('Deploy')}
                </Button>
              </span>
            </Tooltip>
          </Grid>

          <SaveModelDialog
            open={saveModelDialogOpen}
            onClose={() => {
              setSaveModelDialogOpen(false);
            }}
            hintText={t('You need to save the model before deployment.')}
            onSaveModelSucceed={async () => {
              await onModelDeploy(modelId, threshold);
            }}
            modelId={modelId}
          />
        </>
      ) : (
        <Box className={styles.emptyModelBlock}>
          <img src={EmptyModelIcon} width={80} height={80} />
          <Typography variant="h4" className={styles.emptyModelTitle}>
            {t('No model available for deployment')}
          </Typography>
          <Typography variant="caption" className={styles.emptyModelSubtitle}>
            {t('Train a model first and you will be able to set up your deployment')}
          </Typography>
          <Button
            id="fre-deploy-page-back-to-data-button"
            variant="contained"
            color="primary"
            className={styles.emptyModelButton}
            onClick={() => history.push(CLEF_PATH.data.dataBrowser)}
          >
            {t('Back to Build')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SetupDeployment;
